import React from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';

import { Button } from '@src/components/ui_v2/buttons';
import { QBOErrorIcon } from '@src/components/utils/icomoon';

import styles from '../../styles.module.scss';

interface INoLedgerConnectedViewProps {
  vendor: IBusinessVendor,
}

const NoLedgerConnectedView = ({ vendor }: INoLedgerConnectedViewProps) => {
  const handleConnectLedger = () => {
    window.open(`/setup-business/${vendor.businessId}?step=2`, '_blank');
  };

  return (
    <div className={ styles['no-ledger-container'] }>
      <QBOErrorIcon fontSize={ 60 } />
      <div>
        <p className={ styles['no-ledger-title'] }>General Ledger is not connected yet.</p>
        <p className={ styles['no-ledger-label'] }>
          To sync the vendor with the ledger, please connect the General Ledger to this business.
        </p>
      </div>
      <Button variant="primary" onClick={ handleConnectLedger }>Connect General Ledger</Button>
    </div>
  );
};

export default React.memo(NoLedgerConnectedView);
