import React from 'react';

import NumberFormat from 'react-number-format';

import { TAmount } from '@src/types/common';

import Cell, { ITableCellProps } from './cell';

interface ITableCurrencyAmountCellProps extends Omit<ITableCellProps, 'children'> {
  value: TAmount | number,
  decimalScaleValue?: number,
  hidePrefix?: boolean
}

const TableCurrencyAmountCell = ({
  value,
  decimalScaleValue=0,
  hidePrefix = false,
  ...cellProps
}: ITableCurrencyAmountCellProps) => {
  return (
    <Cell
      textAlign="right"
      { ...cellProps }
    >
      <NumberFormat
        thousandSeparator
        decimalScale={ decimalScaleValue }
        displayType="text"
        prefix={ hidePrefix ? '' : '$' }
        value={ value }
      />
    </Cell>
  );
};

export default TableCurrencyAmountCell;
