import React from 'react';

import { IBusinessSimple } from '@src/types/businesses';
import { TDateRange } from '@src/types/common';
import { IDataSet } from '@src/types/dashboards/data_sets';
import { TChartType } from '@src/types/dashboards/widgets';

import ChartView from './charts/chart_view';
import { TChartScale } from './charts/types';

interface IAggregateViewProps {
  businesses?: IBusinessSimple[],
  chartType?: TChartType,
  scale?: TChartScale,
  dataSets: IDataSet[],
  dateRange: TDateRange,
}

const AggregateView = ({
  businesses,
  chartType,
  scale,
  dataSets,
  dateRange,
}: IAggregateViewProps): JSX.Element => {
  return (
    <ChartView
      isStackedBar
      businesses={ businesses }
      chartType={ chartType }
      dataSets={ dataSets }
      dateRange={ dateRange }
      scale={ scale }
    />
  );
};

export default AggregateView;
