import React from 'react';

import classNames from 'classnames';

import { SortArrowIcon } from '@src/components/utils/fa_icons';

import styles from './styles.module.scss';

interface ISortIndicatorProps extends React.ComponentPropsWithoutRef<'span'> {
  direction: 'asc' | 'desc' | undefined,
  onSortAsc?: (event: React.MouseEvent) => void;
  onSortDesc?: (event: React.MouseEvent) => void;
}

const SortIndicator = ({
  className,
  direction,
  onSortAsc,
  onSortDesc,
  ...props
}: ISortIndicatorProps) => {
  const classes = classNames(styles['sort-indicator'], direction, className);

  return (
    <div className={ classes } { ...props }>
      <SortArrowIcon
        variant="up"
        onClick={ onSortAsc }
      />
      <SortArrowIcon
        variant="down"
        onClick={ onSortDesc }
      />
    </div>
  );
};

export default SortIndicator;
