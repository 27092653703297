const HospitalityTotalRevenue = 2000000/12;
const HospitalityADR = 150;
const HospitalityCostPerOccupiedRoom = 50;
const HospitalityGOPPAR = 30000;
const HospitalityGrossProfitMargin = 75;
const HospitalityOccupancyPercentage = 70;
const HospitalityRevPAR = 120;
const HospitalityFoodAndBeverageProfitability = 20;

// UPS Store
const UPSStoreAverageCustomerCount = 120;
const UPSStoreAverageMailboxRevenueOccupied = 20;
const UPSStoreAverageSaleCustomer = 15;
const UPSStoreGrossProfitMargin = 60;
const UPSStoreMeteredMailMargin = 15;
const UPSStoreTotalRevenue = 300000/12;

// QSR
const QSRTotalRevenue = 500000/12;
const QSRGrossProfitMargin = 65;
const QSRSalesPerLaborHour = 35;
const QSRSpendPerTicket = 12;
const QSRTotalSales = 500000/12;

// SaaS
const SaaSTotalRevenue = 5000000/12;
const SaaSMonthlyRecurringRevenue = 250000;
const SaaSCustomerAcquisitionCost = 1000;
const SaaSAnnualRecurringRevenue = 3000000;
const SaaSChurnRate = 3;
const SaaSCustomerCount = 1000;
// Other
const OtherTotalRevenue = 3000000/12;
const OtherOperatingMargin = 15;
const OtherGrossProfitMargin = 65;
const OtherPayrollPercentage = 20;
export const DefaultTargets: Record<string, Record<string, { value: number, unit: string }>> = {
  "7": { // Other
    "total_revenue_other": {
      "value": OtherTotalRevenue,
      "unit": "dollar"
    },
    "total_expenses_other": {
      "value": OtherTotalRevenue * 0.8,
      "unit": "dollar"
    },
    "net_profit_other": {
      "value": OtherTotalRevenue * 0.2,
      "unit": "dollar"
    },
    "operating_margin_other": {
      "value": OtherOperatingMargin,
      "unit": "percent"
    },
    "operating_expenses_other": {
      "value": 0,
      "unit": "dollar"
    },
    "payroll_percentage": {
      "value": OtherPayrollPercentage,
      "unit": "percent"
    },
    "gross_margin_other": {
      "value": OtherGrossProfitMargin,
      "unit": "percent"
    },
    "cash_flow_other": {
      "value": 0,
      "unit": "dollar"
    },
    "ebitda_other": {
      "value": 0,
      "unit": "dollar"
    },
    "quick_ratio_other": {
      "value": 0,
      "unit": "dollar"
    }
  },
  "8": { // The UPS Store
    "average_customer_count": {
      "value": UPSStoreAverageCustomerCount,
      "unit": "count"
    },
    "average_mailbox_revenue_occupied": {
      "value": UPSStoreAverageMailboxRevenueOccupied,
      "unit": "dollar"
    },
    "average_sale_customer": {
      "value": UPSStoreAverageSaleCustomer,
      "unit": "dollar"
    },
    "gross_profit_margin_ups": {
      "value": UPSStoreGrossProfitMargin,
      "unit": "percent"
    },
    "metered_mail_margin_ups": {
      "value": UPSStoreMeteredMailMargin,
      "unit": "percent"
    },
    "net_profit_ups": {
      "value": UPSStoreTotalRevenue * 0.1,
      "unit": "dollar"
    },
    "payroll_percentage_ups": {
      "value": 20,
      "unit": "percent"
    },
    "total_expenses_ups": {
      "value": UPSStoreTotalRevenue * 0.9,
      "unit": "dollar"
    },
    "total_revenue_ups": {
      "value": UPSStoreTotalRevenue,
      "unit": "dollar"
    }
  },
  "9": { // Hospitality
    "adr": {
      "value": HospitalityADR,
      "unit": "dollar"
    },
    "cost_per_occupied_room": {
      "value": HospitalityCostPerOccupiedRoom,
      "unit": "dollar"
    },
    "ebitda": {
      "value": HospitalityTotalRevenue * 0.25,
      "unit": "dollar"
    },
    "food_and_beverage_profitability": {
      "value": HospitalityFoodAndBeverageProfitability,
      "unit": "percent"
    },
    "gop_par": {
      "value": HospitalityGOPPAR,
      "unit": "dollar"
    },
    "gross_profit_margin_hospitality": {
      "value": HospitalityGrossProfitMargin,
      "unit": "percent"
    },
    "net_profit_hospitality": {
      "value": HospitalityTotalRevenue * 0.12,
      "unit": "dollar"
    },
    "occupancy_percentage": {
      "value": HospitalityOccupancyPercentage,
      "unit": "percent"
    },
    "payroll_percentage_hospitality": {
      "value": 20,
      "unit": "percent"
    },
    "rev_par": {
      "value": HospitalityRevPAR,
      "unit": "dollar"
    },
    "total_expenses_hospitality": {
      "value": HospitalityTotalRevenue * 0.88,
      "unit": "dollar"
    },
    "total_revenue_hospitality_daily": {
      "value": HospitalityTotalRevenue / 365,
      "unit": "dollar"
    },
    "total_revenue_hospitality": {
      "value": HospitalityTotalRevenue,
      "unit": "dollar"
    }
  },
  "10": { // QSR
    "beverage_costs_qsr": {
      "value": QSRTotalRevenue * 0.12,
      "unit": "dollar"
    },
    "total_revenue_qsr": {
      "value": QSRTotalRevenue,
      "unit": "dollar"  
    },
    "cogs": {
      "value": QSRTotalRevenue * 0.3,
      "unit": "dollar"
    },
    "gross_profit_margin_qsr": {
      "value": QSRGrossProfitMargin,
      "unit": "percent"
    },
    "net_profit_qsr": {
      "value": QSRTotalRevenue * 0.1,
      "unit": "dollar"
    },
    "payroll_percentage_qsr": {
      "value": 20,
      "unit": "percent"
    },
    "prime_costs_qsr": {
      "value": QSRTotalRevenue * 0.57,
      "unit": "dollar"
    },
    "sales_per_labor_hour_qsr": {
      "value": QSRSalesPerLaborHour,
      "unit": "dollar"
    },
    "spend_per_ticket_qsr": {
      "value": QSRSpendPerTicket,
      "unit": "dollar"
    },
    "total_expenses_qsr": {
      "value": QSRTotalRevenue * 0.9,
      "unit": "dollar"
    },
    "total_food_costs_qsr": {
      "value": QSRTotalRevenue * 0.3,
      "unit": "dollar"
    },
    "total_labor_cost_qsr": {
      "value": QSRTotalRevenue * 0.27,
      "unit": "dollar"
    },
    "total_occupancy_costs_qsr": {
      "value": QSRTotalRevenue * 0.08,
      "unit": "dollar"
    },
    "total_other_costs_qsr": {
      "value": QSRTotalRevenue * 0.12,
      "unit": "dollar"
    },
    "total_paper_costs_qsr": {
      "value": QSRTotalRevenue * 0.04,
      "unit": "dollar"
    },
    "total_sales_qsr": {
      "value": QSRTotalSales,
      "unit": "dollar"
    }
  },
  "11": { // SaaS
    "total_revenue_saas": {
      "value": SaaSTotalRevenue,
      "unit": "dollar"
    },
    "total_expenses_saas": {
      "value": SaaSTotalRevenue * 0.9,
      "unit": "dollar"
    },
    "net_profit_saas": {
      "value": SaaSTotalRevenue * 0.1,
      "unit": "dollar"
    },
    "monthly_recurring_revenue_saas": {
      "value": SaaSMonthlyRecurringRevenue,
      "unit": "dollar"
    },
    "customer_acquisition_cost_saas": {
      "value": SaaSCustomerAcquisitionCost,
      "unit": "dollar"
    },
    "annual_recurring_revenue_saas": {
      "value": SaaSAnnualRecurringRevenue,
      "unit": "dollar"
    },
    "churn_rate_saas": {
      "value": SaaSChurnRate,
      "unit": "percent"
    },
    "customer_count_saas": {
      "value": SaaSCustomerCount,
      "unit": "dollar"
    }
  }
};
