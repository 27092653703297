import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IDocument } from '@src/types/documents';

import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';
import Form from '@src/components/ui_v2/form';
import TextAreaInput from '@src/components/ui_v2/inputs/text_area_input';

import DocumentsSection from '../documents_section';

import styles from '../document_list.module.scss';

interface IDocumentModel {
  id: TID;
  business_id: TID;
  bank_statement: IBankStatement;
  isBankStatementRequest: () => boolean;
  isBalanceSheetStatementRequest: () => boolean;
  get: (key: string) => string | number | null;
}

interface DocumentConversationRightSideViewProps {
  businessId: TID;
  model: IDocumentModel;
  bankStatement: IBankStatement;
  onClose: () => void;
}

interface IConversationFormInput {
  documents: IDocument[];
  note?: string;
}

const DocumentConversationRightSideView: React.FC<DocumentConversationRightSideViewProps> = ({
  bankStatement,
  businessId,
  model,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isDocumentsExpanded, setIsDocumentsExpanded] = useState<boolean>(true);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [noStatementAvailable, setNoStatementAvailable] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IConversationFormInput>();

  const handleNoteChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  }, []);

  const toggleDocumentsSection = useCallback(() => {
    setIsDocumentsExpanded((prev) => !prev);
  }, []);

  const onSubmit = useCallback(async (_data: IConversationFormInput) => {
    try {
      setIsLoading(true);
    } catch {
      setErrorMessage('An error occurred while saving the conversation');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getReviewButtonText = useCallback(() => {
    const btnText = 'Mark as Reviewed';
    const reviewText = 'Review Next';
    return `${btnText} - ${reviewText}`;
  }, []);

  const handleCloseClick = useCallback(() => {
    window.Docyt.vent.trigger('request:item:unselected');
    onClose();
  }, [onClose]);

  const handleDocumentsUpdate = useCallback((newDocuments: IDocument[]) => {
    setDocuments(newDocuments);
  }, []);

  const fetchDocuments = useCallback(async () => {
    try {
      setIsLoading(true);
    } catch {
      setErrorMessage('Failed to load documents');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const documentModel = useMemo(() => ({
    id:                             model.id,
    business_id:                    businessId,
    bank_statement:                 bankStatement,
    isBankStatementRequest:         () => false,
    isBalanceSheetStatementRequest: () => false,
    get:                            (key: string) => {
      if (key === 'business_id') return businessId;
      return null;
    },
  }), [model.id, businessId, bankStatement]);

  useEffect(() => {
    if (businessId) {
      fetchDocuments();
    }
  }, [businessId, fetchDocuments]);

  return (
    <div>
      <SideView.Form
        isOpen
        isRoot
        doneTitle={ getReviewButtonText() }
        isSubmitDisabled={ isSubmitting }
        title="Conversations"
        onCancel={ handleCloseClick }
      >
        {({ formId }) => (

          <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
            {isLoading && <Spinner />}
            {errorMessage && (
              <SideView.Error>
                <div className="error-message">
                  <span>{errorMessage}</span>
                </div>
              </SideView.Error>
            )}
            <div className={ styles.sections }>
              <DocumentsSection
                bankStatement={ bankStatement }
                documents={ documents }
                isDocumentsExpanded={ isDocumentsExpanded }
                isUploading={ isUploading }
                model={ documentModel }
                noStatementAvailable={ noStatementAvailable }
                setIsUploading={ setIsUploading }
                setNoStatementAvailable={ setNoStatementAvailable }
                toggleDocumentsSection={ toggleDocumentsSection }
                onDocumentsUpdate={ handleDocumentsUpdate }
              />

              <hr className={ styles['section-divider'] } />

              <section>
                <div className={ styles['section-header'] }>
                  <h1 className={ styles['notes-section-header'] }>Notes</h1>
                </div>
                <TextAreaInput
                  className={ styles['note-input'] }
                  placeholder="Add note"
                  value={ note }
                  onChange={ handleNoteChange }
                />
              </section>
            </div>
          </Form>
        )}
      </SideView.Form>
    </div>
  );
};

export default DocumentConversationRightSideView;
