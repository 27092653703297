/* eslint-disable max-len */
import Big from 'big.js';
import moment from 'moment';

import { useReconciliationPaymentAccountContext } from '@src/hooks/contexts/reconciliation_payment_account_context';
import { IBankAccountReconciliation, IBankAccountReconciliationItem, IBankAccountReconciliationItemTypes } from '@src/types/bank_account_reconciliations';
import { IBankStatement } from '@src/types/bank_statements';
import { TAmount } from '@src/types/common';
import { amountToNumber } from '@src/utils/amount_helper';
import { API_DATE_FORMAT } from '@src/utils/date_helpers';
import { camelizeKeys } from '@src/utils/transform_keys';

export const RECONCILIATION_CENTER_DRAWER_TABLE_HEIGHT = 'calc(80vh - 150px)';

interface ItemTypesMap {
  [key: string]: IBankAccountReconciliationItem,
}

interface ReconciliationItemCheckResult {
  result: 'success' | 'warn' | 'error'
  message?: string
}

const isVerified = (bankStatement: IBankStatement) => {
  const VERIFIED = window.Docyt.Common.Constants.BANK_STATEMENT_STATES.VERIFIED;
  return bankStatement?.state === VERIFIED;
};

const isNotAvailable = (bankStatement: IBankStatement) => {
  const NOT_AVAILABLE = window.Docyt.Common.Constants.BANK_STATEMENT_STATES.NOT_AVAILABLE;
  return bankStatement?.state === NOT_AVAILABLE;
};

const isBankStatementCheckPassed = (bankStatement: IBankStatement) => isVerified(bankStatement) || isNotAvailable(bankStatement);

const getReconciliationDateRange = (
  reconciliation: IBankAccountReconciliation,
  bankStatement: IBankStatement,
) => {
  const { year, month } = reconciliation;

  let startDate = moment([year, month - 1]).format(API_DATE_FORMAT);
  let endDate = moment(startDate).endOf('month').format(API_DATE_FORMAT);

  if (bankStatement !== null) {
    startDate = bankStatement.startingDate;
    endDate = bankStatement.closingDate;
  }

  return [startDate, endDate];
};

const buildItemTypesFromArray = (
  items: IBankAccountReconciliationItem[],
): IBankAccountReconciliationItemTypes => {
  const reducer = (memo: ItemTypesMap, obj: IBankAccountReconciliationItem): ItemTypesMap => {
    memo[obj.itemType as string] = obj;
    return memo;
  };
  const itemTypesMap = items.reduce(reducer, {} as ItemTypesMap);

  return camelizeKeys(itemTypesMap) as IBankAccountReconciliationItemTypes;
};

const nonZeroAmount = (amount: TAmount | undefined | null): boolean => {
  if (!amount) return false;

  return Number(`${amount}e2`) !== 0.0;
};

const checkReconciliationItem = (
  item: IBankAccountReconciliationItem,
  items: IBankAccountReconciliationItemTypes,
  bankStatement: IBankStatement,
): ReconciliationItemCheckResult => {
  const ret: ReconciliationItemCheckResult = { result: 'success' };

  const isCreditCard = bankStatement?.reconciliationPaymentAccount?.accountType === 'credit_card';
  let amount = 0;
  let bankStatementAmount = Big(0);
  let isSuccess = false;

  switch (item.itemType) {
    case 'bank_feed_verification':
      ret.result = isBankStatementCheckPassed(bankStatement) ? 'success' : 'error';
      ret.message = isBankStatementCheckPassed(bankStatement) ? '' : "Verify the bank feed or mark as 'No Statement Available'.";
      return ret;
    case 'pushed_by_docyt_transactions':
      amount = Number(item.amount || '0');

      bankStatementAmount = Big(bankStatement?.debits || '0');
      bankStatementAmount = bankStatementAmount.add(Big(bankStatement?.credits || '0'));
      isSuccess = isCreditCard ? Big(amount).eq(bankStatementAmount.neg()) : Big(amount).eq(bankStatementAmount);
      isSuccess = isSuccess && !nonZeroAmount(item.details?.missingInQboAmount?.toString() || '0');

      ret.result = isSuccess ? 'success' : 'warn';
      ret.message = isSuccess ? '' : 'The bank statement amount does not match the amount from the ledger!';
      return ret;
    case 'uncategorized_transactions':
      ret.result = Number(item.count || 0) > 0 ? 'warn' : 'success';
      ret.message = Number(item.count || 0) > 0 ? 'There are uncategorized transactions' : '';
      return ret;
    case 'flagged_transactions':
      ret.result = Number(item.count || 0) > 0 ? 'warn' : 'success';
      ret.message = Number(item.count || 0) > 0 ? 'There are flagged transactions' : '';
      return ret;
    case 'unmatched_transfers':
      ret.result = Number(item.count || 0) > 0 ? 'warn' : 'success';
      ret.message = Number(item.count || 0) > 0 ? 'There are unmatched transfers' : '';
      return ret;
    case 'uncleared_bill_payments':
      return ret;
    case 'uncleared_transfers':
      return ret;
    case 'additional_uncleared_balance':
      amount = Number(item.amount || '0');
      amount += Number(items.additionalUnclearedBalanceCarriedOver.amount || '0');
      ret.result = nonZeroAmount(amount.toString()) ? 'warn' : 'success';
      ret.message = nonZeroAmount(amount.toString()) ? 'There are uncleared additional balances' : '';
      return ret;
    case 'difference':
      amount = Number(item.amount || '0');
      ret.result = nonZeroAmount(amount.toString()) ? 'warn' : 'success';
      ret.message = nonZeroAmount(amount.toString()) ? "'This month' difference should be zero!" : '';
      return ret;
    case 'undocumented_transactions':
      ret.result = Number(item.count || 0) > 0 ? 'warn' : 'success';
      ret.message = Number(item.count || 0) > 0 ? 'There are undocumented transactions' : '';
      return ret;
    default:
      return ret;
  }
};

// eslint-disable-next-line max-len
const amountFormatterX = (amount: TAmount | number | undefined | null) => {
  const numberAmount = amountToNumber(amount);

  return numberAmount
    .toLocaleString(
      'en-US',
      {
        style:                 'currency',
        currency:              'USD',
        minimumFractionDigits: 2,
      },
    );
};

// eslint-disable-next-line max-len
const amountFormatter = (amount: TAmount | number | undefined | null) => {
  return amountFormatterX(amount);
};

const usePaymentAccountName = () => {
  const reconciliationPaymentAccount = useReconciliationPaymentAccountContext();
  const paymentAccountIds = reconciliationPaymentAccount.paymentAccounts;

  const dropdownFilters: Record<string, number> = {};
  const paymentAccountFilters: Record<string, number> = {};
  if (paymentAccountIds.length > 1) {
    paymentAccountIds.forEach((account, index) => {
      paymentAccountFilters[`payment_account_id[${index}]`] = account.id;
      dropdownFilters[`dropdown_field[${index}]`] = account.id;
    });
    return {
      ...paymentAccountFilters,
      ...dropdownFilters,
    };
  }

  const accountId = reconciliationPaymentAccount.id;
  paymentAccountFilters.reconciliation_payment_account_id = accountId;
  dropdownFilters['dropdown_field[0]'] = accountId;
  return {
    ...paymentAccountFilters,
    ...dropdownFilters,
  };
};

export {
  buildItemTypesFromArray,
  nonZeroAmount,
  amountFormatter,
  amountFormatterX,
  isVerified,
  getReconciliationDateRange,
  usePaymentAccountName,
  checkReconciliationItem,
  ReconciliationItemCheckResult,
};
