import React from 'react';

import { TBookkeepingStatus } from '@src/types/business_bookkeeping_statuses';

import { Button } from '@src/components/ui_v2/buttons';

interface CompleteButtonProps {
  onDone: (data: any) => void,
}

const CompleteButton = ({ onDone }: CompleteButtonProps) => {
  return (
    <Button variant="primary" onClick={ () => onDone('reconciled' as TBookkeepingStatus) }>
      Mark as Complete
    </Button>
  );
};

export default React.memo(CompleteButton);
