import React, { FC, useEffect, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useJournalEntryFetchedFayeChannel } from '@src/hooks/faye/fetch_qbo_entry';
import { useDebouncedCallback } from '@src/hooks/utils';
import { IFayeMessage, IGetAdjustmentEntryParams, useGetAdjustmentEntryByParams } from '@src/requests/adjustment_entries';
import { IJournalEntryFetchedEvent } from '@src/types/docyt_events/journal_entry_fetched';

import { SpinnerIcon } from '@src/components/utils/fa_icons';

import DetailTable from './detail_table';
import EditPanel from './edit_panel';
import NameInfo from './name_info';

import styles from './styles.module.scss';

const EventDrivenFetchContent: FC<{params: IGetAdjustmentEntryParams}> = ({ params }) => {
  const business = useBusinessContext();
  const businessId = business.id;
  const [result, setResult] = useState<IJournalEntryFetchedEvent>({ qboId: '', businessId });
  const [isLoading, setIsLoading] = useState(true);
  const { qboId } = params;

  const [isSubscribed, setIsSubscribed] = useState(false);

  // Add a Faye extension to listen for successful subscription confirmations.
  // This ensures that the API call via React Query is only enabled after
  // the client has successfully subscribed to the specified Faye channel.
  // This has order because if we call useGetAdjustmentEntryByParams without the subscribe successful,
  // we can't receive the worker process end response and lead to the page still loading.
  useEffect(() => {
    const extension = {
      incoming: (message: IFayeMessage, callback: (msg: IFayeMessage) => void) => {
        if (
          message.channel === '/meta/subscribe'
          && message.successful
          && message.subscription === `/journal_entry_qbo_id-${businessId}-${qboId}`
        ) {
          setIsSubscribed(true);
        }
        callback(message);
      },
    };

    window.Docyt.fayeClient.addExtension(extension);

    return () => {
      window.Docyt.fayeClient.removeExtension(extension);
    };
  }, [qboId, businessId]);

  const handleDataReceived = useDebouncedCallback(({ event }) => {
    if (event?.qboId === qboId && event?.businessId === businessId) {
      setIsLoading(false);
      setResult(event);
    }
  }, [qboId, businessId], 300);

  useJournalEntryFetchedFayeChannel(qboId as string, businessId, handleDataReceived);

  useGetAdjustmentEntryByParams(params, businessId, { enabled: isSubscribed });

  const { error, adjustmentEntry } = result;

  if (isLoading) {
    return <div className={ styles['loading-container'] }><SpinnerIcon spin fontSize={ 30 } /></div>;
  }

  if (error) {
    return (
      <div className={ styles['error-container'] }>
        <span>Error:</span>
        { error }
      </div>
    );
  }

  if (adjustmentEntry) {
    return (
      <div className={ styles.container }>
        <div className={ styles.header }>
          <NameInfo data={ adjustmentEntry } />
          <EditPanel data={ adjustmentEntry } />
        </div>

        <DetailTable data={ adjustmentEntry } />
      </div>
    );
  }

  return null;
};

export default EventDrivenFetchContent;
