import { useCallback, useEffect } from 'react';

import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';

import { TSection } from '@src/types/common';

import { useSection } from '@src/components/utils_v2/section';

import { itemsSelector } from './atoms';
import { IItemSelector, IItemsSelector, TItemsSelectionState } from './types';

const useInitItemsSelector = <T>(allItems: T[], sectionParam?: TSection,
  selectedItems?: T[]): void => {
  const section = useSection(sectionParam);

  const setItemsSelector = useSetRecoilState(itemsSelector(section));

  useEffect(() => {
    setItemsSelector((oldData) => {
      if (difference(oldData.selected, allItems).length > 0
        || (selectedItems && difference(selectedItems, allItems).length > 0)) {
        // If new all array doesn't include selected items,
        // then selected is not valid anymore and should be cleared
        return { ...oldData, all: allItems, selected: [] };
      }

      if (selectedItems) return { ...oldData, all: allItems, selected: selectedItems };

      return { ...oldData, all: allItems };
    });
  }, [allItems, setItemsSelector, selectedItems]);
};

const useItemsSelector = (sectionParam?: TSection): IItemsSelector => {
  const section = useSection(sectionParam);

  const { all, selected } = useRecoilValue(itemsSelector(section));

  const getSelectionState = useCallback((): TItemsSelectionState => {
    if (all.length === 0) return 'none';
    if (isEqual(sortBy(selected), sortBy(all))) return 'all';
    if (selected.length > 0) return 'some';
    return 'none';
  }, [selected, all]);

  const markAll = useRecoilCallback(({ set }) => (checked: boolean) => {
    set(itemsSelector(section), (oldData) => {
      if (checked) return { ...oldData, selected: oldData.all };

      return { ...oldData, selected: [] };
    });
  }, [section]);

  const reset = useRecoilCallback(({ set }) => () => {
    set(itemsSelector(section), (oldData) => {
      return {
        ...oldData,
        selected: [],
      };
    });
  }, [section]);

  const setSelected = useRecoilCallback(({ set }) => (newSelected: number[]) => {
    set(itemsSelector(section), (oldData) => {
      return { ...oldData, selected: newSelected };
    });
  }, [section]);

  return {
    getSelectionState,
    markAll,
    reset,
    selected,
    setSelected,
  };
};

const useItemSelector = <T>(sectionParam?: TSection): IItemSelector => {
  const section = useSection(sectionParam);

  const { selected } = useRecoilValue(itemsSelector(section));

  const mark = useRecoilCallback(({ set }) => (item: T, checked: boolean) => {
    set(itemsSelector(section), (oldData) => {
      if (!checked) {
        return {
          ...oldData,
          selected: oldData.selected.filter((i) => i !== item),
        };
      }

      if (oldData.selected.includes(item)) return oldData;

      return { ...oldData, selected: oldData.selected.concat([item]) };
    });
  }, [section]);

  const isSelected = useCallback((item: T) => {
    return selected.includes(item);
  }, [selected]);

  const toggleMark = useRecoilCallback(({ set }) => (item: T) => {
    set(itemsSelector(section), (oldData) => {
      if (oldData.selected.includes(item)) {
        return {
          ...oldData,
          selected: oldData.selected.filter((i) => i !== item),
        };
      }

      return { ...oldData, selected: oldData.selected.concat([item]) };
    });
  }, [section]);

  const setSelected = useRecoilCallback(({ set }) => (item: T) => {
    set(itemsSelector(section), (oldData) => {
      return { ...oldData, selected: [item] };
    });
  }, [section]);

  return {
    mark,
    isSelected,
    toggleMark,
    setSelected,
  };
};

export {
  useInitItemsSelector,
  useItemSelector,
  useItemsSelector,
};
