import React from 'react';

import { TID } from '@src/types/common';

import { QBOSyncFilterField } from '@src/components/common_v2/filter_fields';
import Filter from '@src/components/ui_v2/filter';

import StatusFilterField from './fields/status_filter_field';

const InvoiceFilter = ({ businessId }: { businessId: TID }) => {
  return (
    <Filter.TableContainer>
      <Filter.DateField
        name="invoice_date"
        placeholder="From-To"
      />
      <QBOSyncFilterField
        name="qbo_sync_status"
        placeholder="All"
      />
      <StatusFilterField
        placeholder="All Statuses"
      />
    </Filter.TableContainer>
  );
};

export default React.memo(InvoiceFilter);
