/* eslint-disable max-len */
import React, { FC, useCallback } from 'react';

import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useLocalStorage } from '@src/hooks/storage';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';

import { amountFormatterX } from '@src/components/reconciliation_center/month_end/utils';
import Table from '@src/components/ui_v2/table';
import { AttachIcon, EditIcon, MoreInfoIcon } from '@src/components/utils/icomoon';

import DeleteIcon from './delete_icon';
import { useTotalAmount } from './use_journal_entry_amount';
import ViewIcon from './view_icon';

import styles from '@src/components/reconciliation_center/month_end/styles.module.scss';

interface ActionProps {
  editable?: boolean;
  item: IAdjustmentEntry;
  openDrawer: (item?: IAdjustmentEntry) => void;
}

interface AdjustmentJournalEntriesProps {
  editable?: boolean;
  openDrawer: (item?: IAdjustmentEntry) => void;
}

const Action: FC<ActionProps> = ({ editable, item, openDrawer }) => {
  const business = useBusinessContext();
  const viewMore = () => {
    Backbone.history.navigate(
      `/businesses/${business.id}/reconciliation_center/journal_entry?docyt_id=${item.docytId}`,
      { trigger: true },
    );
  };

  if (!editable || item.source.type !== 'Normal') {
    return <MoreInfoIcon pointer fontSize={ 12 } inColor="grey-500" onClick={ viewMore } />;
  }

  return (
    <>
      <span style={ { marginRight: '8px' } }>
        <EditIcon pointer fontSize={ 12 } inColor="grey-500" onClick={ () => openDrawer(item) } />
      </span>
      <span>
        <DeleteIcon id={ item.id } />
      </span>
    </>
  );
};

const AdjustmentJournalEntries: FC<AdjustmentJournalEntriesProps> = ({ editable, openDrawer }) => {
  const { adjustmentEntries } = useBankAccountReconciliationContext();
  const totalAdjustmentAmount = useTotalAmount(adjustmentEntries);

  const { id } = useBusinessContext();
  const breakup = useLocalStorage(`adjustment_entries_breakup_toggle_${id}`, false);
  const breakupValue = breakup.storedValue;
  const handleBreakup = useCallback(() => {
    breakup.setValue(!breakupValue);
  }, [breakup, breakupValue]);

  const Breakup = adjustmentEntries.map((it, index) => (
    <Table.Row key={ it.id } className={ styles['row-second-level'] }>
      <Table.TextCell />
      <Table.TextCell hideValueTooltip>
        <div className={ styles['cell-stats'] }>
          <span>
            #
            { index + 1 }
          </span>
          <span>
            { amountFormatterX(it.amount) }
          </span>
        </div>
      </Table.TextCell>
      <Table.TextCell>
        <Action editable={ editable } item={ it } openDrawer={ openDrawer } />
      </Table.TextCell>
    </Table.Row>
  ));

  return (
    <>
      <Table.Row className={ styles.row }>
        <Table.TextCell
          hideValueTooltip
          className={ breakupValue ? styles['row-arrow-down'] : styles['row-arrow-right'] }
          onClick={ handleBreakup }
        >
          Adjustment Journal Entries
        </Table.TextCell>
        <Table.TextCell hideValueTooltip>
          <div className={ styles['cell-stats'] }>
            <span>{ adjustmentEntries.length }</span>
            <span>{ amountFormatterX(totalAdjustmentAmount) }</span>
          </div>
        </Table.TextCell>
        <Table.IconCell>
          {
            editable
              ? <AttachIcon pointer fontSize={ 22 } inColor="grey-500" onClick={ () => openDrawer() } />
              : <ViewIcon />
          }
        </Table.IconCell>
        <Table.TextCell />
      </Table.Row>
      { breakupValue && Breakup }
    </>
  );
};

export default AdjustmentJournalEntries;
