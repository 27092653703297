import React from 'react';

import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import { BusinessDefaultAvatarIcon } from '@src/components/utils/icomoon';

import EditLinkedBusinessesAction from './actions/edit_linked_businesses_action';

import styles from './styles.module.scss';

interface ILikedBusinessesViewProps {
  businessId: TID,
  vendorId: TID,
  businesses: IBusiness[],
  refetchBusinesses: () => void;
}

const businessAvatar = (
  business: IBusiness,
) => {
  if (business.avatarUrl === null) {
    return (
      <BusinessDefaultAvatarIcon
        className="m-r-10"
        fontSize={ 24 }
      />
    );
  }

  return (
    <img
      alt="Avatar"
      className={ styles['business-avatar'] }
      src={ business.avatarUrl }
    />
  );
};

const LikedBusinessesView = ({
  businessId,
  vendorId,
  businesses,
  refetchBusinesses,
}: ILikedBusinessesViewProps): JSX.Element | null => {
  return (
    <div className={ styles.pane }>
      <div className={ styles.header }>
        <span className={ styles.title }>Businesses</span>
        <EditLinkedBusinessesAction
          businessId={ businessId }
          linkedBusinesses={ businesses }
          vendorId={ vendorId }
          onDone={ refetchBusinesses }
        />
      </div>
      <ul>
        {
          businesses.map((business) => (
            <li
              key={ business.id }
              className={ styles.item }
            >
              { businessAvatar(business) }
              <span>{ business.name }</span>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default LikedBusinessesView;
