import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { BusinessChartOfAccounts, getSystemCategories } from '@src/requests/system_categories';
import { TID } from '@src/types/common';

const useGetSystemCategories = (businessId: TID): BusinessChartOfAccounts | undefined => {
  const { data } = useQuery<BusinessChartOfAccounts, Error>(
    [QueryKey.systemCategoriesQuery, businessId],
    () => getSystemCategories(businessId),
  );

  return data; // Return the data directly
};
export {
  useGetSystemCategories,
};
