module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="client__mes-person-card clearfix message-item p-l-16 ');
    
      __out.push(__sanitize(!this.time_message ? 'p-t-16' : void 0));
    
      __out.push('">\n  <div class="client__mes-person-card-edit">\n    <i class="fa fa-caret-down triangle-item message-triangle-item"></i>\n    <div class="client__mes-menu-bar message-context-menu" style="display: none;">\n      ');
    
      if (this.sender_id === Docyt.currentAdvisor.id) {
        __out.push('\n        <a class="in-pink-400 delete-message"><i class="icon icon-trashcan"></i>Delete message</a>\n      ');
      } else {
        __out.push('\n        <a class="mark-unread-message">Mark as Unread</a>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n\n  <div class="client__mes-avatar-wrapper">\n  ');
    
      if (!this.time_message) {
        __out.push('\n    ');
        if (this.avatarUrl) {
          __out.push('\n      <div class="client__mes-userpic">\n        <img src="');
          __out.push(__sanitize(this.avatarUrl));
          __out.push('" class=\'header__userpic-img\'></img>\n      </div>\n    ');
        } else {
          __out.push('\n      <i class=\'client__nav-client-info-img-icon icon icon-smile in-blue-400 avatar-message\'></i>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n  </div>\n  ');
    
      if (!this.time_message) {
        __out.push('\n    <span class="client__mes-sender-name font-semi-bold">');
        __out.push(__sanitize(this.sender_name));
        __out.push('</span>\n    <span class="client__mes-sender-time">');
        __out.push(__sanitize(this.timestamp));
        __out.push('</span>\n  ');
      }
    
      __out.push('\n\n  <div class="client__mes-content">\n    ');
    
      if (this.uploading) {
        __out.push('\n      <p class="client__mes-content-text">Uploading document:</p>\n    ');
      } else {
        __out.push('\n      <p class="client__mes-content-text">Shared document:</p>\n    ');
      }
    
      __out.push('\n  </div>\n\n  <div class="message-file-upload-wrapper">\n    <a class="message-file-upload-image show-document-js">\n      ');
    
      if (this.uploading) {
        __out.push('\n        <span class=\'color-icon icon-xls-file file-icon\'></span>\n      ');
      } else {
        __out.push('\n        ');
        if (this.previewImage) {
          __out.push('\n          <span>\n            <img class=\'first-page-icon file-icon\'></img>\n          </span>\n        ');
        } else {
          __out.push('\n          ');
          if (this.hasAccessDocument) {
            __out.push('\n            <span class=\'color-icon icon-xls-file file-icon\'></span>\n          ');
          } else {
            __out.push('\n            <span class=\'icon icon-lock\'></span>\n          ');
          }
          __out.push('\n        ');
        }
        __out.push('\n      ');
      }
    
      __out.push('\n    </a>\n    <div class="message-file-upload-info">\n      ');
    
      if (this.uploading) {
        __out.push('\n        <p class="message-file-upload-name">');
        __out.push(__sanitize(this.fileName));
        __out.push('</p>\n        <p class="message-file-upload-progress progress-upload">0% of ');
        __out.push(__sanitize(this.fileSize));
        __out.push(' </p>\n        <div class="upload-file-statusbar">\n          <div class="file-status-line" style="width: 0%;"></div>\n        </div>\n      ');
      } else {
        __out.push('\n        <a class="show-document-js pointer">\n          <p class="message-file-upload-name">');
        __out.push(__sanitize(this.fileName));
        __out.push('</p>\n        </a>\n        <p class="message-file-document-info"> ');
        __out.push(__sanitize(this.fileInfo));
        __out.push(' </p>\n        <div class="message-document-download-progress">\n          <p class="message-document-download-progress-info">0% of ');
        __out.push(__sanitize(this.fileSize));
        __out.push(' </p>\n          <div class="upload-file-statusbar">\n            <div class="file-status-line" style="width: 0%;"></div>\n          </div>\n        </div>\n      ');
      }
    
      __out.push('\n    </div>\n    <div class="message-file-upload-icon">\n      ');
    
      if (this.uploading) {
        __out.push('\n        <i class="fa fa-times-circle-o cancel-upload"></i>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}