import { TID } from '@src/types/common';
import { IReceiptService } from '@src/types/receipt_service';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetReceiptServiceByBusinessIdParams {
  businessId: TID;
}

type TReceiptServiceResponse = IReceiptService;

const getReceiptServiceByBusinessId = ({ businessId }: IGetReceiptServiceByBusinessIdParams): Promise<TReceiptServiceResponse> => {
  return apiGet(
    '/api/v1/receipt_services/get_by_business_id',
    underscoreKeys({ businessId }),
  ).then((data) => camelizeKeys(data.receipt_service) as TReceiptServiceResponse);
};

export {
  getReceiptServiceByBusinessId,
};
