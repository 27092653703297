import React, { useMemo } from 'react';

import flatten from 'lodash/flatten';

import { useGetPastTransactions } from '@src/hooks/queries/transaction_service_documents';
import { IBusiness } from '@src/types/businesses';
import { ISortingParams } from '@src/types/sorting';
import { 
  ITransactionServiceDocument, 
  ITransactionServiceDocumentFilterQuery, 
  TTransactionServiceDocumentsSortColumn, 
} from '@src/types/transaction_service_documents';

import CollapseSection from '@src/components/ui/form/collapse_section';
import { AppLink } from '@src/components/ui_v2/buttons';
import QueryStatus from '@src/components/utils/query_status';

import List from './list';

interface IPastTransactionsDetailsProps {
  business: IBusiness;
  transaction: ITransactionServiceDocument;
}

const PastTransactionsDetails = ({
  business,
  transaction,
}: IPastTransactionsDetailsProps): JSX.Element => {
  const filterParams = useMemo<ITransactionServiceDocumentFilterQuery>(() => ({
    vendorId:        transaction.vendorId,
    transactionDate: { lte: transaction.transactionDate },
  }), [transaction.vendorId, transaction.transactionDate]);

  const sorting = useMemo<ISortingParams<TTransactionServiceDocumentsSortColumn>>(() => ({
    orderColumn:    'verified_at',
    orderDirection: 'desc',
  }), []);

  const pastTrasactionsQuery = useGetPastTransactions({
    withMatches:     true,
    businessId:      business.id,
    exclude:         transaction.id,
    transactionType: window.Docyt.Common.Constants.EXPENSE_TRANSACTION_TYPES,
    page:            1,
    perPage:         5,
    filter:          filterParams,
    ...sorting,
  });

  return (
    <div>
      <QueryStatus query={ pastTrasactionsQuery } />
      <CollapseSection
        suffix={ (
          <AppLink href={ `/businesses/${business.id}/reconciliation_center/expenses` }>View All</AppLink>
        ) }
        title="PAST TRANSACTIONS"
      >
        <List
          business={ business }
          pastTransactions={ flatten(pastTrasactionsQuery?.data?.collection || []) }
          transaction={ transaction }
        />
      </CollapseSection>
    </div>
  );
};

export {
  IPastTransactionsDetailsProps,
  PastTransactionsDetails as default,
};
