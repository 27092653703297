import React from 'react';

import { useGetAccountsPayableServiceByBusinessId } from '@src/hooks/queries/accounts_payable/accounts_payable_services';
import { accountsPayableSettingsQueuePath, achTransactionsPath, approvalAuditControlsPath, checkRegisterPath, invoiceQueuePath, paymentMethodSetupPath, paymentRulesQueuePath, settlementItemsPath } from '@src/routes';
import { TID } from '@src/types/common';

import ServiceMenu from '@src/components/common/service_menu';
import ServiceSidebar from '@src/components/ui_v2/service_sidebar';

interface IBillPaySidebarProps {
  businessId: TID
}

const BillPaySidebarMenuItems = ({ businessId }: IBillPaySidebarProps) => {
  const { data: accountPayableService, isLoading } = useGetAccountsPayableServiceByBusinessId(businessId);

  if (isLoading) return null;

  return (
    <ServiceSidebar
      serviceMenu={ (
        <ServiceMenu
          serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.BILL_PAY }
        />
      ) }
    >
      <ServiceSidebar.Item
        href={ invoiceQueuePath(businessId) }
        itemCount={ String(accountPayableService?.countQueuedInvoices) }
        title="Invoice Queue"
      />
      <ServiceSidebar.Separator />
      <ServiceSidebar.Item
        href={ checkRegisterPath(businessId) }
        title="Check Register"
      />
      <ServiceSidebar.Item
        href={ achTransactionsPath(businessId) }
        title="ACH Register"
      />
      <ServiceSidebar.Item
        href={ settlementItemsPath(businessId) }
        itemCount={ String(accountPayableService?.countQueuedSettled) }
        title="Inter-entity Settlements"
      />
      <ServiceSidebar.Separator />
      <ServiceSidebar.Item
        href={ approvalAuditControlsPath(businessId) }
        title="Approval & Audit Controls"
      />
      <ServiceSidebar.Item
        href={ paymentRulesQueuePath(businessId) }
        itemCount={ String(accountPayableService?.countPayableRules) }
        title="Payment Rules"
      />
      <ServiceSidebar.Item
        href={ paymentMethodSetupPath(businessId) }
        title="Payment Method Setup"
      />
      <ServiceSidebar.Item
        href={ accountsPayableSettingsQueuePath(businessId) }
        title="Settings"
      />
    </ServiceSidebar>
  );
};

export default BillPaySidebarMenuItems;
