import React, { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface IHeaderRegionActionsAreaProps {
  children: ReactNode;
  className?: string;
}

const HeaderRegionActionsArea = ({ children, className }: IHeaderRegionActionsAreaProps): JSX.Element => {
  return (
    <div className={ classNames(styles.area, className) }>
      {children}
    </div>
  );
};

export default React.memo(HeaderRegionActionsArea);
