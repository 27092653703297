import React, { useCallback } from 'react';

import classNames from 'classnames';

import toastr from '@lib/toastr';
import { useCreateBusinessMetricBatchValues } from '@src/hooks/queries/business_metric_value';
import { IBusinessMetricValue } from '@src/types/business_metric_value';
import { IMetric } from '@src/types/metrics';
import { formatDate } from '@src/utils/date_helpers';

import { useChangeStartDateModal } from '@src/components/metrics_service/details/list_item/change_start_date';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { Button } from '@src/components/ui_v2/buttons';
import Filter from '@src/components/ui_v2/filter';
import { EditIcon, MetircsChangeStartDateIcon } from '@src/components/utils/icomoon';
import Section from '@src/components/utils_v2/section';

import { IMetricValuesCollection } from '../hooks';

import styles from '../styles.module.scss';

interface MetricsServiceHeaderProps {
  metric: IMetric,
  collection: IMetricValuesCollection,
  isEditMode: boolean,
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  editingValues: IBusinessMetricValue[];
}

const MetricsServiceHeader = ({
  metric,
  collection,
  isEditMode,
  setIsEditMode,
  editingValues,

}: MetricsServiceHeaderProps): JSX.Element => {
  const changeStartDate = useChangeStartDateModal();

  const createBusinessMetricBatchValues = useCreateBusinessMetricBatchValues();
  const { mutate } = createBusinessMetricBatchValues;

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleCancelEdit = () => {
    setIsEditMode(false);
  };

  const handleSaveData = useCallback(() => {
    mutate(
      {
        businessMetricId: metric.id,
        values:           editingValues,
      },
      {
        onSuccess: () => {
          setIsEditMode(false);
        },
        onError: (error) => {
          toastr.error(
            error.message || 'An unexpected error occurred',
            'Error',
          );
        },
      },
    );
  }, [editingValues, mutate, metric.id, setIsEditMode]);

  return (
    <>
      <div className={ styles['item-container'] }>
        <div className={ styles['item-description'] }>
          <span>
            Last Updated
          </span>
          <span>
            <strong>
              { formatDate(metric.lastUpdatedAt) }
            </strong>
          </span>
        </div>
      </div>
      <div className={ classNames(styles['item-container'], styles['divider-vertical']) }>
        <div className={ styles['item-description'] }>
          <span>
            Start Date
          </span>
          <span>
            <strong>
              { formatDate(metric.startDate) }
            </strong>
          </span>
        </div>
      </div>
      <div className={ classNames(styles['item-container'], styles['divider-vertical']) }>
        <div className={ styles['item-description'] }>
          <span>
            Frequency
          </span>
          <span>
            <strong>
              { metric.frequency }
            </strong>
          </span>
        </div>
      </div>

      <div className={ styles['item-container'] }>
        <Section.Provider section={ collection.section }>
          <Filter.TableContainer>
            <Filter.MonthField name="month" />
          </Filter.TableContainer>
        </Section.Provider>
      </div>

      {isEditMode && (
        <div className={ styles['edit-data-action'] }>
          <Button
            variant="link"
            onClick={ handleCancelEdit }
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={ handleSaveData }
          >
            Save
          </Button>
        </div>
      )}
      {!isEditMode
      && (
      <>
        <changeStartDate.Component { ...changeStartDate.props } metric={ metric } />
        <ActionsDropdown className={ styles['ml-auto'] }>
          <ActionsDropdown.Action
            icon={ <MetircsChangeStartDateIcon fontSize={ 20 } /> }
            title="Change Start Date"
            onClick={ changeStartDate.open }
          />
          {collection.records && collection.records.length > 0 && (
            <ActionsDropdown.Action
              icon={ <EditIcon fontSize={ 20 } /> }
              title="Edit"
              onClick={ handleEditClick }
            />
          )}
        </ActionsDropdown>
      </>
      )}
    </>
  );
};

export default React.memo(MetricsServiceHeader);
