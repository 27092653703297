/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react';

import toastr from '@lib/toastr';
import { useUpdateTransactionServiceDocument } from '@src/hooks/queries/transaction_service_documents';
import { useCreateDocumentNote, useDeleteDocumentNote } from '@src/requests/all_transactions';
import { BusinessChartOfAccounts } from '@src/requests/system_categories';
import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ChartOfAccountField from '@src/components/common_v2/form_fields/business_chart_of_account_field';

import DocytAiLearnToggle from './docyt_ai_learn_toggle';
import NoDocumentNeeded from './no_document_needed';
import NoPushQbo from './no_push_qbo';
import TransactionTypeSelector from './transaction_type_selector';

import styles from '../../styles.module.scss';

interface IPayrollChargesProps {
  businessId: TID;
  rowData: ITransactionServiceDocument;
  Verifiy: (boolean: boolean) => void;
  setRowData: (data: ITransactionServiceDocument) => void;
  systemCategories?: BusinessChartOfAccounts;
}

const PayrollCharges = ({ businessId, rowData, Verifiy, setRowData, systemCategories }: IPayrollChargesProps) => {
  const [state, setState] = useState({
    noDocumentNeeded: rowData.noDocumentRequired || false,
    doNotLearn:       rowData.doNotLearn,
    noPushQbo:        rowData.noPushQbo,
    documentNote:     rowData.documentNote?.note || '',
    isSubmitting:     false,
  });

  const category = systemCategories?.payrollBusinessChartOfAccount?.chartOfAccountId || null;
  const isDisabled = rowData.state === 'verified';

  const { mutateAsync: deleteDocumentNote } = useDeleteDocumentNote();
  const { mutateAsync } = useUpdateTransactionServiceDocument();
  const { mutateAsync: createDocumentNote } = useCreateDocumentNote();

  const handleValueChange = useCallback(async (key: string, value: string | boolean) => {
    try {
      setState((prevState) => ({ ...prevState, isSubmitting: true }));
      const params = { id: rowData.id, [key]: value };
      const data = await mutateAsync(params);
      setRowData(data.transactionServiceDocument);
    } catch (error) {
      toastr.error(`Failed to update: ${(error as Error)?.message || 'An unknown error occurred'}`, 'Error');
    } finally {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  }, [mutateAsync, rowData.id, setRowData]);

  useEffect(() => {
    let disabled = true;
    if (((state.noDocumentNeeded && !state.documentNote) || !state.noDocumentNeeded) && category !== null) {
      disabled = false;
    }
    Verifiy(disabled);
  }, [state.noDocumentNeeded, state.documentNote, Verifiy, category]);

  const handleChartOfAccountChange = useCallback(async (value: TID | null): Promise<void> => {
    try {
      const params = { id: rowData.id, payrollChartOfAccountId: value, chartOfAccountId: null, userChartOfAccountId: null };
      await mutateAsync(params);
    } catch (error) {
      toastr.error(`Failed to update: ${(error as Error)?.message || 'An unknown error occurred'}`, 'Error');
    }
  }, [mutateAsync, rowData.id]);

  const handleDocumentNoteChange = useCallback(async (note: string) => {
    try {
      setState((prevState) => ({ ...prevState, isSubmitting: true }));
      const res = await createDocumentNote({ documentId: rowData.documentId, note });
      setRowData({ ...rowData, documentNote: res.documentNote });
    } catch (error) {
      toastr.error(`Failed to update: ${(error as Error)?.message || 'An unknown error occurred'}`, 'Error');
    } finally {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  }, [createDocumentNote, rowData, setRowData]);

  const handleNdnChange = useCallback(async (value: boolean) => {
    if (!value && rowData.documentNote && 'id' in rowData.documentNote) {
      await deleteDocumentNote({
        documentId: rowData.documentId,
        noteId:     rowData.documentNote.id,
      });
    }
    await handleValueChange('noDocumentRequired', value);
  }, [deleteDocumentNote, handleValueChange, rowData.documentNote, rowData.documentId]);

  return (
    <div key={ rowData.id }>
      <div className={ styles['sidebar-type-config'] }>
        <TransactionTypeSelector rowData={ rowData } setRowData={ setRowData } />

        <div className={ styles['sidebar-data'] }>
          <span>Category*</span>
          <ChartOfAccountField
            businessId={ businessId }
            className={ styles['sidebar-data-category'] }
            isDisabled={ isDisabled }
            label="Chart of Account"
            layout="table"
            placeholder="Select Chart of Account"
            queryParams={ {
              searchClassification: ['Asset', 'Liability'],
            } }
            value={ category }
            onChange={ handleChartOfAccountChange }
          />
        </div>

        <DocytAiLearnToggle
          checked={ !state.doNotLearn }
          isDisabled={ isDisabled }
          onChange={ (checked) => handleValueChange('doNotLearn', !checked) }
        />
      </div>

      <div className={ styles['sidebar-type-config'] }>
        <NoDocumentNeeded
          documentNote={ state.documentNote }
          isDisabled={ isDisabled }
          noDocumentNeeded={ state.noDocumentNeeded }
          rowData={ rowData }
          onDocumentNoteChange={ handleDocumentNoteChange }
          onNdnChange={ handleNdnChange }
        />
        <NoPushQbo
          isDisabled={ isDisabled }
          noPushQbo={ state.noPushQbo }
          onChange={ (checked) => handleValueChange('noPushQbo', checked) }
        />
      </div>
    </div>
  );
};

export default PayrollCharges;
