import { TID } from '@src/types/common';
import { camelizeKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

type Nullable<T> = T | null;

interface BusinessChartOfAccount {
    id: number;
    chartOfAccountId: number;
    accountsPayableServiceId: Nullable<number>;
    qboId: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: Nullable<string>;
    businessId: number;
    qboError: Nullable<string>;
    syncStatus: string;
    qboName: string;
    number: Nullable<number>;
    name: Nullable<string>;
}

interface BusinessChartOfAccounts {
    revenueReceivablesBusinessChartOfAccount: BusinessChartOfAccount;
    payrollBusinessChartOfAccount: BusinessChartOfAccount;
    employeeReimbursementsBusinessChartOfAccount: BusinessChartOfAccount;
}

const getSystemCategories = async (businessId: TID): Promise<BusinessChartOfAccounts> => {
  const data = await apiGet(`/api/v1/businesses/${businessId}/system_categories`);
  /* eslint-disable camelcase */
  const { business_system_categories } = data;
  return camelizeKeys(business_system_categories) as BusinessChartOfAccounts;
  /* eslint-enable camelcase */
};

export {
  getSystemCategories,
  BusinessChartOfAccounts,
};
