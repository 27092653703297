import React from 'react';

import { useInboxServiceContext } from '@src/hooks/contexts/inbox_service_context';
import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { documentRequestsPath, flaggedTransactionsPath } from '@src/routes';

import ServiceMenu from '@src/components/common/service_menu';
import ServiceSidebar from '@src/components/ui_v2/service_sidebar';

interface IProps {
  businessId: number
}

const OpenItemsSidebarMenuItems = ({ businessId }: IProps) => {
  const service = useInboxServiceContext();
  const { data: info } = useGetBusinessServicesInfo(businessId);
  const { data: sunsetClientCentralEnabled } = useLDUserFeatureQuery(window.Docyt.currentAdvisor.id, window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG);

  return (
    <ServiceSidebar
      serviceMenu={ (
        <ServiceMenu
          service={ service }
          serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.OPEN_ITEMS }
        />
      ) }
    >
      {(info?.isAccountingFirmUser || (info?.canAccessTaskAdvisor && info?.documentRequestServiceRole)) && (
        <ServiceSidebar.Item
          href={ documentRequestsPath(businessId) }
          isHidden={ !sunsetClientCentralEnabled }
          title="Document Requests"
        />
      )}
      {(!!info?.isBusinessMember && info?.hasFlaggedTransactionsAdvisorRole) && (
        <ServiceSidebar.Item
          href={ flaggedTransactionsPath(businessId) }
          title="Flagged Transactions"
        />
      )}
    </ServiceSidebar>
  );
};

export default OpenItemsSidebarMenuItems;
