import React, { memo } from 'react';

import {
  ICommonTooltipProps,
  IListItem,
} from '@src/components/operational_dashboard/types';
import { AppLink } from '@src/components/ui_v2/buttons';

import { TooltipItem } from './tooltip_item';
import TooltipWrapper from './wrapper';

import styles from './style.module.scss';

interface IMissingRevenueTooltipProps extends ICommonTooltipProps {
  item: IListItem;
}
const MissingRevenueTooltip: React.FC<IMissingRevenueTooltipProps> = ({
  title,
  item,
  cellData,
}) => {
  return (
    <TooltipWrapper title={ title }>
      <TooltipItem key={ item.id }>
        <div className={ styles['tooltip-card-item-main'] }>
          <span className={ styles['tooltip-card-heading'] }>
            {item.key
                && item.heading.replace(':value', cellData?.[item.key] ?? 0)}
          </span>
          {item.actionLink && item.actionLabel && (
          <AppLink
            className={ styles['tooltip-card-action-link'] }
            href={ item.actionLink() }
          >
            {item.actionLabel}
          </AppLink>
          )}
        </div>
      </TooltipItem>
    </TooltipWrapper>
  );
};
export default memo(MissingRevenueTooltip);
