import React, { useState, useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { TID } from '@src/types/common';
import { IExternalSystemPullProperties } from '@src/types/integrations/integrations';

import Modal from '@src/components/ui/modal';
import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import SearchInput from '@src/components/ui_v2/search_dropdown/search_input';

import { useGetIntegrationExternalSystemData } from './hooks';
import { usePullIntegrationConnectModal } from '../pull_integration_connect/pull_integration_connect_modal';
import { usePushIntegrationConnectModel } from '../push_integration_connect/push_integration_connect_model';

import styles from './styles.module.scss';

interface IConnectRevenueSystemModalProps extends IUseModalProps {}
const ConnectRevenueSystemModal = ({
  isOpen,
  onDone,
}: IConnectRevenueSystemModalProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const externalSystemList = useGetIntegrationExternalSystemData();
  const pushIntegrationConnectModel = usePushIntegrationConnectModel();
  const pullIntegrationConnectModel = usePullIntegrationConnectModal();
  const [externalSystemId, setExternalSystemId] = useState<TID | null>(null);
  const [externalSystemName, setExternalSystemName] = useState<string | null>(null);
  const [externalSystemIntegrationType, setexternalSystemIntegrationType] = useState<string | null>(null);
  const [externalSystemConfigSchema, setExternalSystemConfigSchema] = useState<
  IExternalSystemPullProperties | null
  >(null);

  const filteredSystems = externalSystemList.filter((
    sys,
  ) => sys.label.toLowerCase().includes(searchValue.toLowerCase()));

  const connectPushSystem = useCallback((
    systemId: TID,
    systemName: string,
    integrationType: string,
    configSchema?:IExternalSystemPullProperties,
  ) => {
    setExternalSystemId(systemId);
    setExternalSystemName(systemName);
    setexternalSystemIntegrationType(integrationType);
    if (configSchema) {
      setExternalSystemConfigSchema(configSchema);
    }

    if (integrationType === 'email') {
      pushIntegrationConnectModel.open();
      onDone();
    } else if (integrationType === 'pull') {
      pullIntegrationConnectModel.open();
      onDone();
    }
  }, [pushIntegrationConnectModel, pullIntegrationConnectModel, onDone]);

  return (
    <>
      <Modal
        className={ styles['modal-template-excel'] }
        show={ isOpen }
        title="Connect Revenue System"
      >
        <Modal.Body>
          <Text fontSize={ 14 } mb={ 8 } mt={ 16 }>
            Select a revenue system or setup manually to continue.
          </Text>
          <SearchInput
            hideClear
            aria-label="Search revenue systems"
            placeholder="Search Revenue System"
            value={ searchValue }
            width="100%"
            onChange={ setSearchValue }
          />
          <div className={ styles['revenue-system-list'] }>
            {filteredSystems.map((system) => (
              <div key={ system.id } className={ styles['revenue-system-item'] }>
                <div className={ styles['revenue-system-name-wrapper'] }>
                  <div className={ styles['revenue-system-indicator'] } />
                  <span className={ styles['revenue-system-name'] }>{system.label}</span>
                </div>
                <span className={ styles['revenue-system-status'] }>
                  {system.connected}
                  {' '}
                  Connected
                </span>
                <Button
                  className={ styles['connect-button'] }
                  variant="link"
                  onClick={ () => connectPushSystem(
                    system.id,
                    system.label,
                    system.integrationType,
                    system.configSchema,
                  ) }
                >
                  Connect
                </Button>
              </div>
            ))}
          </div>
          <div className={ styles['setup-wrapper'] }>
            <div className={ styles['separator-text'] }>
              <span>Couldn&#39;t find your revenue system?</span>
            </div>
            <Button variant="link"> Setup Manually</Button>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <div className={ styles['cancel-button'] }>
            <Button variant="link" onClick={ onDone }>
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {externalSystemId && externalSystemName && externalSystemIntegrationType && (
        <>
          <pushIntegrationConnectModel.Component
            externalSystemId={ externalSystemId }
            externalSystemName={ externalSystemName }
            { ...pushIntegrationConnectModel.props }
          />
          {externalSystemConfigSchema && (
            <pullIntegrationConnectModel.Component
              externalSystemConfigSchema={ externalSystemConfigSchema }
              externalSystemId={ externalSystemId }
              externalSystemName={ externalSystemName }
              { ...pullIntegrationConnectModel.props }
            />
          )}
        </>
      )}
    </>
  );
};

const useConnectRevenueSystemModal = makeUseModal(ConnectRevenueSystemModal);

export { useConnectRevenueSystemModal, ConnectRevenueSystemModal as default };
