import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useUpdateBusinessBookkeepingStatus } from '@src/hooks/queries/business_bookkeeping_statuses';
import { TBookkeepingStatus, IBusinessBookkeepingStatus } from '@src/types/business_bookkeeping_statuses';
import { formatDate } from '@src/utils/date_helpers';

import MutationStatus from '@src/components/utils/mutation_status';

import CompleteButton from './complete';
import ReopenButton from './reopen';

import styles from './styles.module.scss';

interface CompleteActionProps {
  bookkeepingStatus: IBusinessBookkeepingStatus,
}

const CompleteAction = ({ bookkeepingStatus }: CompleteActionProps) => {
  const business = useBusinessContext();
  const updateBusinessBookkeepingStatus = useUpdateBusinessBookkeepingStatus();
  const { mutate } = updateBusinessBookkeepingStatus;
  const { year, month } = bookkeepingStatus;

  const handleDone = useCallback((status: TBookkeepingStatus) => {
    mutate({
      businessId:                business.id,
      businessBookkeepingStatus: { status, year, month },
    }, {
      onSuccess: (data) => {
        window.Docyt.vent.trigger('month_end:bookkeeping_status:changed', data);
      },
    });
  }, [
    business.id,
    year,
    month,
    mutate,
  ]);

  if (bookkeepingStatus.status === 'reconciled') {
    let completedText = null;
    if (bookkeepingStatus.completedByUser && bookkeepingStatus.completedOn) {
      completedText = (
        <>
          { ' ' }
          by
          { ' ' }
          { bookkeepingStatus.completedByUser.parsedFullname }
          { ' ' }
          on
          { ' ' }
          { formatDate(bookkeepingStatus.completedOn, 'MMM D, YYYY') }
        </>
      );
    }

    return (
      <div className={ styles['reopen-action'] }>
        <span>
          Marked as completed
          { completedText }
          .
        </span>
        { bookkeepingStatus.canReopen && (
        <>
          <MutationStatus mutation={ updateBusinessBookkeepingStatus } />
          <ReopenButton onDone={ handleDone } />
        </>
        ) }
      </div>
    );
  }

  return (
    <div className={ styles['complete-action'] }>
      <span>
        All bank accounts and credit cards for this month have been reconciled.
        You can now mark this month as complete.
      </span>
      <MutationStatus mutation={ updateBusinessBookkeepingStatus } />
      <CompleteButton onDone={ handleDone } />
    </div>
  );
};

export default CompleteAction;
