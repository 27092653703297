import React, { memo } from 'react';

import { ICommonTooltipProps } from '@src/components/operational_dashboard/types';
import { AppLink } from '@src/components/ui_v2/buttons';
import ImgIcon from '@src/components/utils/img_icons/img_icon';

import { TooltipItem } from './tooltip_item';
import TooltipWrapper from './wrapper';

import styles from './style.module.scss';

const FlaggedItemsTooltip: React.FC<ICommonTooltipProps> = ({
  title,
  items,
  cellData,
}) => {
  return (
    <TooltipWrapper title={ title }>
      {items?.map((item) => (
        <TooltipItem key={ item.id }>
          <div className={ styles['tooltip-card-item-main'] }>
            <span>
              <ImgIcon
                alt={ item.icon }
                className={ styles.icon }
                src={ item.icon }
              />
            </span>
            <span className={ styles['tooltip-card-heading'] }>
              {item.key && (cellData?.[item?.key] ?? 0)}
              {' '}
              {item.heading}
            </span>
            {item.actionLink && item.actionLabel && (
            <AppLink
              className={ styles['tooltip-card-action-link'] }
              href={ item.actionLink() }
            >
              {item.actionLabel}
            </AppLink>
            )}
          </div>
        </TooltipItem>
      ))}
    </TooltipWrapper>
  );
};
export default memo(FlaggedItemsTooltip);
