import React, { useMemo } from 'react';

import { IPopover } from '@src/hooks/popover';

import { useDropdown } from './hooks';

interface IDropdownToggleButtonHoverableProps extends React.ComponentPropsWithoutRef<'button'> {
  onClick?: () => void;
}

const DropdownToggleButtonHoverable = ({
  children,
  className,
  onClick,
  ...props
}: IDropdownToggleButtonHoverableProps) => {
  const { 
    showOnHoverAttributes,
    showOnClickAttributes
  } = (useDropdown() || {}) as IPopover;

  const handleClick = useMemo(() => ({
    onClick: (e: React.MouseEvent) => {
      onClick?.();
      showOnClickAttributes.onClick?.(e);
    }
  }), [onClick, showOnClickAttributes]);

  const eventHandlers = useMemo(() => ({
    ...handleClick,
    ...showOnHoverAttributes
  }), [handleClick, showOnHoverAttributes]);

  return (
    <button
      className={ className }
      type="button"
      { ...eventHandlers }
      { ...props }
    >
      { children }
    </button>
  );
};

export default React.memo(DropdownToggleButtonHoverable);
