import { atom } from 'recoil';

import { Family } from '@src/constants/atom_keys';
import { TDateFilter } from '@src/types/filter';
import { TReportDataPeriodType } from '@src/types/report_service/report';

const reportUpdatingState = atom<string | null>({
  key:     Family.ReportUpdatingState,
  default: null,
});

const reportDataPeriodType = atom<TReportDataPeriodType>({
  key:     Family.ReportPeriodType,
  default: undefined,
});

const pickMultiMonths = atom<boolean>({
  key:     Family.PickMultiMonths,
  default: false,
});

const pickMultiDaily = atom<boolean>({
  key:     Family.PickMultiDaily,
  default: false,
});

const reportFilePreparing = atom<boolean>({
  key:     Family.ReportFilePreparing,
  default: false,
});

const reportFilterDateRange = atom<TDateFilter>({
  key:     Family.ReportFilterDateRange,
  default: undefined,
});

export const reportsExportValueType = atom<string[]>({
  key:     Family.ReportsExportValue,
  default: [],
});

const reportRefreshState = atom<boolean>({
  key:     Family.ReportRefreshState,
  default: false,
});

const reportRefreshJobId = atom<string | null>({
  key:     Family.ReportRefreshJobId,
  default: null,
});

export {
  reportUpdatingState,
  reportDataPeriodType,
  pickMultiMonths,
  pickMultiDaily,
  reportFilePreparing,
  reportFilterDateRange,
  reportRefreshState,
  reportRefreshJobId,
};
