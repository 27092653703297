import React, { useCallback, useState } from 'react';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpsertIntegration } from '@src/hooks/queries/integrations/integrations';
import { IUpsertIntegrationResponse } from '@src/requests/integrations/integrations';
import { TID } from '@src/types/common';
import { IExternalSystemPullProperties } from '@src/types/integrations/integrations';

import JsonForm from '@src/components/integrations/config/form';
import Modal from '@src/components/ui/modal';
import Button from '@src/components/ui_v2/buttons/button';
import Form from '@src/components/ui_v2/form';

import PullIntegrationOauthAuthorization from './pull_integration_oauth_authorization';
import { usePullIntegrationSuccessModal } from './pull_integration_success_modal';
import { useConnectRevenueSystemModal } from '../connect_system/connect_system_modal';

interface IPullIntegrationConnectModalProps extends IUseModalProps {
  externalSystemId: TID,
  externalSystemName: string,
  externalSystemConfigSchema: IExternalSystemPullProperties,
  isEdit?: boolean,
  valueForm?: { [key: string]: string | number | null };
}

const PullIntegrationConnectModal = ({
  isOpen,
  onDone,
  onCancel,
  externalSystemId,
  externalSystemName,
  externalSystemConfigSchema,
  isEdit = false,
  valueForm,
  ...props
}: IPullIntegrationConnectModalProps) => {
  const [integration, setIntegration] = useState<IUpsertIntegrationResponse | null>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const pullIntegrationSuccessModel = usePullIntegrationSuccessModal();
  const [integrationId, setIntegrationId] = useState<TID | null>(null);
  const business = useBusinessContext();

  const handleOauthSuccess = useCallback(() => {
    pullIntegrationSuccessModel.open();
    onDone();
  }, [pullIntegrationSuccessModel, onDone]);

  const upsert = useUpsertIntegration();
  const { mutate } = upsert;

  const handleError = useCallback((response) => {
    toastr.error(response?.response?.data?.errors[0], 'Error');
  }, []);

  const connectSystemModal = useConnectRevenueSystemModal();

  const connectPullSystem = useCallback((data: any) => {
    const externalSystemConfig: { [key: string]: string } = {};
    const otherData: { [key: string]: string } = {};

    externalSystemConfigSchema.required.forEach((key) => {
      if (data[key]) {
        externalSystemConfig[key] = data[key];
      }
    });

    Object.keys(data).forEach((key) => {
      if (!externalSystemConfigSchema.required.includes(key)) {
        otherData[key] = data[key];
      }
    });

    const integrationData = {
      externalSystemId,
      ...otherData,
      externalSystemConfig,
    };

    mutate({
      businessId:  business.id,
      integration: integrationData,
      actionType:  isEdit ? 'update' : 'create',
    }, {
      onSuccess: (response) => {
        setIntegration(response);
        if (externalSystemConfigSchema.authorizationRequired
          || externalSystemConfigSchema.authorizationType === 'oauth') {
          setIntegrationId(response.id);
        } else {
          pullIntegrationSuccessModel.open();
          onDone();
        }
      },
      onError: handleError,
    });
  }, [externalSystemId,
    handleError,
    business.id,
    mutate,
    pullIntegrationSuccessModel,
    onDone,
    externalSystemConfigSchema.authorizationRequired,
    externalSystemConfigSchema.authorizationType,
    externalSystemConfigSchema.required,
    isEdit,
  ]);

  const handleOnCancel = useCallback(() => {
    if (!isEdit) {
      connectSystemModal.open();
    }
    onCancel();
  }, [connectSystemModal, onCancel, isEdit]);

  return (
    <>
      {
        (externalSystemConfigSchema.authorizationRequired
          || externalSystemConfigSchema.authorizationType === 'oauth')
        && integrationId && (
          <PullIntegrationOauthAuthorization
            integrationId={ integrationId }
            onSuccess={ handleOauthSuccess }
          />
        )
      }
      <connectSystemModal.Component
        { ...connectSystemModal.props }
      />
      <pullIntegrationSuccessModel.Component
        UpsertIntegrationResponse={ integration }
        { ...pullIntegrationSuccessModel.props }
      />
      <Modal
        show={ isOpen }
        title={ `${externalSystemName} Revenue System` }
        { ...props }
      >
        <>
          <Modal.Body>
            <p>Select the business the revenue system.</p>
            <Form
              id="linked_businesses_form"
              onSubmit={ () => { } }
            >
              <Form.TextField
                readOnly
                label="Business"
                value={ business.name }
              />
            </Form>
            <p>This revenue system requires credential details. Enter below the credential details to connect.</p>
            <JsonForm
              businessId={ business.id }
              formId="edit_linked_businesses_form"
              jsonSchema={ externalSystemConfigSchema.properties }
              valueForm={ isEdit ? valueForm : {} }
              onSubmit={ connectPullSystem }
              onValidationChange={ setIsFormValid }
            />
          </Modal.Body>
          <Modal.Footer className="modal-footer-v2">
            <Button
              className="btn-outline"
              variant="link"
              onClick={ handleOnCancel }
            >
              Back
            </Button>
            <Button
              disabled={ !isFormValid }
              form="edit_linked_businesses_form"
              type="submit"
              variant="primary"
            >
              Connect
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

const usePullIntegrationConnectModal = makeUseModal(PullIntegrationConnectModal);

export {
  usePullIntegrationConnectModal,
  PullIntegrationConnectModal as default,
};
