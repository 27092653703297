import React, { useCallback } from 'react';

import classNames from 'classnames';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IWidget } from '@src/types/dashboards';
import { uiStyleClassNames } from '@src/utils/ui_style_helpers';

import Table from '@src/components/ui_v2/table';

import { CriticalNumberTabularView } from '../critical_number';

interface ITableRowProps {
  item: IWidget[];
}

const TableRow = ({
  item,
}: ITableRowProps): JSX.Element => {
  const business = useBusinessContext();

  const lineItemCellClasses = uiStyleClassNames(
    'line-item-cell',
    {
      fontVariant: undefined,
    },
  );

  const classesActiveColumns = useCallback(() => {
    return classNames('value-cell', true);
  }, []);

  return (
    <Table.Row isClickable className="table-row">
      <Table.TextCell className={ lineItemCellClasses } tooltip={ business.displayName }>
        { business.displayName }
      </Table.TextCell>

      {
        item.map((column: any) => (
          <Table.TextCell key={ column.id } className={ classesActiveColumns() }>
            <CriticalNumberTabularView criticalNumber={ column.criticalNumber } unit={ column.dataSets?.[0]?.unit } />
          </Table.TextCell>
        ))
      }
    </Table.Row>
  );
};

export default TableRow;
