import React, { memo } from 'react';

import { ICommonTooltipProps } from '@src/components/operational_dashboard/types';
import { AppLink } from '@src/components/ui_v2/buttons';

import { TooltipItem } from './tooltip_item';
import TooltipWrapper from './wrapper';

import styles from './style.module.scss';

const ExpenseReportTooltip: React.FC<ICommonTooltipProps> = ({
  title,
  items,
  cellData,
}) => {
  return (
    <TooltipWrapper title={ title }>
      {items?.map((item) => (
        <TooltipItem key={ item.id }>
          <div className={ styles['tooltip-card-item-main'] }>
            <span
              className={ styles['expense-tooltip-iconbox'] }
              style={ { backgroundColor: item?.bgColor } }
            >
              {item?.key ? cellData?.[item?.key] ?? 0 : 0}
            </span>
            <span className={ styles['tooltip-card-heading'] }>
              {item.heading}
            </span>
            {item.actionLink && item.actionLabel && (
            <AppLink
              className={ styles['tooltip-card-action-link'] }
              href={ item.actionLink() }
            >
              {item.actionLabel}
            </AppLink>
            )}
          </div>
        </TooltipItem>
      ))}
    </TooltipWrapper>
  );
};
export default memo(ExpenseReportTooltip);
