import React, { useCallback, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';

import toastr from '@lib/toastr';
import { useUploadBankStatementAttachment } from '@src/hooks/bank_statements';
import { useMoveDocumentsToTrash } from '@src/hooks/queries/documents';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IDocument } from '@src/types/documents';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import { Button } from '@src/components/ui/buttons';

import styles from './document_list.module.scss';

interface IDocumentModel {
  id: TID;
  business_id: TID;
  bank_statement: IBankStatement;
  isBankStatementRequest: () => boolean;
  isBalanceSheetStatementRequest: () => boolean;
  get: (key: string) => string | number | null;
}

interface DocumentUploaderProps {
  model: IDocumentModel;
  isUploading: boolean;
  setIsUploading: (value: boolean) => void;
  documents: IDocument[];
  setDocuments?: (documents: IDocument[]) => void;
  noStatementAvailable: boolean;
  setNoStatementAvailable: (value: boolean) => void;
  onDocumentsUpdate?: (docs: IDocument[]) => void;
  bankStatement: IBankStatement;
}

const DocumentUploader: React.FC<DocumentUploaderProps> = ({
  model,
  isUploading,
  setIsUploading,
  documents,
  bankStatement,
  noStatementAvailable,
  setNoStatementAvailable,
  onDocumentsUpdate,
}) => {
  const [selectedDocId, setSelectedDocId] = useState<TID>(0);
  const uploadAttachment = useUploadBankStatementAttachment();
  const moveToTrash = useMoveDocumentsToTrash();

  const validateBankStatementFileFormat = useCallback((file: File) => {
    const allowedSize = window.configData.allowed_file_upload_size;
    const allowedFormats = ['pdf'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (file.size > allowedSize) {
      toastr.error(`File size should be less than 
        ${window.configData.allowed_file_upload_size}`, 'Something went wrong');
      return false;
    }

    if (!fileExtension || !allowedFormats.includes(fileExtension)) {
      toastr.error(
        'File you uploaded is not supported. You can only upload in one of these formats: pdf',
        'Something went wrong',
      );
      return false;
    }

    return true;
  }, []);

  const uploadBankStatement = useCallback(async (files: File[]): Promise<IBankStatement[]> => {
    setIsUploading(true);
    try {
      const uploadPromises = files.map(async (file) => {
        if (!validateBankStatementFileFormat(file)) {
          return null;
        }

        try {
          const response = await uploadAttachment.mutateAsync({
            businessId:      model.get('business_id') as number,
            bankStatementId: bankStatement.id,
            file,
          });

          if (response.bankStatement) {
            const newDocument: IDocument = {
              id:                        response.bankStatement.id,
              name:                      response.bankStatement.name || '',
              docytId:                   response.bankStatement.docytId || '',
              chatId:                    0,
              consumerId:                0,
              current:                   true,
              businessDocuments:         [],
              businessNames:             [],
              businesses:                [],
              createdAt:                 new Date().toISOString(),
              documentOwners:            [],
              source:                    '',
              state:                     'uploaded',
              storageSize:               0,
              unencryptedDocumentFields: [],
              updatedAt:                 new Date().toISOString(),
              uploaderEmail:             '',
              uploaderName:              '',
              dueOff:                    false,
              expiryOff:                 false,
              haveAccess:                true,
              isFrozen:                  false,
              pages:                     [],
              sharers:                   [],
              type:                      '',
              lastModifiedAt:            new Date().toISOString(),
            };
            onDocumentsUpdate?.([...documents, newDocument]);
          }

          return response.bankStatement;
        } catch {
          toastr.error('Failed to upload file', 'Error');
          return null;
        }
      });

      const results = await Promise.all(uploadPromises);
      const successfulUploads = results.filter((r): r is IBankStatement => r !== null);

      if (successfulUploads.length > 0) {
        toastr.success('Files uploaded successfully', 'Success');
      }

      return successfulUploads;
    } catch {
      toastr.error('Upload failed', 'Error');
      return [];
    } finally {
      setIsUploading(false);
    }
  }, [model, bankStatement, documents, onDocumentsUpdate, setIsUploading,
    uploadAttachment, validateBankStatementFileFormat]);

  const handleDelete = useCallback((docId: TID) => {
    moveToTrash.mutate({
      value:       true,
      documentIds: [docId],
    }, {
      onSuccess: () => {
        const updatedDocuments = documents.filter((doc) => doc.id !== docId);
        onDocumentsUpdate?.(updatedDocuments);
        toastr.success('Document deleted successfully', 'Success');
      },
      onError: (error) => {
        toastr.error(error.message || 'Failed to delete document', 'Error');
      },
    });
  }, [documents, onDocumentsUpdate, moveToTrash]);

  const deleteModal = useConfirmDeleteModal({
    onDone: () => handleDelete(selectedDocId),
  });

  const isMailroomRequest = model.get('request_type') === window.Docyt.Common.Constants.SERVICE_NAMES.MAILROOM;
  const isBankStatementRequest = model.isBankStatementRequest();
  const isBalanceSheetRequest = model.isBalanceSheetStatementRequest();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      if (isUploading) return;

      // For non-mailroom requests, only allow one file
      if (!isMailroomRequest && acceptedFiles.length > 1) {
        toastr.error('Only one file can be uploaded at a time', 'Error');
        return;
      }

      // For bank statements and balance sheets, check if document exists
      if ((isBankStatementRequest || isBalanceSheetRequest) && documents.length > 0) {
        // TODO: Replace window.confirm with a proper modal dialog component
        // For now, just allow replacing without confirmation
        return;
      }

      // For mailroom, allow multiple files
      // For others (bank statement, balance sheet), only use the first file
      const filesToUpload = isMailroomRequest ? acceptedFiles : [acceptedFiles[0]];

      try {
        setIsUploading(true);
        const uploadedDocs = await uploadBankStatement(filesToUpload);
        if (uploadedDocs.length > 0) {
          window.Docyt.vent.trigger('transactions:request:done');
        }
      } catch {
        toastr.error('Upload failed', 'Error');
      } finally {
        setIsUploading(false);
      }
    },
    multiple: isMailroomRequest, // Only allow multiple files for mailroom
    accept:   '.pdf,application/pdf',
  });

  const renderDocumentTable = useCallback(() => {
    return (
      <div className={ styles['documents-table'] }>
        <table aria-label="Documents">
          <thead>
            <tr>
              <th scope="col">Document ID</th>
              <th scope="col">Name</th>
              <th scope="col">Amount</th>
              <th scope="col"><span className="sr-only">Actions</span></th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc) => (
              <tr key={ doc.id }>
                <td className={ styles['document-id'] }>
                  <Link
                    className={ styles['document-link'] }
                    to={ `/document/${doc.id}` }
                  >
                    {doc.docytId}
                  </Link>
                </td>
                <td>{doc.name}</td>
                <td>-</td>
                <td>
                  <Button
                    aria-label={ `Delete document ${doc.name}` }
                    className={ styles['delete-button'] }
                    onClick={ () => {
                      setSelectedDocId(doc.id);
                      deleteModal.open();
                    } }
                  >
                    <i aria-hidden="true" className="fa fa-trash" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <deleteModal.Component
          confirmStyle="primary"
          confirmTitle="Delete"
          text="Are you sure you want to delete this document?"
          title="Delete Document"
          { ...deleteModal.props }
        />
      </div>
    );
  }, [documents, deleteModal]);

  const renderDropZone = useCallback(() => (
    <div
      { ...getRootProps() }
      className={ `${styles['document-drop-zone']} ${isDragActive ? styles.active : ''}` }
    >
      <input { ...getInputProps() } />
      <div className={ styles['drop-zone-content'] }>
        <span className={ styles['add-document-text'] }>Add Document</span>
        <span className={ styles['drag-drop-text'] }>
          {isUploading && 'Uploading...'}
          {!isUploading && isMailroomRequest && 'Drag and drop your files here'}
          {!isUploading && !isMailroomRequest && 'Drag and drop your file here'}
        </span>
      </div>
    </div>
  ), [getRootProps, getInputProps, isDragActive, isUploading, isMailroomRequest]);

  return (
    <div>
      {documents?.length > 0 && renderDocumentTable()}
      {renderDropZone()}
      <div className={ styles['no-statement-checkbox'] }>
        <label className={ styles['no-statement-checkbox-label'] }>
          <input
            checked={ noStatementAvailable }
            type="checkbox"
            onChange={ (e) => setNoStatementAvailable(e.target.checked) }
          />
          <span>No Statement Available</span>
        </label>
      </div>
    </div>
  );
};

export default DocumentUploader;
