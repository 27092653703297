import React from 'react';

import TogglePortfolioViewAction from '../../actions/toggle_view_action';

import styles from './styles.module.scss';

interface IDashboardDetailsHeaderProps {
  showToggleView: boolean,
}

const DashboardDetailsHeader = ({
  showToggleView,
}: IDashboardDetailsHeaderProps): JSX.Element => {
  return (
    <div className={ styles['details-header'] }>
      <div className={ styles['right-wrapper'] } />
      <div className={ styles['left-wrapper'] }>
        { showToggleView && <TogglePortfolioViewAction /> }
      </div>
    </div>
  );
};

export default DashboardDetailsHeader;
