export const undepositedRevenueTooltipData = [
  {
    id:        '10001',
    iconColor: '#B066FF',
    heading:   'Credit card',
    amount:    800,
    subItems:  [
      { id: '10001-1', heading: 'Amex', amount: 520 },
      { id: '10001-2', heading: 'Shift4', amount: 280 },
    ],
    actionLabel: 'Review',
    actionLink:  () => '#',
  },
  {
    id:          '10002',
    iconColor:   '#FFC266',
    heading:     'Cash and Check',
    amount:      210,
    actionLabel: 'Review',
    actionLink:  () => '#',
  },
];

export const unreadMessagesTooltipData = {
  id:          '9001',
  heading:     'Unread Messages',
  actionLabel: 'Review',
  actionLink:  () => '#',
  key:         'unreadMessageCount',
};

export const expenseReportTooltipData = [
  {
    id:          '1001',
    bgColor:     '#9143FF',
    heading:     'Uploaded Invoices',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'uploadedInvoices',
  },
  {
    id:          '1002',
    bgColor:     '#43BAFE',
    heading:     'Approved Invoices',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'approvedInvoices',
  },
  {
    id:          '1003',
    bgColor:     '#01B787',
    heading:     'Ready to Pay Invoices',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'readyToPayInvoices',
  },
];

export const corporateCardTooltipData = {
  id:          '2001',
  heading:     'Unapproved Receipts',
  actionLabel: 'Review',
  actionLink:  () => '#',
  key:         'unapprovedReceipts',
};

export const employeeReimbursementsTooltipData = [
  {
    id:          '3001',
    bgColor:     '#9143FF',
    heading:     'Submitted',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'submitted',
  },
  {
    id:          '3002',
    bgColor:     '#43BAFE',
    heading:     'Approved',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'approved',
  },
  {
    id:          '3003',
    bgColor:     '#01B787',
    heading:     'Reimbursed',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'reimbursed',
  },
];

export const myReimbursementsTooltipData = [
  {
    id:          '4001',
    bgColor:     '#FFAC07',
    heading:     'Uploaded',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'uploaded',
  },
  {
    id:          '4002',
    bgColor:     '#9143FF',
    heading:     'Submitted',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'submitted',
  },
  {
    id:          '4003',
    bgColor:     '#01B787',
    heading:     'Reimbursed',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'reimbursed',
  },
];

export const flaggedItemsTooltipData = [
  {
    id:          '5001',
    bgColor:     '#20b2aa',
    heading:     'Flagged Invoices',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'flaggedInvoices',
    icon:        'https://cdn-icons-png.freepik.com/512/45/45813.png',
  },
  {
    id:          '5002',
    bgColor:     '#20b2aa',
    heading:     'Flagged Receipts',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'flaggedReceipts',
    icon:        'https://cdn-icons-png.freepik.com/512/45/45813.png',
  },
  {
    id:          '5003',
    bgColor:     '#20b2aa',
    heading:     'Flagged Transactions',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'flaggedTransactions',
    icon:        'https://cdn-icons-png.freepik.com/512/45/45813.png',
  },
];

export const missingRevenueReportsTooltipData = {
  id:          '7001',
  heading:     "Revenue hasn't arrived for :value days",
  actionLabel: 'Review',
  actionLink:  () => '#',
  key:         'missingRevenueReports',
};

export const unbalancedReportsTooltipData = {
  id:          '7002',
  heading:     'Unbalanced Reports',
  actionLabel: 'Review',
  actionLink:  () => '#',
  key:         'unbalancedReports',
};

export const uncategorizedTransactionsTooltipData = {
  id:          '7003',
  heading:     'Uncategorized Transactions',
  actionLabel: 'Review',
  actionLink:  () => '#',
  key:         'uncategorizedTransactions',
};

export const unclearedDocumentsTooltipData = {
  id:          '7004',
  heading:     'Uncleared Documents',
  actionLabel: 'Review',
  actionLink:  () => '#',
  key:         'unclearedDocuments',
};

export const missingBankStatementTooltipData = {
  id:          '7005',
  heading:     'Missing Bank Statement',
  actionLabel: 'Review',
  actionLink:  () => '#',
  key:         'missingBankStatement',
};

export const closingStatusTooltipData = {
  id:               '7006',
  heading:          'Closed',
  actionLabel:      'view',
  actionLink:       () => '#',
  shortDescription: "Last 100% closing was done for Jan'24",
  key:              'percent',
};

export const documentRequestsTooltipData = [
  {
    id:          '8001',
    bgColor:     '#00B887',
    heading:     'Reviewed',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'reviewed',
  },
  {
    id:          '8002',
    bgColor:     '#FFB648',
    heading:     'Open',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'open',
  },
];
