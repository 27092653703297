import { useMemo } from 'react';

import flatten from 'lodash/flatten';
import { useForm, UseFormReturn } from 'react-hook-form';
import { UseInfiniteQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useGetDashboardTableData } from '@src/hooks/queries/operational_dashboard/operation_dashboard_service';
import { useSorting } from '@src/hooks/url_params';
import { IGetDashboardTableServiceResponse } from '@src/requests/operational_dashboard/operation_dashboard_service';
import { TSection } from '@src/types/common';
import { TDashboardTableServiceDocumentsSortColumn } from
  '@src/types/operational_dashboard/operation_dashboard_service';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { IDashboardData, TViewOperationalDashboard } from './types';

interface IFilterForm {
  search_business_by_name: string;
}

interface IDashboardTableCollection {
  query: UseInfiniteQueryResult<IGetDashboardTableServiceResponse, Error>;
  records: IDashboardData[];
  section: TSection;
  sorting: ISorting<TDashboardTableServiceDocumentsSortColumn>;
  FilterForm: UseFormReturn<IFilterForm>;
}

interface dashboardTableCollectionProps{
  view: TViewOperationalDashboard
}

const dashboardDefaultSorting: ISortingParams<TDashboardTableServiceDocumentsSortColumn> =
  {
    orderColumn:    'business',
    orderDirection: 'desc',
  };
const useDashboardTableCollection = ({ view }:dashboardTableCollectionProps): IDashboardTableCollection => {
  const FilterForm = useForm<IFilterForm>({
    defaultValues: {
      search_business_by_name: '',
    },
  });

  const { watch } = FilterForm;
  const searchBusinessByName = watch('search_business_by_name');

  const section = useMemo(() => {
    return {
      section: Section.OperationalDashboardTable,
    };
  }, []);
  const sorting = useSorting<TDashboardTableServiceDocumentsSortColumn>({
    section:        section.section,
    defaultSorting: dashboardDefaultSorting,
  });
  const query = useGetDashboardTableData({
    view,
    ...sorting.data,
    ...(searchBusinessByName ? { searchBusinessByName } : {}),

  });
  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);
  return {
    query,
    records,
    section,
    sorting,
    FilterForm,
  };
};
export { IDashboardTableCollection, useDashboardTableCollection };
