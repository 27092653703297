import React from 'react';

import CorporateCardsTooltip from '@src/components/operational_dashboard/components/tooltips/corporate_cards_tooltip';

import HoverTooltipWrapper from './hover_tooltip_wrapper';
import NumberBox from './number_box';
import { IListItem, TSetupStatus } from '../../types';

interface IReconciliationItemProps {
    tooltipData : IListItem
    value :number;
    tooltipValue?: string | number;
    setupStatus?:TSetupStatus;
}

const ReconciliationItem = ({
  tooltipData,
  value,
  tooltipValue,
  setupStatus,
}:IReconciliationItemProps) => (
  <HoverTooltipWrapper
    content={ (
      tooltipValue && (
      <CorporateCardsTooltip
        item={ tooltipData }
        value={ tooltipValue }
      />
      )
    ) }
  >
    <NumberBox setupStatus={ setupStatus } value={ { value, type: 'number' } } />
  </HoverTooltipWrapper>
);

export default ReconciliationItem;
