import React, { useCallback, useState } from 'react';

import { IBusinessSimple } from '@src/types/businesses';
import { IPortfolioDashboard } from '@src/types/portfolio';

import { TWidgetViewCategory } from '@src/components/management_group_dashboard/types';

import WidgetContainer from './widgets/widget_container';
import WidgetHeader from './widgets/widget_header';

import styles from '../styles.module.scss';

interface IDashboardDetailsContainerProps {
  dashboard: IPortfolioDashboard,
  businesses: IBusinessSimple[],
  widgetId?: string,
}

const DashboardDetailsContainer = ({
  dashboard,
  businesses,
  widgetId,
}: IDashboardDetailsContainerProps): JSX.Element => {
  const [currentView, setCurrentView] = useState<TWidgetViewCategory>('aggregate');

  const handleChange = useCallback((value) => {
    setCurrentView(value);
  }, [setCurrentView]);

  return (
    <div className={ styles['details-container'] }>
      <WidgetHeader
        currentView={ currentView }
        onChange={ handleChange }
      />
      {
        widgetId && dashboard.bookkeepingStartDate && (
          <WidgetContainer
            bookkeepingStartDate={ dashboard.bookkeepingStartDate }
            businesses={ businesses }
            currentView={ currentView }
            dashboard={ dashboard }
            widgetId={ widgetId }
          />
        )
      }
    </div>
  );
};

export default DashboardDetailsContainer;
