import React, { useMemo } from 'react';

import { IBusiness } from '@src/types/businesses';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

import Item from './list_item/item';

interface IPastTransactionsListProps {
  pastTransactions: ITransactionServiceDocument[],
  transaction: ITransactionServiceDocument,
  business: IBusiness,
}

const PastTransactionsList = ({
  pastTransactions,
  transaction,
  business,
}: IPastTransactionsListProps): JSX.Element => {
  const isAccountantMember = useMemo(() => {
    return business.isAccountantMember;
  }, [business]);

  const hasCategorizationService = useMemo(() => {
    return business.hasCategorizationServiceRole;
  }, [business]);

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Cell>Date</Table.Cell>
          <Table.Cell>Dept | Category</Table.Cell>
          <Table.Cell textAlign="right" widthPercent={ 15 }>Amount</Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        { (isAccountantMember || hasCategorizationService) && pastTransactions.map((pastTransaction) => {
          if (transaction.id === pastTransaction.id) return null;

          return (
            <Item
              key={ pastTransaction.id }
              pastTransaction={ pastTransaction }
            />
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default PastTransactionsList;
