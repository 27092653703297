import React, { useCallback, useMemo } from 'react';

import { TOrderDirection } from '@src/types/common';
import { ISorting } from '@src/types/sorting';
import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import SortIndicator from '@src/components/utils/sort_indicator';

import styles from './styles.module.scss';

interface ITableHeaderCellCommonProps extends
  React.ComponentPropsWithoutRef<'th'>, IUIStyleProps
{
  /**
   * Width of the column.
   *
   * All units acceptable by css may be used. For example: `'50%'`, `'100px'`, `'20rem'`.
   */
  width?: string;
}

interface ITableHeaderCellSortableProps<TSortColumn extends string> {
  columnName: TSortColumn,
  sorting: ISorting<TSortColumn>,
}

interface ITableHeaderCellNotSortableProps {
  columnName?: never,
  sorting?: never,
}

type TTableHeaderCellProps<TSortColumn extends string = never> =
  ITableHeaderCellCommonProps & (
    ITableHeaderCellNotSortableProps |
    ITableHeaderCellSortableProps<TSortColumn>
  );

const TableHeaderCell = <TSortColumn extends string = never>({
  children,
  columnName,
  sorting,
  style,
  width,
  ...props
}: TTableHeaderCellProps<TSortColumn>) => {
  const sortedDirection: TOrderDirection | undefined =
    sorting && columnName ? sorting.getColumnSortDirection(columnName) : undefined;

  const [classes, thProps] = uiStyleProps(
    undefined,
    props,
    {
      [styles['table-sortable-header-cell']]: sorting,
    },
  );

  const { toggleColumSort, sortColum } = sorting || {};
  const handleSort = useCallback(() => {
    if (!toggleColumSort || !columnName) return;

    toggleColumSort(columnName);
  }, [columnName, toggleColumSort]);

  const handleSortAsc = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    if (!sortColum || !columnName) return;
    sortColum(columnName, 'asc');
  }, [columnName, sortColum]);

  const handleSortDesc = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    if (!sortColum || !columnName) return;
    sortColum(columnName, 'desc');
  }, [columnName, sortColum]);

  const cellStyle = useMemo(() => {
    if (!width) return style;

    return {
      ...style || {},
      width,
    };
  }, [style, width]);

  return (
    <th
      className={ classes }
      style={ cellStyle }
      onClick={ handleSort }
      { ...thProps }
    >
      <div className={ styles['table-header-content'] }>
        <span className={ styles['table-header-title'] }>
          { children }
        </span>
        { sorting && (
          <>
            { ' ' }
            <SortIndicator
              direction={ sortedDirection }
              onSortAsc={ handleSortAsc }
              onSortDesc={ handleSortDesc }
            />
          </>
        ) }
      </div>
    </th>
  );
};

const MemoizedTableHeaderCell = React.memo(TableHeaderCell) as typeof TableHeaderCell;

export {
  TTableHeaderCellProps,
  MemoizedTableHeaderCell as default,
};
