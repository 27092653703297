import { atom } from 'recoil';

import { Family } from '@src/constants/atom_keys';

const togglePortfolioViewState = atom<boolean>({
  key:     Family.TogglePortfolioViewState,
  default: true,
});

export {
  togglePortfolioViewState,
};
