import React from 'react';

import { GroupChannel, Member } from '@sendbird/chat/groupChannel';

import toastr from '@lib/toastr';
import { useRemoveUserFromChannel } from '@src/hooks/queries/chat_central';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';

import userDefault from '@assets/images/user_default.png';

import styles from '../../styles.module.scss';

interface IMemberItemProps {
member: Member,
channel: GroupChannel | null,
handleSuccessRemoveMember: (member:Member) => void,
}

const MemberItem = React.memo(({ member, channel, handleSuccessRemoveMember }: IMemberItemProps) => {
  const removeUserQuery = useRemoveUserFromChannel();
  const { mutate: removeUser } = removeUserQuery;
  const isMuted = channel?.myMutedState === 'muted';
  const isRemoveButtonVisible: boolean = !isMuted && Number(member.userId) !== Number(window.Docyt.currentAdvisor.get('id'));

  const handleRemoveMember = () => {
    if (!channel?.url || !member.userId) return;
    removeUser({
      channelUrl: channel.url,
      userId:     member.userId,
    }, {
      onSuccess: () => {
        toastr.success('User removed successfully', 'Sucess');
        handleSuccessRemoveMember(member);
      },
      onError: (error) => {
        toastr.error(error.message, 'Error');
      },
    });
  };

  const confirmModal = useConfirmDeleteModal({
    onDone: handleRemoveMember,
  });

  return (
    <div className={ styles['member-list'] }>
      <div className={ styles['member-avatar'] }>
        <img
          alt={ member.nickname }
          src={ member.profileUrl }
          onError={ ({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = userDefault;
          } }
        />
      </div>
      <div className={ styles['member-title'] }>{member.nickname}</div>
      {isRemoveButtonVisible && (
      <>
        <confirmModal.Component
          confirmTitle="Remove"
          text={ (
            <p>
              Are you sure you want to remove
              {' '}
              <strong>{member.nickname}</strong>
              {' '}
              from the
              {' '}
              <strong>{channel?.name}</strong>
              {' '}
              chat?
            </p>
						) }
          title="Remove User"
          { ...confirmModal.props }
        />
        <button
          className={ styles['remove-button'] }
          type="button"
          onClick={ confirmModal.open }
        >
          Remove User
        </button>
      </>
      )}
    </div>
  );
});

MemberItem.displayName = 'MemberItem';

export default MemberItem;
