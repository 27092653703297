import React, { useState, useEffect } from 'react';

import { Button } from '@src/components/ui_v2/buttons';

import styles from './styles.module.scss';

const AISuggestion: React.FC<{ content: string }> = ({ content }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [more, setMore] = useState(false);

  const showMore = (isFlag: boolean) => setMore(isFlag);
  const hasEllipsis = content.length > 130;

  const displayContent = hasEllipsis ? content.slice(0, 130) : content;

  useEffect(() => {
    if (isTypingComplete) return () => {};

    let textIndex = 0;

    const wordInterval = setInterval(() => {
      if (textIndex < displayContent.length) {
        setDisplayedText((prevText) => prevText + displayContent[textIndex]);
        textIndex += 1;
      } else {
        setIsTypingComplete(true);
        clearInterval(wordInterval);
      }
    }, 50);

    return () => clearInterval(wordInterval);
  }, [displayContent, isTypingComplete]);

  const ellipsisSign = () => {
    if (hasEllipsis) {
      if (!more) {
        return (
          <Button className={ styles['ellipsis-sign'] } variant="link" onClick={ () => showMore(true) }>
            …more
          </Button>
        );
      }
      if (more) {
        return (
          <Button className={ styles['ellipsis-sign'] } variant="link" onClick={ () => showMore(false) }>
            less
          </Button>
        );
      }
    }
    return <div />
  }

  const showContent = more ? content : displayedText;

  return (
    <span>
      { showContent }
      { isTypingComplete ? ellipsisSign() : <span className={ styles['ai-cursor'] }>|</span> }
    </span>
  );
};

export default AISuggestion;
