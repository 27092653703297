import React, { useEffect } from 'react';

import toastr from '@lib/toastr';
import { useGetIntegrationAuthorizationUrl } from '@src/hooks/queries/integrations/integrations';
import { TID } from '@src/types/common';

interface PullIntegrationOauthAuthorizationProps {
  integrationId: TID | null;
  onSuccess: () => void;
}

const PullIntegrationOauthAuthorization: React.FC<PullIntegrationOauthAuthorizationProps> = ({
  integrationId,
  onSuccess,
}) => {
  const { data: authorizationUrlData } = useGetIntegrationAuthorizationUrl({ integrationId: integrationId! });

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) return;

      if (event.data.type === 'integration:authorization:success') {
        onSuccess();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [onSuccess]);

  useEffect(() => {
    if (authorizationUrlData) {
      const oauthWindow = window.open(
        authorizationUrlData.authorizationUrl,
        'popUpWindow',
        'height=700, width=800, left=100, top=100, '
        + 'resizable=yes, scrollbars=yes, toolbar=yes, '
        + 'menubar=no, location=no, directories=no, status=yes',
      );

      if (oauthWindow === null) {
        toastr.error(
          'Pop-ups are blocked. Please enable pop-ups in your browser settings to continue.',
          'Pop-up Blocked',
        );
      }
    }
  }, [authorizationUrlData]);

  return null;
};

export default PullIntegrationOauthAuthorization;
