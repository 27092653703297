/* eslint-disable max-len */
import React, { FC } from 'react';

import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';

import { UnableToSyncIcon, WarningTriangleIcon } from '@src/components/utils/icomoon';

import { ReconciliationItemCheckResult } from './utils';

interface CheckResultIconProps {
  checkResult: ReconciliationItemCheckResult,
}

const CheckResultIcon: FC<CheckResultIconProps> = ({ checkResult }) => {
  const reconciliation = useBankAccountReconciliationContext();
  if (!reconciliation || reconciliation.status === 'reconciled' || reconciliation.status === 'acknowledged') return null;

  switch (checkResult.result) {
    case 'error':
      return <UnableToSyncIcon fontSize={ 18 } inColor="red-400" />;
    case 'warn':
      return <WarningTriangleIcon fontSize={ 24 } />;
    default:
      return null;
  }
};

export default CheckResultIcon;
