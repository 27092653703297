import React from 'react';

import { IBusinessSimple } from '@src/types/businesses';
import { IWidget } from '@src/types/dashboards';

import Table from '@src/components/ui_v2/table';

import Row from './row';

interface ITableBodyProps {
  widgets: IWidget[];
  businesses: IBusinessSimple[];
}

const TableBody = ({
  widgets,
  businesses,
}: ITableBodyProps): JSX.Element => {
  return (
    <Table.Body>
      {
        businesses.map((business) => (
          <Row key={ business.id } business={ business } item={ widgets } />
        ))
      }
    </Table.Body>
  );
};

export default TableBody;
