import React from 'react';

import { Control, Controller } from 'react-hook-form';

import SearchInput from '@src/components/ui_v2/search_dropdown/search_input';

import styles from '../style.module.scss';

interface FilterProps {
  control: Control<any>;
}

const Filter: React.FC<FilterProps> = ({ control }) => {
  return (
    <div className={ styles['operational-dashboard-table-section-filter'] }>
      <Controller
        control={ control }
        name="search_business_by_name"
        render={ ({ field: { onChange, value } }) => (
          <SearchInput
            id="search-business-by-name"
            placeholder="Search businesses"
            value={ value }
            onChange={ onChange }
          />
        ) }
      />
    </div>
  );
};

export default React.memo(Filter);
