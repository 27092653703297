import React, { useCallback } from 'react';

import classNames from 'classnames';

import Dropdown from '@src/components/ui_v2/dropdown';
import { CheckboxInput } from '@src/components/ui_v2/inputs';
import { useItemsSelector } from '@src/components/utils_v2/items_selector';

import styles from './styles.module.scss';

interface ISelectTableAllItemHCellProps extends
  Omit<React.ComponentPropsWithoutRef<'th'>, 'children'>
{
  bulkActions?: React.ReactNode,
  sticky?: boolean,
  inputProps?: React.ComponentPropsWithoutRef<typeof CheckboxInput>,
}

const SelectTableAllItemHCell = ({
  bulkActions,
  sticky,
  className,
  inputProps,
  ...cellProps
}: ISelectTableAllItemHCellProps) => {
  const classes = classNames(
    styles['table-select-all-header-cell'],
    {
      [styles['with-bulk-actions']]: bulkActions,
      [styles.sticky]:               sticky,
      [styles.left]:                 sticky,
    },
    className,
  );

  const { markAll, getSelectionState } = useItemsSelector();

  const handleChange = useCallback((checked) => {
    markAll(checked);
  }, [markAll]);

  const selectionState = getSelectionState();
  const checked = selectionState === 'all' || selectionState === 'some';
  const intermediate = selectionState === 'some';

  return (
    <th
      className={ classes }
      { ...cellProps }
    >
      <div className={ styles['table-select-all-header-cell-container'] }>
        <CheckboxInput
          small
          checked={ checked }
          intermediate={ intermediate }
          hiddenTitle="Select all"
          onChange={ handleChange }
          { ...inputProps }
        />
        { bulkActions && (
          <Dropdown.Provider className={ styles['table-bulk-actions-content'] }>
            <Dropdown.InputIndicator className={ styles['table-bulk-actions-toggle-button'] } />
            <Dropdown.Menu>
              { bulkActions }
            </Dropdown.Menu>
          </Dropdown.Provider>
        ) }
      </div>
    </th>
  );
};

export default React.memo(SelectTableAllItemHCell);
