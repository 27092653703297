import React from 'react';

import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.scss';

const BusinessLoading = () => {
  return (
    <div className={ styles['business-loading'] }>
      {[...Array(8)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={ index } className={ styles['loading-row'] }>
          <Skeleton className={ styles['icon-skeleton'] } height={ 16 } width={ 16 } />
          <Skeleton className={ styles['text-skeleton'] } height={ 12 } width={ 100 } />
        </div>
      ))}
    </div>
  );
};

export default React.memo(BusinessLoading);
