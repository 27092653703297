import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import CorporateCardsSidebarMenuItems from './corporate_card_sidebar_menu_items/corporate_card_sidebar_menu_items';

interface IProps {
  businessId: TID
}

const CorporateCardsSidebarMenuWrapper = ({ businessId }: IProps) => {
  return (
    <Root>
      <CorporateCardsSidebarMenuItems businessId={ businessId } />
    </Root>
  );
};

export default CorporateCardsSidebarMenuWrapper;
