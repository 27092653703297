import React from 'react';

import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui_v2/table';

import GroupHeader from '../components/custom_cells/group_header';
import { collaborationKeyToStringMap, DASHBOARD_ICONS, expenseKeyToStringMap,
  IBusinessOwnerConfigFormType, reconciliationKeyToStringMap, revenueKeyToStringMap, monthEndCloseKeyToStringMap } from '../helper';
import { hasActivePreference, mapObjectKeys } from '../helper/functional';
import { TDashboardSortColumn } from '../types';

import styles from './style.module.scss';

interface IDashboardTableHeadProps {
    preferences?: IBusinessOwnerConfigFormType;
    sorting: ISorting<TDashboardSortColumn>;
}

const TableHead = ({
  preferences,
  sorting,
}: IDashboardTableHeadProps) => {
  return (
    <Table.Head>
      <Table.Row>
        <Table.HCell<TDashboardSortColumn>
          columnName="business"
          sorting={ sorting }
          width="274px"
        >
          Businesses
        </Table.HCell>
        <Table.HCell width="210px">
          <GroupHeader
            isIssuesHeader
            elements={ DASHBOARD_ICONS }
            heading="Issues"
          />
        </Table.HCell>
        {hasActivePreference('expense', preferences) && (
        <Table.HCell className={ styles['expense-header'] } width="300px">
          <GroupHeader
            elements={
               preferences?.expense
               && mapObjectKeys(preferences.expense, expenseKeyToStringMap)
             }
            heading="Expense"
          />
        </Table.HCell>
        )}
        { hasActivePreference('revenue', preferences)
                && (
                  <Table.HCell width="200px">
                    <GroupHeader
                      elements={
                          preferences?.revenue
                          && mapObjectKeys(preferences?.revenue, revenueKeyToStringMap)
                        }
                      heading="Revenue"
                    />
                  </Table.HCell>
                )}
        { hasActivePreference('continuousReconciliation', preferences) && (
        <Table.HCell width="200px">
          <GroupHeader
            elements={
                          preferences?.continuousReconciliation
                          && mapObjectKeys(
                            preferences?.continuousReconciliation,
                            reconciliationKeyToStringMap,
                          )
                        }
            heading="Continous Reconciliation"
          />
        </Table.HCell>
        )}
        { hasActivePreference('monthEndClosing', preferences) && (
        <Table.HCell width="300px">
          <GroupHeader
            elements={
                          preferences?.monthEndClosing
                          && mapObjectKeys(
                            preferences?.monthEndClosing,
                            monthEndCloseKeyToStringMap,
                          )
                        }
            heading="Month End Closing"
          />
        </Table.HCell>
        )}
        {hasActivePreference('collaboration', preferences) && (
        <Table.HCell width="290px">
          {' '}
          <GroupHeader
            elements={
                  preferences?.collaboration
                  && mapObjectKeys(
                    preferences?.collaboration,
                    collaborationKeyToStringMap,
                  )
                }
            heading="Collaboration"
          />
        </Table.HCell>
        )}
      </Table.Row>
    </Table.Head>
  );
};

export default TableHead;
