import React from 'react';

import { IWidget } from '@src/types/dashboards';

import Table from '@src/components/ui_v2/table';

import Row from './row';

interface ITableBodyProps {
  widgets: IWidget[];
}

const TableBody = ({
  widgets
}: ITableBodyProps): JSX.Element => {
  return (
    <Table.Body>
      <Row item={ widgets } />
    </Table.Body>
  );
};

export default TableBody;
