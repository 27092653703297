import React, { useEffect, useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetAccountingClasses } from '@src/hooks/queries/accounting_classes';
import { ITSDComponentProps } from '@src/types/transaction_service_documents';

import BusinessChartOfAccountField from '@src/components/common_v2/form_fields/business_chart_of_account_field';
import Spinner from '@src/components/ui/spinner';
import { AmountInput } from '@src/components/ui_v2/inputs';
import Tooltip from '@src/components/ui_v2/tooltip';

import AmountField from './amount_field';
import DocytAiLearnToggle from './docyt_ai_learn_toggle';
import NoDocumentNeeded from './no_document_needed';
import NoPushQbo from './no_push_qbo';
import SidebarSelectInput from './sidebar_select_input';
import TransactionTypeSelector from './transaction_type_selector';
import useTxnDoc from './use_txn_doc';
import UnverifiedIcon from '../../../../utils/icomoon/unverified';

import styles from '@src/components/reconciliation_center/all_transactions/styles.module.scss';

const subTypeOptions = [
  { value: 'ach_transfer_to', label: 'ACH Transfer to another bank' },
  { value: 'payment_cash_check', label: 'Payment using Cash / Check' },
];

const LoanPayments = ({ rowData, setRowData, verify }: ITSDComponentProps) => {
  const { updateFields, handleCreateDocumentNote, handleNdnChange, commonProps } = useTxnDoc({
    rowData,
    setRowData,
  });

  const { isSubmitting, isDisabled, doNotLearn, noDocumentNeeded, documentNote, noPushQbo, documentRelated } = commonProps;

  const subType = subTypeOptions.find((i) => i.value === rowData.subType);

  const business = useBusinessContext();

  const departmentQuery = useGetAccountingClasses({
    businessId: business.id,
  });

  const departmentOptions = useMemo(() => {
    const departments = departmentQuery.data?.collection;
    if (!departments) return [];
    return departments.map((i) => ({ value: String(i.id), label: i.name }));
  }, [departmentQuery.data?.collection]);

  const department = departmentOptions.find((i) => i.value === String(rowData.interestAccountingClass?.id));

  const totalAmount = (parseFloat(rowData.principalAmount || '0')
    + parseFloat(rowData.interestAmount || '0')).toFixed(2);
  const txnAmount = (-rowData.amount).toFixed(2);
  const amountEqual = totalAmount === txnAmount;

  useEffect(() => {
    let disabled = true;

    const { principalChartOfAccountId, interestChartOfAccountId, principalAmount, interestAmount } = rowData;

    if (subType && principalChartOfAccountId && interestChartOfAccountId
      && principalAmount && interestAmount && amountEqual && documentRelated) {
      disabled = false;
    }

    verify(disabled);
  }, [amountEqual, rowData, subType, documentRelated, verify]);

  return (
    <>
      {isSubmitting && <Spinner />}
      <div className={ styles['sidebar-type-config'] }>
        <TransactionTypeSelector rowData={ rowData } setRowData={ setRowData } />

        <SidebarSelectInput
          isDisabled={ isDisabled }
          label="Payment Type*"
          options={ subTypeOptions }
          placeholder="Select Payment Type"
          value={ subType }
          onChange={ (val) => updateFields({ subType: val }) }
        />

        <BusinessChartOfAccountField
          key="principalChartOfAccountId"
          hideClear
          businessId={ business.id }
          isDisabled={ isDisabled }
          label="Principal Category*"
          labelClass={ styles['select-label'] }
          placeholder="Enter Category Name or Code"
          queryParams={ { searchClassification: ['Liability'] } }
          value={ rowData.principalChartOfAccountId }
          onChange={ (id) => updateFields({ principalChartOfAccountId: id }) }
        />

        <SidebarSelectInput
          isDisabled={ isDisabled }
          label="Interest Department"
          options={ departmentOptions }
          placeholder="Enter Department"
          value={ department }
          onChange={ (val) => updateFields({ interestAccountingClassId: val }) }
        />

        <BusinessChartOfAccountField
          key="interestChartOfAccountId"
          hideClear
          businessId={ business.id }
          isDisabled={ isDisabled }
          label="Interest Category*"
          labelClass={ styles['select-label'] }
          placeholder="Enter Category Name or Code"
          queryParams={ { searchClassification: ['Expense'] } }
          value={ rowData.interestChartOfAccountId }
          onChange={ (id) => updateFields({ interestChartOfAccountId: id }) }
        />

        <AmountField
          key={ rowData.principalAmount }
          initialValue={ rowData.principalAmount || '' }
          isDisabled={ isDisabled }
          label="Principal Amount*"
          onChange={ (val) => updateFields({ principalAmount: val }) }
        />

        <AmountField
          key={ rowData.interestAmount }
          initialValue={ rowData.interestAmount || '' }
          isDisabled={ isDisabled }
          label="Interest Amount*"
          onChange={ (val) => updateFields({ interestAmount: val }) }
        />

        <div className={ styles['sidebar-data'] }>
          <span className={ styles['select-icon-label'] }>
            Total Amount
            <Tooltip.Hover
              content="Total amount is sum of Principal Amount and Interest Amount.
            In order to verify a transaction Total Amount should match Transaction Amount."
            >
              <UnverifiedIcon fontSize={ 18 } />
            </Tooltip.Hover>
          </span>

          <AmountInput
            disabled
            style={ { color: amountEqual ? '' : 'red' } }
            value={ totalAmount }
            onChange={ () => {} }
          />
        </div>

        <div className={ styles['sidebar-data'] }>
          <span className={ styles['select-label'] }>Txn Amount</span>
          <AmountInput
            disabled
            value={ txnAmount }
            onChange={ () => {} }
          />
        </div>

        <DocytAiLearnToggle
          checked={ !doNotLearn }
          isDisabled={ isDisabled }
          onChange={ (checked) => updateFields({ doNotLearn: !checked }) }
        />
      </div>

      <div className={ styles['sidebar-type-config'] }>
        <NoDocumentNeeded
          documentNote={ documentNote }
          isDisabled={ isDisabled }
          noDocumentNeeded={ noDocumentNeeded }
          rowData={ rowData }
          onDocumentNoteChange={ handleCreateDocumentNote }
          onNdnChange={ handleNdnChange }
        />
        <NoPushQbo
          isDisabled={ isDisabled }
          noPushQbo={ noPushQbo }
          onChange={ (checked) => updateFields({ noPushQbo: checked }) }
        />
      </div>
    </>
  );
};

export default LoanPayments;
