import React from 'react';

import { isInSetupStatus } from '@src/components/operational_dashboard/helper/functional';
import { IBusinessCommonProps, TViewOperationalDashboard } from '@src/components/operational_dashboard/types';
import { AppLink } from '@src/components/ui_v2/buttons';
import { BusinessDefaultAvatarIcon, BusinessDriveIcon } from '@src/components/utils/icomoon';
import ImgIcon from '@src/components/utils/img_icons/img_icon';

import styles from './style.module.scss';

interface IBusinessCellProps extends IBusinessCommonProps {
  user?: TViewOperationalDashboard | null;
}
const BusinessCell = (
  { displayName, legalName, icon, user, setupStatus, unreadInboxMessages, businessId }: IBusinessCellProps,
) => {
  return (
    <AppLink
      href={ `/businesses/${businessId}` }
    >
      <div className={ styles['business-column-container'] }>
        {isInSetupStatus(setupStatus) && <span className={ styles['in-setup-tag'] }>IN SETUP</span>}
        { icon ? (
          <div className={ styles['business-icon-container'] }>
            <ImgIcon
              alt="Business Icon"
              className={ styles['business-icon'] }
              src={ icon }
            />
          </div>
        )
          : <BusinessDefaultAvatarIcon className={ styles['business-icon-default'] } fontSize={ 32 } />}
        <div className={ styles['business-content'] }>
          <h3 className={ styles['business-name'] }>{displayName}</h3>
          <p className={ styles['business-type'] }>{legalName}</p>
        </div>
        <div className={ styles['business-actions'] }>
          {user === 'accountant' && (
            <>
              <BusinessDriveIcon className={ styles[unreadInboxMessages ? 'dark-icon' : 'light-icon'] } />
              {unreadInboxMessages && <div className={ styles['notification-badge'] }>{unreadInboxMessages}</div>}
            </>
          )}
        </div>
      </div>
    </AppLink>
  );
};
export default BusinessCell;
