import React, { useState, useCallback, useEffect } from 'react';

import { IBusinessMetricValue } from '@src/types/business_metric_value';
import { API_DATE_FORMAT, endOfMonthApiDate, getUTCTimezone, formatDate } from '@src/utils/date_helpers';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import Header from './header';
import useMetricValuesCollection from './hooks';
import List from './list_item/metric_values';
import { useBusinessMetricContext } from '../business_metric_context';

import styles from './styles.module.scss';

const MetricsServiceDetails = (): JSX.Element => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [editingValues, setEditingValues] = useState<IBusinessMetricValue[]>([]);
  const metric = useBusinessMetricContext();
  const [initialized, setInitialized] = useState(false);

  const collection = useMetricValuesCollection({
    businessMetricId: metric.id!,
  });

  const generateDefaultValues = (selectedMonth: string): IBusinessMetricValue[] => {
    const endDate = new Date(endOfMonthApiDate(getUTCTimezone(new Date(selectedMonth))));
    const daysInMonth = endDate.getDate();
    const [year, month] = selectedMonth.split('-').map(Number);

    const defaultValues = Array.from({ length: daysInMonth }, (_, index) => {
      const day = index + 1;
      const date = formatDate(new Date(year, month - 1, day), API_DATE_FORMAT);
      return { date, value: 0 };
    });

    return defaultValues;
  };
  const handleValueChanged = useCallback((date: string, value?: number) => {
    if (value === undefined) return;

    setEditingValues((originValues) => {
      const newValues = originValues.map((originValue) => {
        if (originValue.date !== date) return originValue;

        return {
          ...originValue,
          value,
        };
      });

      return newValues;
    });
  }, []);

  useEffect(() => {
    if (isEditMode) {
      setInitialized(false); // Reset initialized when entering edit mode
    }
  }, [isEditMode]);

  const initializeValues = () => {
    if (!initialized && isEditMode) {
      setInitialized(true);
      if (collection.records && collection.records.length > 0) {
        const initialValues = collection.records.map((item) => ({
          date:  item.date,
          value: item.value,
        }));
        setEditingValues(initialValues);
      } else {
        const defaultValues = generateDefaultValues(collection.selectedMonth);
        setEditingValues(defaultValues);
      }
    }
  };

  if (isEditMode && !initialized) {
    initializeValues();
  }

  useEffect(() => {
    setIsEditMode(false);
  }, [collection.selectedMonth]);

  if (!metric) {
    return (
      <div className={ styles.centeredContainer }>
        Error loading metric detail. Please try again later.
      </div>
    );
  }

  return (
    <div className={ styles.centeredContainer }>
      <DetailsRegion>
        <DetailsRegion.Header title={ metric.name }>
          <Header
            collection={ collection }
            editingValues={ editingValues }
            isEditMode={ isEditMode }
            metric={ metric }
            setIsEditMode={ setIsEditMode }
          />
        </DetailsRegion.Header>
        <List
          collection={ collection }
          editingValues={ editingValues }
          isEditMode={ isEditMode }
          metric={ metric }
          setIsEditMode={ setIsEditMode }
          onValueChange={ handleValueChanged }
        />
      </DetailsRegion>
    </div>
  );
};

export default React.memo(MetricsServiceDetails);
