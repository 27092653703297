import React, { useCallback } from 'react';

import { TID } from '@src/types/common';
import { IFinancialInstitutionBankAccount } from '@src/types/financial_institution_bank_account';
import { IManagementGroup } from '@src/types/management_groups';
import { IFICReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import { Button as ButtonLink, LinkButton } from '@src/components/ui/buttons';
import Table from '@src/components/ui/table';
import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';
import { InfoIcon } from '@src/components/utils/icomoon';

import { useEmployeeCardMappingList } from './employee_card_mapping/employee_card_mapping_list';
import { useEmployeeCardMappingModal } from './employee_card_mapping/employee_card_mapping_modal';

import styles from '../styles.module.scss';

interface BankAccountMappingInfoProps {
  accountType: string;
  employeeCardFeatureFlag: boolean;
  financialInstitutionBankAccount: IFinancialInstitutionBankAccount;
  refetchFinancialConnectionList: () => void;
  managementGroup: IManagementGroup;
  handleSetupAccountClick: (financialBankAccountId: TID) => void;
}

const FICBankAccountMappingInfo: React.FC<BankAccountMappingInfoProps> = ({
  accountType,
  employeeCardFeatureFlag,
  financialInstitutionBankAccount,
  managementGroup,
  handleSetupAccountClick,
  refetchFinancialConnectionList,
}) => {
  const { reconciliationPaymentAccounts } = financialInstitutionBankAccount;
  const isMappedWithRPA: boolean = Boolean(reconciliationPaymentAccounts?.length);
  const {
    Component: EmployeeCardMappingModalComponent,
    props: employeeCardMappingModalProps,
    openWithValue,
  } = useEmployeeCardMappingModal();

  const {
    Component: EmployeeCardMappingListComponent,
    props: employeeCardMappingListProps,
    openWithValue: openEmployeeCardMappingList,
  } = useEmployeeCardMappingList();

  const handleOpenModal = useCallback((rpaBankAccount: IFICReconciliationPaymentAccount) => {
    openWithValue({
      reconciliationPaymentAccountId:    rpaBankAccount.id,
      financialInstitutionBankAccountId: financialInstitutionBankAccount.id,
      businessId:                        rpaBankAccount.businessId,
    });
  }, [openWithValue, financialInstitutionBankAccount.id]);

  /**
   * Opens the employee card mapping list modal for a given reconciliation payment account
   * Only opens if the account is not currently fetching owner information
   * @param rpaBankAccount - The reconciliation payment account to view mappings for
   */
  const viewMappingList = useCallback((rpaBankAccount: IFICReconciliationPaymentAccount) => {
    if (!rpaBankAccount.isFetchingAccountOwnerInformation) {
      openEmployeeCardMappingList({
        reconciliationPaymentAccountId:    rpaBankAccount.id,
        financialInstitutionBankAccountId: financialInstitutionBankAccount.id,
      });
    }
  }, [openEmployeeCardMappingList, financialInstitutionBankAccount.id]);

  return employeeCardFeatureFlag ? (
    <Table.Cell className={ styles.cell }>
      <EmployeeCardMappingModalComponent
        { ...employeeCardMappingModalProps }
        managementGroupId={ managementGroup.id }
        refetchFinancialConnectionList={ refetchFinancialConnectionList }
      />
      <EmployeeCardMappingListComponent
        { ...employeeCardMappingListProps }
        refetchFinancialConenctionList={ refetchFinancialConnectionList }
      />
      {isMappedWithRPA ? (
        <div className={ styles['fic-banking-account-create-mapping'] }>
          {reconciliationPaymentAccounts?.map((rpaBankAccount) => (
            <div key={ rpaBankAccount.id } className={ styles['row-item'] }>
              <div className={ styles['bank-account-name'] }>
                {rpaBankAccount.bankAccountName}
              </div>
              {rpaBankAccount.isMultiEntity && (
                <div className={ styles['multi-entity-maping-status'] }>
                  {(() => {
                    if (rpaBankAccount.isMapRequired) {
                      return rpaBankAccount.canAccess && (
                        <Button className={ styles['horizontal-padding'] } variant="outline" onClick={ () => handleOpenModal(rpaBankAccount) }>
                          Map Employee Cards
                        </Button>
                      );
                    }

                    if (rpaBankAccount.isFetchingAccountOwnerInformation) {
                      return (
                        <>
                          <span className={ styles['multi-entity-maping-status-text-disabled'] }>
                            {rpaBankAccount.mappingStatus}
                          </span>
                          <Tooltip.Hover content="We are currently fetching the employee cards from bank based on the transactions. This might take hours to fetch." variant="dark">
                            <div className={ styles['multi-entity-maping-info-icon'] }>
                              <InfoIcon />
                            </div>
                          </Tooltip.Hover>
                        </>
                      );
                    }

                    return (
                      <LinkButton
                        className={ styles['multi-entity-maping-status-text'] }
                        title={ rpaBankAccount.mappingStatus }
                        onClick={ () => viewMappingList(rpaBankAccount) }
                      />
                    );
                  })()}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <>
          <span className={ styles['no-bank-account-mapped-for-employee-cards'] }>
            {`No ${accountType === 'credit_card' ? 'Credit Card' : 'Banking Account'} mapped`}
          </span>
          <ButtonLink
            bsStyle="link"
            className={ styles['map-credit-card'] }
            onClick={ () => handleSetupAccountClick(financialInstitutionBankAccount.id) }
          >
            {`Map ${accountType === 'credit_card' ? 'Credit Card' : 'Bank Account'}`}
          </ButtonLink>
        </>
      )}
    </Table.Cell>
  ) : (
    <Table.Cell>
      {financialInstitutionBankAccount.bankAccountName ? (
        <span>{financialInstitutionBankAccount.bankAccountName}</span>
      ) : (
        <span className={ styles['no-bank-account-mapped'] }>No Banking Account mapped</span>
      )}
    </Table.Cell>
  );
};

export default FICBankAccountMappingInfo;
