import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IAngleDoubleUpIconProps extends Omit<IFaIconProps, 'icon'> {}

const AngleDoubleUpIcon = (props: IAngleDoubleUpIconProps): JSX.Element => {
  return <FaIcon icon="angle-double-up" { ...props } />;
};

export default AngleDoubleUpIcon;
