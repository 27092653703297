import React from 'react';

import Tooltip from '@src/components/ui_v2/tooltip';
import BankStatementArchived from '@src/components/utils/icomoon/bank_statement_archived';

import styles from '../../../styles.module.scss';

const ArchivedStatusItem = () => {
  const archivedTooltip = (
    <div className="not-available-status">
      <div className={ styles['state-status'] }>
        <BankStatementArchived fontSize={ 20 } />
        <span className={ styles['statement-state'] }>Archived Account</span>
      </div>
    </div>
  );

  return (
    <Tooltip.Hover
      className={ styles['statement-item'] }
      content={ archivedTooltip }
      tooltipClassName={ styles['missing-state-item'] }
    >
      <BankStatementArchived fontSize={ 20 } />
    </Tooltip.Hover>
  );
};

export default ArchivedStatusItem;
