import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import BillPaySidebarMenuItems from './bill_pay_sidebar_menu_items/bill_pay_sidebar_menu_items';

interface IBillPaySidebarMenuWrapperProps {
  businessId: TID
}

const BillPaySidebarMenuWrapper = ({ businessId }: IBillPaySidebarMenuWrapperProps) => {
  return (
    <Root>
      <BillPaySidebarMenuItems businessId={ businessId } />
    </Root>
  );
};

export default BillPaySidebarMenuWrapper;
