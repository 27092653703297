import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { getPaymentStatusClass } from '@src/components/vendors/my_vendors/details/payments/utils';

interface IInvoiceStatusProps {
  invoice: IAccountsPayableServiceDocument, 
}

const PaymentStatus = ({ invoice }: IInvoiceStatusProps) => {
  const status = invoice.paymentStatus;
  
  return (
    <div>
      <span className={ `invoice-status-panel font-bold text-nowrap ${getPaymentStatusClass(invoice, status)}` }>
        { status || '-' }
      </span>
    </div>
  );
};

export default React.memo(PaymentStatus);

