/* eslint-disable max-len */
import React, { useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import {
  dataExportPath,
  settingsIntegrationsPath,
  myPracticePath,
} from '@src/routes';
import { TID } from '@src/types/common';
import { LDFeatureForUserEnabled, LDFeatureValueForUser } from '@src/utils/config';

import AIAnalyticsAction from '@src/components/header/settings/ai_analytics_action';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import DataExportAction from './data_export_action';
import JournalEntriesAction from './journal_entries_action';

import styles from '../styles.module.scss';

type TActionsProps = {
  currentBusinessClientId?: TID,
  curBusinessId: TID,
  isReceivableOnly: boolean,
  isBusinessUser: boolean,
  isClientView: boolean,
}

const Actions = ({
  currentBusinessClientId,
  curBusinessId,
  isReceivableOnly,
  isBusinessUser,
  isClientView,
}: TActionsProps) => {
  const business = useBusinessContext();

  const [sunsetClientCentralEnabled, setSunsetClientCentralEnabled] = useState<
    string | boolean | undefined>(
      LDFeatureValueForUser(
        window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
        String(window.Docyt.currentAdvisor.id),
      ),
    );

  const dataExportRevampV1Enabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.DATA_EXPORT_REVAMP_V_1_FLAG);

  if (sunsetClientCentralEnabled === undefined) {
    LDFeatureForUserEnabled(
      window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
      String(window.Docyt.currentAdvisor.id),
    ).then((res) => {
      setSunsetClientCentralEnabled(res);
    });
  }

  let clientCentralURL;
  if (currentBusinessClientId) {
    clientCentralURL = `/client_central/${currentBusinessClientId}`;
  } else if (curBusinessId) {
    clientCentralURL = `/client_central/${curBusinessId}`;
  } else {
    clientCentralURL = 'client_central';
  }

  return (
    <>
      {
        !isReceivableOnly && (
          <>
            <span className={ styles['settings-section'] }>DATA</span>

            {
              dataExportRevampV1Enabled ? (
                <DataExportAction />
              ) : (
                <ActionsDropdown.LinkAction
                  className={ styles['settings-item'] }
                  href={ dataExportPath() }
                  title="Data Export"
                />
              )
            }
            { curBusinessId && <JournalEntriesAction businessId={ curBusinessId } /> }
            <span className={ styles['settings-section'] }>CONNECTIONS</span>
            <ActionsDropdown.LinkAction
              className={ styles['settings-item'] }
              href="/financial_institution_connection_center"
              title="Financial Connections"
            />
            <ActionsDropdown.LinkAction
              className={ styles['settings-item'] }
              href={ settingsIntegrationsPath() }
              title="Integrations"
            />
            <span className={ styles['settings-section'] }>COLLABORATION</span>
            <ActionsDropdown.LinkAction
              className={ styles['settings-item'] }
              hidden={ Boolean(sunsetClientCentralEnabled) }
              href={ clientCentralURL }
              title="Client Central"
            />
            {
              isBusinessUser && (
                <ActionsDropdown.LinkAction
                  className={ styles['settings-item'] }
                  href={ myPracticePath() }
                  title="My Practice"
                />
              )
            }
            <ActionsDropdown.Divider />
          </>
        )
      }
      { isClientView && (!business || business.isAccountingFirm) && <AIAnalyticsAction businessId={ curBusinessId } /> }
      <ActionsDropdown.LinkAction
        className={ styles['settings-item'] }
        href="/settings"
        title="Settings"
      />
    </>
  );
};

export default Actions;
