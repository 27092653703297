import React, { useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { IReport } from '@src/types/report_service/report';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';
import { IReportData } from '@src/types/report_service/report_data';
import { IReportItem } from '@src/types/report_service/report_item';

import { hideZeroRowsType, budgetsSelectAddValueType, forecastSelectAddValueType } from '@src/components/ui_v2/filter/atoms';
import Table from '@src/components/ui_v2/table/table';
import TableSection from '@src/components/ui_v2/table_section';

import Body from './body';
import Header from './header';

import ReportDataTableFilter from '../filter';
import { IReportDatasCollection, useGenerateTableData } from '../../hooks';
import { useHideZeroRows } from '../../tableHooks';

interface IReportDataTableProps { 
  collection: IReportDatasCollection;
  report: IReport;
  items: IReportItem[];
  configurations: IReportConfiguration[];
  reportEstimateEnabled?: boolean;
}

const ReportDataTable = ({
  collection,
  report,
  items,
  configurations,
  reportEstimateEnabled=false,
}: IReportDataTableProps): JSX.Element => {
  const budgetsComparison = useRecoilValue(budgetsSelectAddValueType);
  const forecastComparison = useRecoilValue(forecastSelectAddValueType);
  const hideZeroRows = useRecoilValue(hideZeroRowsType);
  const data = useGenerateTableData(report, configurations, collection, budgetsComparison?.[0], forecastComparison?.[0]);
  const updatedData = useHideZeroRows(
    hideZeroRows,
    items,
    report,
    collection?.records,
    data.multiMonthColumns,
    data.ptdColumns,
    data.ytdColumns,
    budgetsComparison?.[0],
    forecastComparison?.[0]
  );

  // To update zero rows show status
  const [itemData, setItemData] = React.useState<any>([]);
  useEffect(() => {
    setItemData([...updatedData])
  },[hideZeroRows, updatedData])

  return (
    <TableSection.Panel
      filter={ (
        <ReportDataTableFilter
          collection={ collection }
          configurations={ configurations }
          isShownCustomize={ data.isShownCustomize }
          report={ report }
          reportEstimateEnabled={ reportEstimateEnabled }
        />
      ) }
    >
      <Table isRegionScroll sticky query={ collection.query } wrapperClassName="table-region-container">
        <Header
          headerBottomRowColumns={ data.headerBottomRowColumns }
          headerMiddleRowColumns={ data.headerMiddleRowColumns }
          headerTopRowColumns={ data.headerTopRowColumns }
          multiMonthColumns={ data.multiMonthColumns }
          ptdColumns={ data.ptdColumns }
          report={ report }
          ytdColumns={ data.ytdColumns }
        />
        <Body
          budgetsComparison={ budgetsComparison?.[0] as string }
          forecastComparison={ forecastComparison?.[0] as string }
          hideZeroRows={ hideZeroRows }
          items={ itemData }
          multiMonthColumns={ data.multiMonthColumns }
          ptdColumns={ data.ptdColumns }
          report={ report }
          reportData={ collection.records as IReportData[] }
          ytdColumns={ data.ytdColumns }
        />
      </Table>
    </TableSection.Panel>
  );
};

export default ReportDataTable;
