import React from 'react';

import { useLocalStorage } from '@src/hooks/storage';

import { useDashboardTableCollection } from '@src/components/operational_dashboard/hooks';
import Table from '@src/components/operational_dashboard/table/table';
import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import { Filter } from './filter';
import { availableViews } from '../helper';
import { TViewOperationalDashboard } from '../types';

import styles from './style.module.scss';

const OperationsDashboardTableList = () => {

  const { storedValue: isClientView } = useLocalStorage('is_client_view', false);
  const view = isClientView ? availableViews.accountant as TViewOperationalDashboard
    : availableViews.businessOwnerView as TViewOperationalDashboard;
  const collection = useDashboardTableCollection({ view });
  const { control } = collection.FilterForm;
  return (
    <Section.Provider section={ collection?.section }>
      <div className={ styles['operational-dashboard-table-section-container'] }>
        <Filter control={ control } />
        <TableSection className={ styles['operational-dashboard-table-section'] }>
          <Table
            infiniteQuery={ collection.query }
            records={ collection.records }
            sorting={ collection.sorting }
            view={ view }
          />
        </TableSection>
      </div>
    </Section.Provider>
  );
};

export default React.memo(OperationsDashboardTableList);
