import React from 'react';

import { IWidget } from '@src/types/dashboards';

import Table from '@src/components/ui_v2/table/table';
import TableSection from '@src/components/ui_v2/table_section';

import Body from './body';
import Header from './header';

import styles from './styles.module.scss';

interface IWidgetReportDataTableProps {
  collection: IWidget[];
}

const WidgetDataTable = ({
  collection,
}: IWidgetReportDataTableProps): JSX.Element => {
  return (
    <TableSection className={ styles['widget-report-data-table-section'] }>
      <TableSection.Panel>
        <Table isRegionScroll sticky wrapperClassName="table-region-container">
          <Header widgets={ collection } />
          <Body widgets={ collection } />
        </Table>
      </TableSection.Panel>
    </TableSection>
  );
};

export default WidgetDataTable;
