/* eslint-disable import/order */
import React, { memo, useCallback, useState } from 'react';

import { UserMessage } from '@sendbird/chat/message';
import {
  ChannelSettings,
  MessageSearch,
} from '@sendbird/uikit-react';
import Channel, { ChannelProps } from '@sendbird/uikit-react/Channel';
import Thread from '@sendbird/uikit-react/Thread';

import EmptyFragment from './empty_fragment';
import Loader from './loader';
import MemberList from './member_list';

import { IReplyThreadProps, SendableMessageType } from '../../types';
import styles from '../../styles.module.scss';
import NoChatBody from './no_chat';

interface IChatBodyProps extends ChannelProps {
  setCurrentChannelUrl: (channelUrl: string) => void;
  parentMessage: SendableMessageType | null;
  setParentMessage(mess: SendableMessageType | null): void;
}

const ChatBody = ({
  channelUrl,
  setCurrentChannelUrl,
  parentMessage,
  setParentMessage,
}: IChatBodyProps) => {
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showChannelInfo, setShowChannelInfo] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState<number | null>(null);

  const onReplyInThread = ({ message }: IReplyThreadProps) => {
    if (message.isUserMessage() || message.isFileMessage()) {
      setParentMessage(message as UserMessage);
      setShowSearch(false);
      setShowChannelInfo(false);
    }
  };

  const onSearch = useCallback(() => {
    setShowSearch(true);
    setShowChannelInfo(false);
    setParentMessage(null);
  }, [setParentMessage]);

  const onChannelInfo = useCallback(() => {
    setShowChannelInfo(true);
    setShowSearch(false);
    setParentMessage(null);
  }, [setParentMessage]);

  const onSearchCloseClick = useCallback(() => {
    setShowSearch(false);
  }, []);

  const onChannelInfoCloseClick = useCallback(() => {
    setShowChannelInfo(false);
  }, []);
  return (
    <div className={ styles['conversation-wrap'] }>
      <Channel
        isMessageGroupingEnabled
        isReactionEnabled
        showSearchIcon
        animatedMessage={ selectedMessage }
        channelUrl={ channelUrl }
        renderPlaceholderInvalid={ () => (channelUrl ? <NoChatBody /> : <Loader />) }
        renderPlaceholderLoader={ () => <Loader /> }
        replyType="THREAD"
        onChatHeaderActionClick={ onChannelInfo }
        onQuoteMessageClick={ onReplyInThread }
        onReplyInThread={ onReplyInThread }
        onSearchClick={ onSearch }
      />

      {channelUrl && parentMessage && (
      <Thread
        key={ parentMessage?.reqId }
        channelUrl={ channelUrl }
        message={ parentMessage }
        renderParentMessageInfoPlaceholder={ () => <Loader /> }
        renderThreadListPlaceHolder={ () => <Loader /> }
        onHeaderActionClick={ () => {
          setParentMessage(null);
        } }
        onMoveToParentMessage={ ({ channel }) => {
          setCurrentChannelUrl(channel?.url);
          setParentMessage(null);
        } }
      />
      )}
      {showChannelInfo && channelUrl && (
      <ChannelSettings
        channelUrl={ channelUrl }
        overrideInviteUser={ () => null }
        renderChannelProfile={ () => <EmptyFragment /> }
        renderLeaveChannel={ () => <EmptyFragment /> }
        renderModerationPanel={ () => <MemberList channelUrl={ channelUrl } /> }
        onCloseClick={ onChannelInfoCloseClick }
      />
      )}
      {showSearch && channelUrl && (
      <MessageSearch
        channelUrl={ channelUrl }
        onCloseClick={ onSearchCloseClick }
        onResultClick={ (message) => setSelectedMessage(message?.messageId) }
      />
      )}
    </div>
  );
};

export default memo(ChatBody);
