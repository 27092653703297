import React, { useState } from 'react';

import { useTCDContext } from '@src/hooks/contexts/transation_context_data_context';

import { Button } from '@src/components/ui_v2/buttons';

import styles from './styles.module.scss';

const DefaultContent: React.FC = () => {
  const [more, setMore] = useState<boolean>(false);
  const { data } = useTCDContext();

  const showMore = (isFlag: boolean) => setMore(isFlag);
  const hasEllipsis = data.aiContext.length > 125;
  const displayContent = hasEllipsis ? data.aiContext.slice(0, 125) : data.aiContext;
  const showContent = more ? data.aiContext : displayContent;
  
  const ellipsisSign = () => {
    if (hasEllipsis) {
      if (!more) {
        return (
          <Button className={ styles['ellipsis-sign'] } variant="link" onClick={ () => showMore(true) }>
            …more
          </Button>
        );
      }
      if (more) {
        return (
          <Button className={ styles['ellipsis-sign'] } variant="link" onClick={ () => showMore(false) }>
            less
          </Button>
        );
      }
    }
    return <div />
  }

  return (
    <span>
      { showContent }
      { ellipsisSign() }
    </span>
  );
};

export default React.memo(DefaultContent);