import React, { useCallback } from 'react';

import { Button } from '@src/components/ui_v2/buttons';

import { usePullIntegrationSuccessModal } from './pull_integration_success_modal';

const PullIntegrationSuccess = (): JSX.Element => {
  const integration = {
    id:                 12,
    externalSystemName: 'Ginger',
  };
  const pullIntegrationSuccessModal = usePullIntegrationSuccessModal();
  const connectPullSystem = useCallback(() => {
    pullIntegrationSuccessModal.open();
  }, [pullIntegrationSuccessModal]);
  return (
    <>
      <pullIntegrationSuccessModal.Component
        UpsertIntegrationResponse={ integration }
        { ...pullIntegrationSuccessModal.props }
      />
      <Button
        variant="link"
        onClick={ connectPullSystem }
      >
        Pull Integration Success
      </Button>
    </>
  );
};
export default PullIntegrationSuccess;
