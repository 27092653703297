/* eslint-disable max-len */
import React, { FC } from 'react';

import { IBankAccountReconciliationItemTypes, IBankAccountReconciliationWithLoading, TBankAccountReconciliationStatus } from '@src/types/bank_account_reconciliations';
import { IBankStatement } from '@src/types/bank_statements';

import Reconciled from './reconciled';
import Unreconciled from './unreconciled';

import styles from './styles.module.scss';

interface SidebarProps {
  items: IBankAccountReconciliationItemTypes
  reconciliation: IBankAccountReconciliationWithLoading
  bankStatement: IBankStatement
}

const Sidebar: FC<SidebarProps> = ({ items, reconciliation, bankStatement }) => {
  const isReconciled = reconciliation.status === 'reconciled' as TBankAccountReconciliationStatus;

  return (
    <div className={ styles.container }>
      <div>
        <div className={ styles.header }>
          <div>Account Reconciliation</div>
        </div>
        {
          isReconciled
            ? <Reconciled bankStatement={ bankStatement } reconciliation={ reconciliation } />
            : <Unreconciled bankStatement={ bankStatement } items={ items } reconciliation={ reconciliation } />
        }
      </div>
    </div>
  );
};

export default Sidebar;
