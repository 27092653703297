import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';

import { TID, TDate } from '@src/types/common';

import RevenueReportTypeField from '@src/components/common_v2/form_fields/revenue_report_type_field';
import ComponentForm from '@src/components/ui_v2/form';

interface IField {
  type: string | TDate;
  required: boolean;
  title: string;
  readOnly?: boolean;
  maxLength?: number;
}

interface IJsonFormProps {
  formId: string;
  businessId: TID;
  jsonSchema: { [key: string]: IField };
  valueForm?: { [key: string]: string | number | null };
  onSubmit: (data: { [key: string]: string | number | null }) => void;
  onValidationChange: (isValid: boolean) => void;
}

const Form = ({
  formId,
  businessId,
  jsonSchema,
  valueForm,
  onSubmit,
  onValidationChange,
}: IJsonFormProps) => {
  const validationSchema = Yup.object().shape(
    Object.fromEntries(
      Object.entries(jsonSchema).map(([key, field]) => [
        key,
        field.type === 'string'
          ? Yup.string()
            .required(field.required ? `${field.title} is required` : undefined)
            .max(field.maxLength || Infinity, `${field.title} must be at most ${field.maxLength} characters`)
          : Yup.mixed(),
      ]),
    ),
  );

  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode:          'onChange',
    resolver:      yupResolver(validationSchema),
    defaultValues: valueForm,
  });

  useEffect(() => {
    onValidationChange(isValid);
  }, [isValid, onValidationChange]);

  const renderField = (key: string, field: IField) => {
    if (key === 'revenueReportTypeId') {
      return (
        <Controller
          key={ key }
          control={ control }
          name={ key }
          render={ ({ field: { onChange, value } }) => (
            <RevenueReportTypeField
              businessId={ businessId }
              label={ field.title }
              value={ value ? Number(value) : null }
              onChange={ (e) => onChange(e?.toString() || '') }
            />
          ) }
        />
      );
    }
    if (field.type === 'string') {
      return (
        <Controller
          key={ key }
          control={ control }
          name={ key }
          render={ ({ field: { onChange, value } }) => (
            <ComponentForm.TextField
              error={ errors[key]?.message }
              hideClear={ key === 'password' }
              label={ field.title }
              maxLength={ field.maxLength }
              placeholder={ `Enter ${field.title}` }
              readOnly={ field.readOnly }
              required={ field.required }
              type={ key === 'password' ? 'password' : 'text' }
              value={ value || '' }
              onChange={ (e) => onChange(e.target.value) }
            />
          ) }
        />
      );
    }
    if (field.type === 'date') {
      return (
        <Controller
          key={ key }
          control={ control }
          name={ key }
          render={ ({ field: { onChange, value } }) => (
            <ComponentForm.DateField
              error={ errors[key]?.message }
              label={ field.title }
              value={ value ? `${value}` : '' }
              onChange={ (date) => onChange(date) }
            />
          ) }
        />
      );
    }
    return null;
  };

  return (
    <ComponentForm id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      {Object.entries(jsonSchema).map(([key, field]) => renderField(key, field))}
    </ComponentForm>
  );
};

export default React.memo(Form);
