import React, { FC } from 'react';

import { SelectInput } from '@src/components/ui_v2/inputs';

import styles from '@src/components/reconciliation_center/all_transactions/styles.module.scss';

interface IOption {
  value: string;
  label: string;
}

interface SidebarSelectInputProps {
  label: string;
  isDisabled?: boolean;
  placeholder?: string;
  options: IOption[];
  value?: IOption;
  onChange?: (value: string) => void;
}

const SidebarSelectInput: FC<SidebarSelectInputProps> = ({
  label,
  isDisabled = false,
  placeholder = '',
  options,
  value,
  onChange,
}) => (
  <div className={ styles['sidebar-data'] }>
    <span className={ styles['select-label'] }>{label}</span>
    <SelectInput
      alwaysShowIndicator
      hideClear
      className={ styles['select-input'] }
      isDisabled={ isDisabled }
      options={ options }
      placeholder={ placeholder }
      value={ value }
      onChange={ (option) => onChange?.(option?.value as string) }
    />
  </div>
);

export default SidebarSelectInput;
