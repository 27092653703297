import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { TReviewedStatementSortColumn, IReviewedBankStatementsData } from '@src/types/bank_statements';
import { TID, TYear } from '@src/types/common';
import { ISorting } from '@src/types/sorting';
import { MONTHS } from '@src/utils/date_helpers';

import Table from '@src/components/ui/table';

import Item from './table_item';

import styles from '../styles.module.scss';

interface IBankStatementPendingReviewTableProps {
  businessId: TID,
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TReviewedStatementSortColumn>,
  reviewedBankStatements: IReviewedBankStatementsData[],
  months: number;
  year?: TYear,
  onCloseUploadModal: () => void,
}

const generateTableCells = (months: number) => {
  const cells = [];
  for (let i = 0; i < months; i += 1) {
    const monthName = MONTHS[i];
    cells.push(<Table.HCell className={ styles['reviewed-item'] }>{monthName}</Table.HCell>);
  }
  return cells;
};

const BankStatementPendingReviewTable = ({
  businessId,
  query,
  sorting,
  reviewedBankStatements,
  months,
  year,
  onCloseUploadModal,
}: IBankStatementPendingReviewTableProps) => {
  return (
    <Table
      className={ styles['bank-statements-table'] }
      infiniteQuery={ query }
      wrapperClassName="banking-transactions-table-wrapper-limited"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell<TReviewedStatementSortColumn>
            columnName="banking_account_name"
            sorting={ sorting }
            widthPercent={ 20 }
          >
            Banking Account
          </Table.HCell>
          {
            generateTableCells(months)
          }
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          reviewedBankStatements.map((reviewedBankStatement) => (
            <Item
              key={ reviewedBankStatement.id }
              businessId={ businessId }
              months={ months }
              reviewedBankStatement={ reviewedBankStatement }
              year={ year }
              onCloseUploadModal={ onCloseUploadModal }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(BankStatementPendingReviewTable);
