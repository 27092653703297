import React, { useCallback } from 'react';

import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { BillIcon } from '@src/components/utils/icomoon';

type TViewInvoiceActionProps = {
  document: IAchTransaction,
  onClick: (document: IAchTransaction) => void,
}

const ViewInvoiceAction = ({
  document,
  onClick,
}: TViewInvoiceActionProps) => {
  const handleSelect = useCallback(() => {
    onClick(document);
  }, [document, onClick]);

  return (
    <ActionsDropdown.Action
      icon={ <BillIcon fontSize={ 20 } /> }
      title="View Invoice Detail"
      onSelect={ handleSelect }
    />
  );
};

export default ViewInvoiceAction;
