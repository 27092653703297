import React, { useCallback, useMemo, useState } from 'react';

import { useGetDataSetsWithValues } from '@src/hooks/queries/dashboards/data_sets';
import { IBusinessSimple } from '@src/types/businesses';
import { TDate, TDateRange } from '@src/types/common';
import { IPortfolioDashboard } from '@src/types/portfolio';
import { API_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';

import {
  getDefaultDateRange,
  getRealDateRange,
} from '@src/components/portfolio_dashboard/details/widgets/charts/chart_helpers';
import Footer from '@src/components/portfolio_dashboard/details/widgets/full_view/full_view_footer';
import Header from '@src/components/portfolio_dashboard/details/widgets/full_view/full_view_header';
import { TWidgetViewCategory } from '@src/components/portfolio_dashboard/types';
import { ErrorNotification } from '@src/components/ui/notification';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import AggregateView from './aggregate_view';
import { TChartScale } from './charts/types';
import ComparisonView from './comparison_view';

import styles from '../../styles.module.scss';

interface IWidgetContainerProps {
  dashboard: IPortfolioDashboard,
  widgetId: string,
  currentView: TWidgetViewCategory,
  businesses: IBusinessSimple[],
  bookkeepingStartDate: TDate,
}

const WidgetContainer = ({
  dashboard,
  widgetId,
  currentView,
  businesses,
  bookkeepingStartDate,
}: IWidgetContainerProps): JSX.Element => {
  const widget = dashboard.widgetTemplates.find((item) => item.id === widgetId);

  const defaultRange = getDefaultDateRange(widget?.scale as TChartScale);
  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: formatDate(defaultRange.from, API_DATE_FORMAT),
    endDate:   formatDate(defaultRange.to, API_DATE_FORMAT),
  });

  const realRange = useMemo(() => {
    return getRealDateRange(
      dateRange,
      bookkeepingStartDate,
      widget?.scale as TChartScale,
    );
  }, [dateRange, widget?.scale, bookkeepingStartDate]);

  const query = useGetDataSetsWithValues({
    dashboardId: dashboard.id,
    widgetId,
    from:        realRange.startDate!,
    to:          realRange.endDate!,
  });

  const handleDateRange = useCallback((range: TDateRange) => {
    setDateRange(range);
  }, [setDateRange]);

  const dataSets = useMemo(() => {
    const businessIds = businesses.map((business) => business.id);
    return query.data?.dataSets.filter((dataSet) => businessIds.includes(dataSet.businessId)) || [];
  }, [query.data?.dataSets, businesses]);

  return (
    <DetailsRegion className={ styles['widget-container'] }>
      { query.isError && <ErrorNotification message="Data is not found." /> }
      <Header
        criticalNumber={ query.data?.meta.criticalNumber }
        dateRange={ dateRange }
        unit={ dataSets.length ? dataSets[0].unit : undefined }
        widgetDescription={ widget?.description }
        widgetName={ widget?.name }
        onChange={ handleDateRange }
      />
      {
        realRange.startDate && realRange.endDate && (
          <>
            {
              (currentView === 'aggregate')
                ? (
                  <AggregateView
                    businesses={ businesses }
                    chartType="bar"
                    dataSets={ dataSets }
                    dateRange={ realRange }
                    scale={ widget?.scale as TChartScale }
                  />
                )
                : (
                  <ComparisonView
                    businesses={ businesses }
                    chartType={ widget?.briefViewOption.chartType }
                    dataSets={ dataSets }
                    dateRange={ realRange }
                    scale={ widget?.scale as TChartScale }
                  />
                )
            }
            <Footer
              businesses={ businesses }
              dataSets={ dataSets }
            />
          </>
        )
      }
    </DetailsRegion>
  );
};

export default WidgetContainer;
