import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { useController, useForm } from 'react-hook-form';

import BusinessField from '@src/components/common_v2/form_fields/business_field';
import RevenueReportTypeField from '@src/components/common_v2/form_fields/revenue_report_type_field';
import Form from '@src/components/ui_v2/form';

import {
  IImportMappingValues,
  selectImportMappingValidation,
} from './schema';

interface IImportMappingProps {
  formId: string;
  onSubmit: (data: IImportMappingValues) => void;
  onValidationChange: (isValid: boolean) => void;
}

const ImportMappingForm = ({
  formId,
  onSubmit,
  onValidationChange,
}: IImportMappingProps) => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
  } = useForm<IImportMappingValues>({
    mode:          'onChange',
    resolver:      yupResolver(selectImportMappingValidation),
    defaultValues: {
      revenueReportTypeId: undefined,
      businessId:          undefined,
    },
  });

  const businessIdController = useController({ control, name: 'businessId' });
  const rrtControl = useController({ control, name: 'revenueReportTypeId' });

  const selectedBusinessId = watch('businessId');

  useEffect(() => {
    onValidationChange(isValid);
  }, [isValid, onValidationChange]);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <BusinessField
        error={ errors.businessId?.message }
        label="Business"
        placeholder="Select Business"
        { ...omit(businessIdController.field, 'ref') }
      />
      {selectedBusinessId && (
        <RevenueReportTypeField
          businessId={ selectedBusinessId }
          error={ errors.revenueReportTypeId?.message }
          label="Revenue Mapping"
          placeholder="Select Mapping"
          { ...omit(rrtControl.field, 'ref') }
        />
      )}
    </Form>
  );
};

export default React.memo(ImportMappingForm);
