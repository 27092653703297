import React, { memo } from 'react';

import classNames from 'classnames';

import { IRevenueProps } from '@src/types/common_operational_dashboard';

import LineGraph from '@src/components/operational_dashboard/components/custom_cells/line_graph';
import UndepositedRevenueTooltip from '@src/components/operational_dashboard/components/tooltips/undeposit_revenue_tooltip';
import {
  undepositedRevenueTooltipData,
} from '@src/components/operational_dashboard/helper';
import { progressUndepositedRevenueData } from '@src/components/operational_dashboard/helper/functional';
import {
  ICommonProps,
} from '@src/components/operational_dashboard/types';

import AuthWrapper from './auth_wrapper';
import HoverTooltipWrapper from './hover_tooltip_wrapper';
import ProcessPendingReviewData from './process_pending_review_data';
import { isObjectEmpty } from './utils';

import styles from './style.module.scss';

interface IRevenueCellProps extends IRevenueProps, ICommonProps {}

const RevenueCell = ({
  undepositedRevenue,
  pendingReview,
  preferences,
  setupStatus,
}: IRevenueCellProps) => {
  const classes = classNames(styles['business-column-container'], styles['expense-header-container']);
  return (
    <div className={ classes }>
      {undepositedRevenue
        && preferences?.revenue?.undepositedRevenue?.undepositedRevenueAmount && (
          <div className={ styles['header-item'] }>
            <AuthWrapper isObjectEmpty={ isObjectEmpty(undepositedRevenue) }>
              <HoverTooltipWrapper
                content={ (
                  undepositedRevenue.undepositedRevenueAmount && (
                  <UndepositedRevenueTooltip
                    cellData={ undepositedRevenue }
                    items={ undepositedRevenueTooltipData }
                    title=""
                  />
                  )
            ) }
              >
                <LineGraph
                  isCurrency
                  segments={ progressUndepositedRevenueData(undepositedRevenue) }
                  setupStatus={ setupStatus }
                />
              </HoverTooltipWrapper>
            </AuthWrapper>
          </div>
      )}
      {pendingReview
        && preferences?.revenue?.pendingReview
        && (
          <div className={ styles['header-item'] }>
            <AuthWrapper isObjectEmpty={ isObjectEmpty(pendingReview) }>
              <ProcessPendingReviewData
                pendingReviewProp={ pendingReview }
                preferences={ preferences }
                setupStatus={ setupStatus }
              />
            </AuthWrapper>
          </div>
        )}
    </div>
  );
};

export default memo(RevenueCell);
