import React, { useCallback, useMemo } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpdateDashboard } from '@src/hooks/queries/dashboards/dashboards';
import { useGetTemplates } from '@src/hooks/queries/dashboards/templates';
import { TMongoID } from '@src/types/common';
import { IDashboard } from '@src/types/dashboards/dashboards';
import { IWidget } from '@src/types/dashboards/widgets';

import Modal from '@src/components/ui/modal';
import ErrorNotification from '@src/components/ui/notification/error_notification';
import Spinner from '@src/components/ui/spinner';
import MutationStatus from '@src/components/utils/mutation_status';

import Form, { IEditTargetsFormValues } from './form';

const FORM_ID = 'edit_targets_form';

interface IEditTargetsModalProps extends Omit<IUseModalProps, 'onDone'> {
  dashboard: IDashboard;
  standardCategoryId?: number;
  onDone: (dashboard?: IDashboard) => void;
}

const EditTargetsModal = ({
  dashboard,
  standardCategoryId,
  isOpen,
  onCancel,
  onDone,
}: IEditTargetsModalProps) => {
  const query = useGetTemplates({
    standardCategoryId: standardCategoryId || -1,
  });

  const templates = useMemo(() => {
    return query.data?.templates || [];
  }, [query.data]);
  const widgets = useMemo(() => {
    return templates.map((template) => (
      {
        identifier: template.id,
        name:       template.name,
      } as unknown as IWidget
    ));
  }, [templates]);

  const update = useUpdateDashboard();

  const { mutate: updateBusinessDashboard } = update;

  const updateDashboard = useCallback((dashboardId: TMongoID, values: IEditTargetsFormValues) => {
    updateBusinessDashboard(
      {
        id:          dashboardId,
        targets:     values.targets,
      },
      {
        onSuccess: (data) => onDone(data.dashboard),
      },
    );
  }, [updateBusinessDashboard, onDone]);

  const handleSubmit = useCallback((values: IEditTargetsFormValues) => {
    updateDashboard(dashboard.id, values);
  }, [updateDashboard, dashboard]);

  return (
    <>
      { update.isLoading && <Spinner /> }
      { query.isError && <ErrorNotification message="No Templates loaded" /> }
      <MutationStatus mutation={ update } successMessage="Updated targets successfully." />
      <Modal.Form
        formId={ FORM_ID }
        proceedTitle="Save"
        show={ isOpen }
        title="Edit Targets"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <div className="create_dashboard_modal_container">
            <Form
              dashboard={ dashboard }
              formId={ formId }
              standardCategoryId={ standardCategoryId || 0 }
              widgets={ widgets }
              onSubmit={ handleSubmit }
            />
          </div>
        )}
      </Modal.Form>
    </>
  );
};

const useEditTargetsModal = makeUseModal(EditTargetsModal);

export {
  IEditTargetsModalProps,
  useEditTargetsModal,
  EditTargetsModal as default,
};
