import React from 'react';

import { IBusinessMetricValue } from '@src/types/business_metric_value';

import NumberInput from '@src/components/ui/form/inputs/number_input';

import styles from '../styles.module.scss';

interface EditableNumberInputProps {
  metricValue: IBusinessMetricValue;
  handleValueChange: (date: string) => (value: number) => void;
}

const EditableNumberInput: React.FC<EditableNumberInputProps> = ({ metricValue, handleValueChange }) => {
  const convertToNumericValue = (value: string | number | undefined): number | null => {
    if (value === undefined || value === null) return null;
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;
    return Number.isNaN(numericValue) ? null : numericValue;
  };

  const handleChange = (value: number | undefined) => {
    const finalValue = convertToNumericValue(value);
    if (finalValue !== null) {
      handleValueChange(metricValue.date)(finalValue);
    }
  };

  // Conditional rendering based on isEditMode

  return (
    <NumberInput
      autoComplete="off"
      className={ styles['item-value-input'] }
      value={ metricValue.value }
      onChange={ handleChange }
    />
  );
};

export default EditableNumberInput;
