import { TMongoID } from '@src/types/common';
import { IWidget } from '@src/types/dashboards';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

interface IGetPortfolioWidgetsParams {
  dashboardId: TMongoID,
}

interface IGetPortfolioWidgetsResponse {
  collection: IWidget[],
}

const getPortfolioWidgets = (
  params: IGetPortfolioWidgetsParams,
): Promise<IGetPortfolioWidgetsResponse> => {
  return apiGet(
    '/dashboards/api/v1/widgets',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      collection: cdata.widgets as IWidget[],
    };
  });
};

export {
  IGetPortfolioWidgetsParams,
  IGetPortfolioWidgetsResponse,
  getPortfolioWidgets,
};
