import React from 'react';

import ServiceMenu from '@src/components/common/service_menu';
import Root from '@src/components/root';
import ServiceSidebar from '@src/components/ui_v2/service_sidebar';

import FinancialAccountsSidebarMenuItems from './financial_accounts_sidebar_menu/financial_accounts_sidebar_menu_items';

interface IProps {
  businessId: number
}

const FinancialAccountsSidebarMenuWrapper = ({ businessId }: IProps) => {
  return (
    <Root>
      <ServiceSidebar
        serviceMenu={ (
          <ServiceMenu
            serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.FINANCIAL_ACCOUNTS }
          />
      ) }
      >
        <FinancialAccountsSidebarMenuItems businessId={ businessId } />
      </ServiceSidebar>
    </Root>
  );
};

export default FinancialAccountsSidebarMenuWrapper;
