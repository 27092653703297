import React, { useCallback } from 'react';

import classNames from 'classnames';

import { useGetBankingAccountsServiceInfo } from '@src/hooks/queries/banking_accounts_service_info';
import { accountsPath, bankConnectionCenterPath, bankingAccountsTrashPath, CSVImportPath, transactionsPath } from '@src/routes';
import { TID } from '@src/types/common';

import ServiceSidebar from '@src/components/ui_v2/service_sidebar';
import OpenInNewIcon from '@src/components/utils/icomoon/open_in_new';

import styles from './style.module.scss';

interface IFinancialAccountsSidebarMenuItemsProps {
businessId: TID
}

const FinancialAccountsSidebarMenuItems = ({ businessId }: IFinancialAccountsSidebarMenuItemsProps) => {
  const { data: bankingAccountsServiceInfo, isLoading: isLoadingBankingAccountsServiceInfo } = useGetBankingAccountsServiceInfo(businessId);

  const renderIssueBadge = useCallback((count: number) => {
    if (count > 0) {
      return (
        <span className={ classNames(styles['issue-badge'], 'badge progress-bar-danger') }>
          {`${count} Issue${count > 1 ? 's' : ''}`}
        </span>
      );
    }
    return null;
  }, []);

  if (isLoadingBankingAccountsServiceInfo) {
    return null;
  }

  const aggregatorInactivatedPaymentAccountsCount = bankingAccountsServiceInfo?.reconciliationService?.aggregatorInactivatedPaymentAccountsCount || 0;

  const totalAccounts = (bankingAccountsServiceInfo?.reconciliationService?.unarchivedUnconnectedAccessiblePaymentAccountsCount || 0) + (bankingAccountsServiceInfo?.reconciliationService?.unarchivedAccessiblePaymentAccountsCount || 0);

  const isAdmin = bankingAccountsServiceInfo?.reconciliationService?.userRole?.role === 'ADMIN';

  return (
    <>
      {isAdmin && (
      <ServiceSidebar.Item
        href={ accountsPath(businessId) }
        itemCount={ String(totalAccounts) }
        subTitle={ (
          <div className={ styles['nav-item-sub-title'] }>
            {renderIssueBadge(aggregatorInactivatedPaymentAccountsCount)}
          </div>
        ) }
        title="All Accounts"
      />
      )}
      <ServiceSidebar.Item
        href={ transactionsPath(businessId) }
        itemCount={ String(bankingAccountsServiceInfo?.reconciliationService?.transactionsCount) }
        title="All Transactions"
      />
      <ServiceSidebar.Separator />
      {isAdmin && (
      <>
        <ServiceSidebar.Item
          href={ CSVImportPath(businessId) }
          itemCount={ String(bankingAccountsServiceInfo?.reconciliationService?.pendingTransactionImportsCount) }
          title="CSV Import"
        />
        <ServiceSidebar.Item
          href={ bankingAccountsTrashPath(businessId) }
          itemCount={ String(bankingAccountsServiceInfo?.reconciliationService?.deletedTransactionsCount) }
          title="Trash"
        />
      </>
      )}
      <ServiceSidebar.Separator />
      <ServiceSidebar.Item
        href={ bankConnectionCenterPath(businessId) }
        suffixIcon={ <OpenInNewIcon fontSize={ 18 } /> }
        title="Bank Connection Center"
      />
    </>
  );
};

export default FinancialAccountsSidebarMenuItems;
