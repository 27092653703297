import React from 'react';

import Modal from '@src/components/ui/modal';

interface IBaseMappingModalProps {
  isOpen: boolean;
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  footerContent: React.ReactNode;
  manageEmployeeCardLinkContent?: React.ReactNode;
}

const BaseEmployeeCardMappingModal: React.FC<IBaseMappingModalProps> = ({
  isOpen,
  title,
  subtitle,
  children,
  footerContent,
  manageEmployeeCardLinkContent,
}) => {
  return (
    <Modal
      className="employee-card-mapping-modal"
      show={ isOpen }
      title={ title }
    >
      <Modal.Body>
        <div className="mapping-modal-content">
          {subtitle && (
            <div className="mapping-modal-subtitle">
              {subtitle}
            </div>
          )}
          {children}
          <div className="mapping-description">
            Some employee cards from bank may be missing if no transactions have been recorded for them.
          </div>
          { manageEmployeeCardLinkContent }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="mapping-modal-footer">
          {footerContent}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default BaseEmployeeCardMappingModal;
