import React, { useCallback } from 'react';

import { useUpdateDashboardUsers } from '@src/hooks/queries/dashboards/dashboard_users';
import { IBusinessUser } from '@src/types/business_users';
import { IBusinessSimple } from '@src/types/businesses';
import { IPortfolioDashboard } from '@src/types/portfolio';

import NoPermissionView from '@src/components/portfolio_dashboard/dashboard/dashboard_no_permission_view';

import ContainerWrapper from './details_container_wrapper';
import { Header } from './header';

import styles from '../styles.module.scss';

interface IDashboardDetailsProps {
  dashboard: IPortfolioDashboard,
  businesses: IBusinessSimple[],
  widgetId?: string,
}

const DashboardDetails = ({
  dashboard,
  businesses,
  widgetId,
}: IDashboardDetailsProps): JSX.Element => {
  const updateUsers = useUpdateDashboardUsers();

  const { mutate: updateDashboardUsers } = updateUsers;

  const handleShareDone = useCallback((accessibleUsers: IBusinessUser[]) => {
    updateDashboardUsers(
      {
        dashboardId: dashboard.id,
        userIds:     accessibleUsers.map((user) => user.id),
      },
      {
        onSuccess: () => {
          dashboard.dashboardUserIds = accessibleUsers.map((user) => user.id);
        },
      },
    );
  }, [dashboard, updateDashboardUsers]);

  return (
    <div className={ styles.details }>
      <Header showToggleView={ !widgetId } />
      {
        dashboard.hasAccess
          ? (
            <ContainerWrapper
              businesses={ businesses }
              dashboard={ dashboard }
              widgetId={ widgetId }
            />
          )
          : (
            <NoPermissionView
              currentDashboard={ dashboard }
              isLoading={ updateUsers.isLoading }
              onShareDone={ handleShareDone }
            />
          )
      }
    </div>
  );
};

export default DashboardDetails;
