import { TID } from '@src/types/common';
import { IStandardDocument } from '@src/types/standard_documents';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

const getStandardDocuments = (
  businessId: TID,
  ownDocuments: boolean = false,
): Promise<IStandardDocument[]> => {
  return apiGet(
    '/api/web/v1/standard_documents',
    underscoreKeys({ businessId, ownDocuments: Boolean(ownDocuments) }),
  ).then((data) => camelizeKeys(data.standard_documents) as IStandardDocument[]);
};

const getStandardDocumentsByPerson = (
  personId: TID,
  personType: string,
): Promise<IStandardDocument[]> => {
  return apiGet(
    '/api/web/v1/standard_documents',
    underscoreKeys({ personId, personType }),
  ).then((data) => camelizeKeys(data.standard_documents) as IStandardDocument[]);
};

export {
  getStandardDocuments,
  getStandardDocumentsByPerson,
};
