import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { getFormattedDueDate } from '../utils';

interface IInvoiceDueDateProps {
  accountsPayableServiceDocument: IAccountsPayableServiceDocument;
}

const InvoiceDueDate = ({ accountsPayableServiceDocument }: IInvoiceDueDateProps) => {
  const formattedDate = getFormattedDueDate(accountsPayableServiceDocument.invoiceDueDate);
  if (!formattedDate.dueIn) return null;

  return (
    <>
      <div className={ `text-nowrap ${formattedDate.isOverDue ? 'in-red-400' : ''}` }>
        { formattedDate.dueIn }
        { formattedDate.isOverDue && (
          <span> overdue</span>
        ) }
      </div>
    </>
  );
};

export default React.memo(InvoiceDueDate);
