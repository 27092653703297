import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { getReceiptServiceByBusinessId } from '@src/requests/receipt_services';
import { TID } from '@src/types/common';
import { IReceiptService } from '@src/types/receipt_service';

type TReceiptServiceResponse = IReceiptService 

const useGetReceiptServiceByBusinessId = (businessId: TID) => {
  return useQuery<TReceiptServiceResponse, Error & AxiosError>(
    [QueryKey.receiptService, businessId],
    () => getReceiptServiceByBusinessId({ businessId }),
    {
      enabled: !!businessId,
    },
  );
};

export {
  useGetReceiptServiceByBusinessId,
};
