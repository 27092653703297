import React, { useCallback, useMemo, useRef, useState } from 'react';

import sumBy from 'lodash/sumBy';

import { useReportServiceBudgetContext } from '@src/hooks/contexts/budget_context';
import {
  useCalculateReportServiceBudgetItem, useShowPerMonthItem,
} from '@src/hooks/queries/report_service/report_service_budget_items';
import { IReportServiceBudgetFormula } from '@src/types/report_service/report_service_budget_formulas';
import { IReportServiceBudgetItem, TItemChangeCallback } from '@src/types/report_service/report_service_budget_items';
import { MONTHS } from '@src/utils/date_helpers';

import { Button } from '@src/components/ui/buttons';
import CustomSelect from '@src/components/ui/form/inputs/custom_select';
import Table from '@src/components/ui/table';
import MutationStatus from '@src/components/utils/mutation_status';

import { usePercentageChartModal, useValuePerMonthModel } from '../modal';
import BudgetInputCell from './budget_input_cell';
import FormulaValueCell from './formula_value_cell';

import styles from './styles.module.scss';

interface IBudgetsDetailItemProps {
  item: IReportServiceBudgetItem,
  budgetFormulas: IReportServiceBudgetFormula[],
  formulaEnabled: string | boolean | undefined,
  onChange: TItemChangeCallback,
  onSetDraft: (value: boolean) => void,
  chartOfAccount: any,
  isSelectDropOpen:boolean,
  setIsSelectDropOpen:(value: string) => void,
  decimalScaleValue: number,
}

const BudgetsDetailItem = ({
  item,
  budgetFormulas,
  formulaEnabled,
  onChange,
  onSetDraft,
  chartOfAccount,
  isSelectDropOpen,
  setIsSelectDropOpen,
  decimalScaleValue,
}: IBudgetsDetailItemProps): JSX.Element => {
  const PercentageChartModal = usePercentageChartModal();
  const ValuePerMonthModel = useValuePerMonthModel();
  const [coaForMonth, setCoaForMonth] = useState<boolean>(false);
  const [openChartOfAccount, setOpenChartOfAccount] = useState<boolean>(false);
  const [openValuePerMonth, setOpenValuePerMonth] = useState<boolean>(false);
  const [valuePerMonth, setValuePerMonth] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | null | undefined>('');
  const [coaValue, setCoaValue] = useState<string | null>(null);
  const [showBudgetItemMonthlyValue, setShowBudgetItemMonthlyValue] = useState<any>({});
  const selectedCoa = useRef(null);
  const [currentFormula, setCurrentFormula] = useState<IReportServiceBudgetFormula>(
    budgetFormulas.find((b) => b.id === item.formulaId) || budgetFormulas.find((b) => b.id === 'custom') || budgetFormulas[0],
  );
  const ShowPerMonthItem = useShowPerMonthItem();
  const { mutate: showPerMonthBudgetItem } = ShowPerMonthItem;

  const formulaOptions = useMemo(() => {
    const options = (section: string | null = null) => {
      const budgetOptions = budgetFormulas.map((formula) => {
        if (section === formula.section) {
          return {
            label: formula.name,
            value: String(formula.id),
            section,
          };
        }
        return '';
      });
      return budgetOptions.filter((element) => element !== '');
    };

    return [
      {
        options: options(),
      },
      {
        label:   'YEAR',
        options: options('YEAR'),
      },
      {
        label:   'MONTH',
        options: options('MONTH'),
      },
    ];
  }, [budgetFormulas]);

  const budget = useReportServiceBudgetContext();
  const calculator = useCalculateReportServiceBudgetItem();
  const { mutate } = calculator;

  const handleChangeFormula = useCallback((option, coa = null) => {
    const value = option?.value;
    selectedCoa.current = coa?.id;

    if ((value === 'chart_of_account' || value === 'chart_of_account_for_month') && !coa?.id) {
      setSelectedItem(item.displayName);
      setOpenChartOfAccount(true);
      if (option.section === 'MONTH') {
        setCoaForMonth(true);
      } else {
        setCoaForMonth(false);
      }
      return;
    }

    if (option.section === 'MONTH') {
      setSelectedItem(item.displayName);
      setOpenChartOfAccount(false);
      setValuePerMonth(true);
    }

    if (option.section !== 'MONTH' || !option.section) {
      setValuePerMonth(false);
    }
    setCoaValue((value === 'chart_of_account' || value === 'chart_of_account_for_month') ? coa?.displayName : null);
    if (value === String(currentFormula?.id)) return;

    const newFormula = budgetFormulas.find((b) => String(b.id) === value);
    setCurrentFormula(newFormula!);
    mutate(
      {
        id:                      item.id,
        budgetId:                budget.id,
        formulaId:               newFormula!.id,
        formulaInput:            0.0,
        formulaChartOfAccountId: coa?.id,
      },
      {
        onSuccess: () => onSetDraft(true),
      },
    );
    setIsSelectDropOpen("");
  }, [currentFormula, setCurrentFormula, budgetFormulas, mutate, budget, item, onSetDraft]);

  const handleMenuOpen = (value: any) => {
    setIsSelectDropOpen(value);
  };
  const handleMenuClose = (value: any) => {
    setIsSelectDropOpen(value);
  };

  const handleChangeFormulaValue = useCallback((value: number) => {
    item.formulaValue = value;
    mutate(
      {
        id:                      item.id,
        budgetId:                budget.id,
        formulaId:               currentFormula.id,
        formulaInput:            value,
        formulaChartOfAccountId: item.formulaChartOfAccountId,
      },
      {
        onSuccess: () => onSetDraft(true),
      },
    );
  }, [currentFormula, item, mutate, onSetDraft, budget]);

  const currentFormulaId = (currentFormulaValue: IReportServiceBudgetFormula) => {
    return coaValue ? `% of ${coaValue}` : String(currentFormulaValue.name).replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
  };

  const coaDefaultValue = () => {
    const coaObject = chartOfAccount.find((coa: any) => coa.id === item.formulaChartOfAccountId);
    return coaObject ? `% of ${coaObject.displayName}` : currentFormulaId(currentFormula);
  };

  const renderValue = () => {
    return coaValue ? currentFormulaId(currentFormula) : coaDefaultValue();
  };

  const onEdit = async () => {
    setShowBudgetItemMonthlyValue({});
    await showPerMonthBudgetItem(
      {
        budgetId:     budget.id,
        budgetItemId: item.id,
      },
      {
        onSuccess: (response: any) => {
          setShowBudgetItemMonthlyValue(response.budgetItem);
        },
      },
    );

    if (!selectedCoa.current) {
      const selectedCoaObject = chartOfAccount.find(
        (coa: any) => coa.id === item.formulaChartOfAccountId,
      );
      selectedCoa.current = selectedCoaObject?.id;
    }

    setOpenValuePerMonth(true);
  };

  return (
    <>
      <MutationStatus mutation={ calculator } />
      <Table.Row>
        <Table.Cell style={ { paddingLeft: 20 } }>
          {item.displayName}
        </Table.Cell>
        <Table.Cell>
          {item.accTypeName}
        </Table.Cell>
        <Table.Cell className="table-divider">
          {item.accountingClassName}
        </Table.Cell>
        {
          formulaEnabled
          && (
          <Table.Cell className="table-divider p-l-10 relative">
            <CustomSelect
              handleMenuClose={ handleMenuClose }
              handleMenuOpen={ handleMenuOpen }
              isMenuOpenCheck={ isSelectDropOpen }
              options={ formulaOptions }
              renderValue={ renderValue }
              uniqueId={ item?.id }
              onChange={ (option: any) => {
                handleChangeFormula(option);
              } }
            />
          </Table.Cell>
          )
        }
        {
          (valuePerMonth
          && (
            <Table.Cell className="table-divider">
              <Button
                className={ styles['modal-edit-btn'] }
                fontSize={ 16 }
                title="Edit"
                onClick={ onEdit }
              />

            </Table.Cell>
          ))
          || formulaEnabled && (
          <FormulaValueCell
            currentFormula={ currentFormula }
            decimalScaleValue={ decimalScaleValue }
            initValue={ item.formulaValue || 0 }
            onChange={ handleChangeFormulaValue }
            onEdit={ onEdit }
          />
          )
        }
        <Table.CurrencyAmountCell
          decimalScaleValue={ decimalScaleValue }
          value={ sumBy(item.budgetItemValues, (value) => value.value) }
        />
        {
          MONTHS.map((month, index) => (
            <BudgetInputCell
              key={ month }
              currentFormula={ currentFormula }
              decimalScaleValue={ decimalScaleValue }
              formulaEnabled={ formulaEnabled }
              item={ item }
              month={ index }
              onChange={ onChange }
              onSetDraft={ onSetDraft }
            />
          ))
        }
      </Table.Row>

      { openChartOfAccount
        && (
          <PercentageChartModal.Component
            chartOfAccount={ chartOfAccount }
            coaForMonth={ coaForMonth }
            handleChangeFormula={ handleChangeFormula }
            selectedItem={ selectedItem }
            setOpenChartOfAccount={ setOpenChartOfAccount }
          />
        )}

      {openValuePerMonth && Object.keys(showBudgetItemMonthlyValue).length > 0
        && (
        <ValuePerMonthModel.Component
          MONTHS={ MONTHS }
          budget={ budget }
          currentFormula={ currentFormula }
          item={ item }
          selectedCoa={ selectedCoa }
          selectedItem={ selectedItem }
          setOpenValuePerMonth={ setOpenValuePerMonth }
          showBudgetItemMonthlyValue={ showBudgetItemMonthlyValue }
        />
        )}
    </>
  );
};

export default BudgetsDetailItem;
