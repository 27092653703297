import React from 'react';

import classNames from 'classnames';

import { IWidget } from '@src/types/dashboards';

import Table from '@src/components/ui_v2/table';

interface ITableHeaderProps {
  widgets: IWidget[];
}

const TableHeader = ({
  widgets,
}: ITableHeaderProps): JSX.Element => {
  const classes = classNames(`header-cell-${widgets.length}`);

  return (
    <Table.Head>
      <Table.Row>
        <Table.EmptyHCell className="line-item-cell">
          Business
        </Table.EmptyHCell>
        {widgets.map((widget: IWidget) => (
          <Table.HCell key={ widget.id } className={ classes }>
            {widget.name}
          </Table.HCell>
        ))}
      </Table.Row>
    </Table.Head>
  );
};

export default TableHeader;
