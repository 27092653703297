module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="table-responsive request-documents-table-container">\n  <table>\n    <colgroup span="3">\n      <col width="40%"></col>\n      <col></col>\n      <col width="50px"></col>\n    </colgroup>\n    <thead>\n      <tr>\n        <th class="font-14 font-bold">Document ID</th>\n        <th class="font-14 font-bold">Name</th>\n        <th width="50px"></th>\n      </tr>\n    </thead>\n    <tbody>\n    </tbody>\n  </table>\n</div>\n');
    
      if (this.document_request_service_role === 'REVIEWER') {
        __out.push('\n<div class="drop-zone-section">\n');
        if (this.state === 'opened') {
          __out.push('\n  <div class="document-drop-zone">\n    <a class="font-semi-bold pointer add-new-document-js">Add New Document</a>\n    <input type="file" multiple="" class="input-new-document" hidden>\n    <p class="m-t-5 m-b-0 in-grey-1050">Drag and drop one or multiple files from your computer</p>\n  </div>\n  <div class="m-t-18 text-center">\n    ');
          if (!this.is_bank_statement_request && !this.is_balance_sheet_statement_request) {
            __out.push('\n      <a href="/businesses/');
            __out.push(__sanitize(this.business_id));
            __out.push('/business_inbox" class="font-semi-bold pointer">\n        <span class="icon icon-inbox m-r-10"></span> Add Document from Business Mailroom\n      </a>\n    ');
          }
          __out.push('\n  </div>\n');
        }
        __out.push('\n</div>\n');
        if (this.state !== 'closed' && (!this.is_last || this.reviewBtnText)) {
          __out.push('\n<div class="actions-section">\n  ');
          if (!this.is_last) {
            __out.push('\n  <a class="font-semi-bold pointer m-r-68 skip-js">Skip</a>\n  ');
          }
          __out.push('\n  ');
          if (this.reviewBtnText && this.visibleReviewBtn) {
            __out.push('\n  <button class="btn btn-info font-semi-bold flex-1 review-next-js">');
            __out.push(__sanitize(this.reviewBtnText));
            __out.push('</button>\n  ');
          }
          __out.push('\n</div>\n');
        }
        __out.push('\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}