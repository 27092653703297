import { TBankingAccountsService } from '@src/types/banking_accounts_service_info';
import { camelizeKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

const getBankingAccountsServiceInfo = (businessId: number): Promise<TBankingAccountsService> => {
  return apiGet(
    `/api/v1/banking_accounts_services/by_business_id?business_id=${businessId}`,
  ).then((data) => camelizeKeys(data) as TBankingAccountsService);
};

export {
  getBankingAccountsServiceInfo,
};
