import React from 'react';

import { TID } from '@src/types/common';

import { DisconnectedBankAccountIcon, DisconnectedGeneralLedgerIcon, DisconnectedRevenueIntegrationIcon,
  DuplicateVendorIcon, LedgerConnectionIssueIcon } from '@src/components/utils/icomoon';
import CoaSyncErrorIcon from '@src/components/utils/icomoon/od_coa_sync_errors';

import { IFilterPreference, IIconConfig } from '../types';

// Have an optional parameter if the view part is need to be catered in future scope
export const filterMapper = (view?: string): IFilterPreference[] => [
  {
    heading:    'Expense',
    headingKey: 'expense',
    child:      [
      {
        heading:       'Bill Pay',
        headingKey:    'billPay',
        isCommingSoon: false,
        elements:      [
          {
            title: 'Uploaded Invoices',
            description:
                'Quick link to view invoices that have been uploaded but not verified',
            name:     'uploadedInvoices',
            disabled: !(view === 'accountant' || view === 'businessOwner'),
          },
          {
            title:       'Approved Invoices',
            description: 'Quick link to view invoices that need approval',
            name:        'approvedInvoices',
            disabled:    !(view === 'accountant' || view === 'businessOwner'),
          },
          {
            title: 'Ready to Pay Invoices',
            description:
                'Quick link to view unpaid invoices that are ready to pay',
            name:     'readyToPayInvoices',
            disabled: !(view === 'accountant' || view === 'businessOwner'),
          },
        ],
      },
      {
        heading:       'Corporate Cards',
        headingKey:    'corporateCards',
        isCommingSoon: false,
        elements:      [
          {
            title: 'Unapproved Receipts',
            description:
                'Quick link to view accounts receivable outstanding...',
            name:     'unapprovedReceipts',
            disabled: false,
          },
        ],
      },
      {
        heading:       'Expense Reports',
        headingKey:    'expenseReports',
        isCommingSoon: false,
        elements:      [
          {
            title:       'Employee Reimbursements',
            description: 'Quick link to view...',
            name:        'employeeReimbursements',
            disabled:    !(view === 'accountant' || view === 'businessOwner'),
          },
          {
            title:       'My Reimbursements',
            description: 'Quick link to view...',
            name:        'myReimbursements',
            disabled:    !(view === 'businessOwner'),
          },
        ],
      },
    ],
  },
  {
    heading:    'Revenue',
    headingKey: 'revenue',
    child:      [
      {
        heading:       'Undeposited Revenue',
        headingKey:    'undepositedRevenue',
        isCommingSoon: true,
        elements:      [
          {
            title:       'Undeposited Revenue Amount',
            description: 'Quick link to view',
            name:        'undepositedRevenueAmount',
            disabled:    !(view === 'accountant' || view === 'businessOwner'),
          },
        ],
      },
      {
        heading:       'Pending Review',
        headingKey:    'pendingReview',
        isCommingSoon: true,
        elements:      [
          {
            title: 'Missing Revenue Reports',
            description:
                'This will be a count of the number of days that revenue hasn’t arrived for.',
            name:     'missingRevenueReports',
            disabled: !(view === 'accountant' || view === 'businessOwner'),
          },
          {
            title:       'Unbalanced Reports',
            description: 'Quick link to view',
            name:        'unbalancedReports',
            disabled:    !(view === 'accountant' || view === 'businessOwner'),
          },
        ],
      },
    ],
  },
  {
    heading:    'Continuous Reconciliation',
    headingKey: 'continuousReconciliation',
    child:      [
      {
        heading:       '',
        headingKey:    '',
        isCommingSoon: false,
        elements:      [
          {
            title:       'Transactions For Review',
            description: 'Quick link to view',
            name:        'uncategorizedTransactions',
            disabled:    !(view === 'accountant'),
          },
          {
            title:       'Uncleared Documents',
            description: 'Quick link to view',
            name:        'unclearedDocuments',
            disabled:    !(view === 'accountant'),
          },
        ],
      },
    ],
  },
  {
    heading:    'Month End Closing',
    headingKey: 'monthEndClosing',
    child:      [
      {
        heading:       '',
        headingKey:    '',
        isCommingSoon: false,
        elements:      [
          {
            title:       'Missing Bank Statements',
            description: 'Quick link to view',
            name:        'missingBankStatements',
            disabled:    !(view === 'accountant'),
          },
          {
            title:       'Audit Scan',
            description: 'Quick link to view',
            name:        'auditScan',
            disabled:    !(view === 'accountant'),
          },
          {
            title:       'Last Close Date',
            description: 'Quick link to view',
            name:        'lastCloseDate',
            disabled:    !(view === 'accountant'),
          },
          {
            title:       'Notes',
            description: 'Quick link to view',
            name:        'notes',
            disabled:    !(view === 'accountant'),
          },
        ],
      },
    ],
  },
  {
    heading:    'Collaboration',
    headingKey: 'collaboration',
    child:      [
      {
        heading:       'Flagged Items',
        headingKey:    'flaggedItems',
        isCommingSoon: false,
        elements:      [
          {
            title:       'Flagged Invoices',
            description: 'Quick link to view',
            name:        'flaggedInvoices',
            disabled:    false,
          },
          {
            title:       'Flagged Receipts',
            description: 'Quick link to view',
            name:        'flaggedReceipts',
            disabled:    false,
          },
          {
            title:       'Flagged Transactions',
            description: 'Quick link to view',
            name:        'flaggedTransactions',
            disabled:    false,
          },
        ],
      },
      {
        heading:       'Document Requests',
        headingKey:    'documentRequests',
        isCommingSoon: false,
        elements:      [
          {
            title:       'Reviewed',
            description: 'Quick link to view',
            name:        'reviewed',
            disabled:    false,
          },
          {
            title:       'Open',
            description: 'Quick link to view',
            name:        'open',
            disabled:    false,
          },
        ],
      },
      {
        heading:       'Unread Messages',
        headingKey:    'unreadMessages',
        isCommingSoon: false,
        elements:      [
          {
            title:       'Unread Messages Count',
            description: 'Quick link to view',
            name:        'unreadMessagesCount',
            disabled:    false,
          },
        ],
      },
    ],
  },
  {
    heading:    'Mailroom',
    headingKey: 'mailroom',
    child:      [
      {
        heading:       '',
        headingKey:    '',
        isCommingSoon: false,
        elements:      [
          {
            title:       'Unreviewed Documents',
            description: 'Quick link to view',
            name:        'unreviewedDocuments',
            disabled:    !(view === 'accountant'),
          },
        ],
      },
    ],
  },
];

export const expenseKeyToStringMap = {
  billPay:        'Bill Pay',
  corporateCards: 'Corporate Cards',
  expenseReports: 'Expense Reports',
};

export const revenueKeyToStringMap = {
  undepositedRevenue: 'Undeposited Revenue',
  pendingReview:      'Pending Review',
};

export const reconciliationKeyToStringMap = {
  uncategorizedTransactions: 'Transactions For Review',
  unclearedDocuments:        'Uncleared Documents',
};

export const monthEndCloseKeyToStringMap = {
  missingBankStatements: 'Missing Bank Statements',
  auditScan:             'Audit Scan',
  lastCloseDate:         'Last Close Date',
  notes:                 'Notes',
};

export const collaborationKeyToStringMap = {
  flaggedItems:     'Flagged Items',
  documentRequests: 'Document Requests',
  unreadMessages:   'Unread Messages',
};

export const billPayColors: ColorMapping = {
  uploadedInvoices:   '#8a2be2',
  approvedInvoices:   '#00bfff',
  readyToPayInvoices: '#20b2aa',
};

interface ColorMapping {
  [key: string]: string;
}

export const flaggedItemsColors: ColorMapping = {
  flaggedInvoices:     '#8a2be2',
  flaggedReceipts:     '#00bfff',
  flaggedTransactions: '#20b2aa',
};

export const documentRequestsColors: ColorMapping = {
  open:     '#FFB648',
  reviewed: '#00B887',
};

export const pieChartConstants = {
  startAngle:  90,
  endAngle:    452,
  innerRadius: 12,
  outerRadius: 15,
};

export const pieChartColors = ['#0088FE', '#00C49F'];
export const availableViews = {
  businessOwnerView: 'business',
  accountant:        'accountant',
};
export const iconSize = 16;

export const DASHBOARD_ICONS: IIconConfig[] = [
  {
    key:       'disconnected-ledger',
    icon:      <DisconnectedGeneralLedgerIcon fontSize={ iconSize } />,
    hoverText: 'Disconnected General Ledger',
  },
  {
    key:       'disconnected-revenue',
    icon:      <DisconnectedRevenueIntegrationIcon fontSize={ iconSize } />,
    hoverText: 'Disconnected Revenue Integrations',
  },
  {
    key:       'disconnected-bank',
    icon:      <DisconnectedBankAccountIcon fontSize={ iconSize } />,
    hoverText: 'Disconnected Bank Accounts',
  },
  {
    key:       'ledger-issues',
    icon:      <LedgerConnectionIssueIcon fontSize={ iconSize } />,
    hoverText: 'General Ledger Sync Issues',
  },
  {
    key:       'duplicate-vendor',
    icon:      <DuplicateVendorIcon fontSize={ iconSize } />,
    hoverText: 'Duplicate Vendors',
  },
  {
    key:       'chart-of-account-sync-errors',
    icon:      <CoaSyncErrorIcon fontSize={ iconSize } />,
    hoverText: 'COA Sync Errors',
  },
];

export const iconSizeTooltip = 13;
const defaultUrl = '/operational_dashboard';
export const GeneralLedgerDisconnectedTooltipData = {
  id:          '2002',
  heading:     'General Ledger Disconnected',
  actionLabel: 'Review',
  actionLink:  (businessId?: TID): string => {
    return businessId
      ? `/setup-business/${businessId}?step=2`
      : defaultUrl;
  },
  key:       'generalLedgerDisconnected',
  titleIcon: <DisconnectedGeneralLedgerIcon fontSize={ iconSizeTooltip } inColor="red-400" />,
};
export const DuplicateVendorsTooltipData = {
  id:          '2003',
  heading:     'Duplicate Vendors',
  actionLabel: 'Review',
  actionLink:  (businessId?: TID): string => {
    return businessId
      ? `/businesses/${businessId}/vendor_service?merge_available=true`
      : defaultUrl;
  },
  key:       'duplicateVendors',
  titleIcon: <DuplicateVendorIcon fontSize={ iconSizeTooltip } inColor="orange-500" />,
};
export const GeneralLedgerConnectionIssuesTooltipData = {
  id:          '2004',
  heading:     'General Ledger Sync Issues',
  actionLabel: 'Review',
  actionLink:  (businessId?: TID): string => {
    return businessId
      ? `/businesses/${businessId}/reconciliation_center/ledger_syncing_centre`
      : defaultUrl;
  },
  key:       'quickBookSyncIssue',
  titleIcon: <LedgerConnectionIssueIcon fontSize={ iconSizeTooltip } inColor="orange-500" />,
};
export const RevenueIntegrationsTooltipData = {
  id:          '2005',
  heading:     'Revenue Integrations Disconnected',
  actionLabel: 'Review',
  actionLink:  (businessId?: TID): string => {
    return businessId
      ? `/settings/integrations/${businessId}/external_systems`
      : defaultUrl;
  },
  key:       'revenueIntegrations',
  titleIcon: <DisconnectedRevenueIntegrationIcon fontSize={ iconSizeTooltip } inColor="red-400" />,
};
export const DisconnectedBankAccountsTooltipData = {
  id:          '2006',
  heading:     'Bank Accounts Disconnected',
  actionLabel: 'Review',
  actionLink:  () => '/financial_institution_connection_center ',
  key:         'disconnectedBankAccounts',
  titleIcon:   <DisconnectedBankAccountIcon fontSize={ iconSizeTooltip } inColor="red-400" />,
};
export const CoaSyncErrorsData = {
  id:          '2007',
  heading:     'COA Sync Errors',
  actionLabel: 'Review',
  actionLink:  (businessId?: TID): string => {
    return businessId
      ? `/businesses/${businessId}/reconciliation_center/accounts?type=categories`
      : defaultUrl;
  },
  key:       'disconnectedBankAccounts',
  titleIcon: <CoaSyncErrorIcon fontSize={ iconSizeTooltip } inColor="orange-500" />,
};
