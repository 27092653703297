import React, { useMemo } from 'react';

import classNames from 'classnames';

import { currencyLocaleFormatter, localeFormatter } from '@src/utils/currency';

interface ICriticalNumberTabularViewProps {
  className?: string,
  criticalNumber: number,
  unit?: string,
}

const CriticalNumberTabularView = ({
  className,
  criticalNumber,
  unit,
}: ICriticalNumberTabularViewProps) => {
  const classes = classNames(className);

  const resultNumber = useMemo(() => {
    switch (unit) {
      case window.Docyt.Common.Constants.DASHBOARD_DATASET_UNIT.DOLLAR:
        return currencyLocaleFormatter(criticalNumber);
      case window.Docyt.Common.Constants.DASHBOARD_DATASET_UNIT.PERCENT:
        return `${localeFormatter((criticalNumber / 100), 'percent')}`;
      case window.Docyt.Common.Constants.DASHBOARD_DATASET_UNIT.COUNT:
        return `${localeFormatter(criticalNumber)}`;
      default:
        return '';
    }
  }, [unit, criticalNumber]);

  const isDollar = resultNumber.indexOf('$') !== -1;
  const formattedNumber = isDollar ? resultNumber.replace('$', '') : resultNumber;

  return (
    <>
      { isDollar ? <span>$</span> : <span /> }
      <span className={ classes }>
        { formattedNumber }
      </span>
    </>
  );
};

export default CriticalNumberTabularView;
