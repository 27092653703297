import React, { useState, useMemo } from 'react';

import debounce from 'lodash/debounce';

import { AmountInput } from '@src/components/ui_v2/inputs';

import styles from '@src/components/reconciliation_center/all_transactions/styles.module.scss';

interface Props {
  label?: string;
  isDisabled?: boolean;
  initialValue: string | null;
  onChange: (value: string) => void;
}

const AmountField = ({
  label = 'Amount',
  isDisabled = false,
  initialValue,
  onChange,
}: Props) => {
  const [tempAmount, setTempAmount] = useState(initialValue);

  const debouncedOnChange = useMemo(
    () => debounce((newVal: string | null) => {
      onChange(newVal || '0');
    }, 800),
    [onChange],
  );

  const handleAmountChange = (val: string | null) => {
    setTempAmount(val);
    debouncedOnChange(val);
  };

  return (
    <div className={ styles['sidebar-data'] }>
      <span className={ styles['select-label'] }>{label}</span>
      <AmountInput
        className="form-control"
        disabled={ isDisabled }
        placeholder="Amount"
        value={ tempAmount }
        onChange={ handleAmountChange }
      />
    </div>
  );
};

export default AmountField;
