import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { getBankingAccountsServiceInfo } from '@src/requests/banking_accounts_service_info';
import { TBankingAccountsService } from '@src/types/banking_accounts_service_info';
import { TID } from '@src/types/common';

const useGetBankingAccountsServiceInfo = (businessId: TID) => {
  return useQuery<TBankingAccountsService, Error & AxiosError>(
    [QueryKey.bankingAccountsServiceInfo, businessId],
    () => getBankingAccountsServiceInfo(businessId),
  );
};

export {
  useGetBankingAccountsServiceInfo,
};
