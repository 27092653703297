module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<table id="category-table" class="table bg-white" style="table-layout: fixed;">\n  <colgroup span="8">\n    <col width="4%"></col>\n    <col width="6%"></col>\n    <col width="10%"></col>\n    <col width="10%"></col>\n    <col width="14%"></col>\n    <col width="8%"></col>\n    <col width="8%"></col>\n    <col width="8%"></col>\n    <col width="8%"></col>\n    <col width="8%"></col>\n    ');
    
      if (this.business.get('class_mapping_type') !== 'any') {
        __out.push('\n      <col width="8%"></col>\n    ');
      }
    
      __out.push('\n    <col width="10%"></col>\n    <col width="4%"></col>\n  </colgroup>\n  <thead>\n    <tr>\n      <th></th>\n      <th class="relative sortable" data-orderby="number" data-orderdir="ASC">Code</th>\n      <th colspan="1" class="relative sortable sorted-desc" data-orderby="category" data-orderdir="ASC">Category</th>\n      <th colspan="1" class="relative sortable" data-orderby="account_type" data-orderdir="ASC">Account Type</th>\n      <th colspan="1" class="relative sortable" data-orderby="detail_type" data-orderdir="ASC">Detail Type</th>\n      <th class="relative sortable" data-orderby="transaction" data-orderdir="ASC">Transactions</th>\n      <th class="relative sortable" data-orderby="invoice" data-orderdir="ASC">Invoices</th>\n      <th class="relative sortable" data-orderby="receipt" data-orderdir="ASC">Receipts</th>\n      <th class="relative sortable" data-orderby="revenue_report" data-orderdir="ASC">Revenue Reports</th>\n      <th class="relative sortable" data-orderby="count_adjustments" data-orderdir="ASC">Journal Entries</th>\n      ');
    
      if (this.business.get('class_mapping_type') !== 'any') {
        __out.push('\n        <th class="relative sortable" data-orderby="mapped_classes" data-orderdir="ASC">Mapped Departments</th>\n      ');
      }
    
      __out.push('\n      <th class="relative sortable" data-orderby="created_at" data-orderdir="ASC">Date Added</th>\n      <th></th>\n    </tr>\n  </thead>\n  <tbody>\n  </tbody>\n</table>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}