import React, { useMemo } from 'react';

import { useTCDContext } from '@src/hooks/contexts/transation_context_data_context';

import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';
import { BotNotificationIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

const ContextSummary: React.FC<{ onClick: () => void; isVendorCategoryStatus?: boolean }> = ({
  onClick,
  isVendorCategoryStatus,
}) => {
  const { data } = useTCDContext();
  const isDisabled = useMemo(() => {
    if (
      data.transactionType === window.configData.transaction_types.EXPENSE 
      && data.isFlaggedOrHasMessage 
      && !data.clientContext
    ) {
      return true;
    }

    if (
      data.transactionType === window.configData.transaction_types.EXPENSE 
      && data.flaggedStatus === 'resolved'
      && isVendorCategoryStatus
    ) {
      return true;
    }
    
    return false;
  }, [data, isVendorCategoryStatus]);

  return (
    <div className={ styles['context-summary'] }>
      <Tooltip.Hover
        content={ data.flaggedStatus === 'resolved' && isVendorCategoryStatus ? 'Suggested. Review categorization.' : '' }
      >
        <Button
          className={ styles['context-header'] }
          disabled={ isDisabled }
          variant="outline"
          onClick={ onClick }
        >
          <BotNotificationIcon className="in-white" fontSize={ 22 } />
          <span>Ask Docyt AI</span>
        </Button>
      </Tooltip.Hover>
    </div>
  );
};

export default ContextSummary;
