import React from 'react';

import { components, DropdownIndicatorProps, GroupBase } from 'react-select';

import { months } from '@src/utils/date_helpers';

import { SelectInput, TOption } from '@src/components/ui_v2/inputs';
import { CaretIcon } from '@src/components/utils/fa_icons';

import styles from './styles.module.scss';

const DropdownIndicator = React.memo((props: DropdownIndicatorProps<TOption, false, GroupBase<TOption>>) => {
  return (
    <components.DropdownIndicator { ...props }>
      <CaretIcon variant="down" />
    </components.DropdownIndicator>
  );
});

DropdownIndicator.displayName = 'DropdownIndicator';

const monthOptions: TOption[] = months.map((month, index) => ({
  label: month,
  value: index.toString(),
}));

interface IDateRangeInputHeaderProps {
  date: Date;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  changeMonth: (month: number) => void;
  changeYear: (year: number) => void;
  minDate?: Date;
  maxDate?: Date;
}

const DateRangeInputHeader = ({
  date,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  decreaseMonth,
  increaseMonth,
  changeMonth,
  changeYear,
  minDate,
  maxDate,
}: IDateRangeInputHeaderProps) => {
  const currentYear = new Date().getFullYear();
  const startYear = minDate?.getFullYear() || currentYear - 100;
  const endYear = maxDate?.getFullYear() || currentYear + 100;

  const yearOptions: TOption[] = React.useMemo(() => Array.from(new Array(endYear - startYear + 1), (val, index) => ({
    label: (startYear + index).toString(),
    value: (startYear + index).toString(),
  })), [startYear, endYear]);

  return (
    <div className={ styles['date-range-input-header'] }>
      <button
        aria-label="Previous month"
        className="react-datepicker__navigation react-datepicker__navigation--previous"
        disabled={ prevMonthButtonDisabled }
        type="button"
        onClick={ decreaseMonth }
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous" />
      </button>
      <div className="react-datepicker__current-month">
        { date.toLocaleString('default', { month: 'long', year: 'numeric' }) }
      </div>
      <button
        aria-label="Next month"
        className="react-datepicker__navigation react-datepicker__navigation--next"
        disabled={ nextMonthButtonDisabled }
        type="button"
        onClick={ increaseMonth }
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next" />
      </button>
      <div className={ styles['date-range-input-header-dropdown'] }>
        <SelectInput
          components={ {
            DropdownIndicator,
          } }
          isClearable={ false }
          isSearchable={ false }
          options={ monthOptions }
          value={ monthOptions.find((o) => o.value === date.getMonth().toString()) }
          onChange={ (option) => changeMonth(parseInt(option?.value || '0', 10)) }
        />
        <SelectInput
          components={ {
            DropdownIndicator,
          } }
          isClearable={ false }
          isSearchable={ false }
          options={ yearOptions }
          value={ yearOptions.find((o) => o.value === date.getFullYear().toString()) }
          onChange={ (option) => changeYear(parseInt(option?.value || '0', 10)) }
        />
      </div>
    </div>
  );
};

DateRangeInputHeader.displayName = 'DateRangeInputHeader';

const MemoizedDateRangeInputHeader = React.memo(DateRangeInputHeader);

export { MemoizedDateRangeInputHeader as default };
