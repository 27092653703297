import React, { useCallback, useMemo } from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

interface IPastTransactionsListItemProps {
  pastTransaction: ITransactionServiceDocument,
}

const PastTransactionsListItem = ({
  pastTransaction,
}: IPastTransactionsListItemProps): JSX.Element => {
  const handleClick = useCallback(() => {
    window.open(`/document/${pastTransaction.documentId}`);
  }, [pastTransaction]);

  const categories = useMemo(() => {
    return pastTransaction.category?.split(';').map((cat) => cat.trim()) || [];
  }, [pastTransaction.category]);

  return (
    <Table.Row>
      <Table.DateCell pointer date={ pastTransaction.transactionDate } />
      <Table.Cell>
        { categories.map((category) => (
          <p key={ `${pastTransaction.id}-${category}` }>{category}</p>
        )) }
      </Table.Cell>
      <Table.AmountCell pointer amount={ pastTransaction.amount } inColor="blue-400" onClick={ handleClick } />
    </Table.Row>
  );
};

export default PastTransactionsListItem;
