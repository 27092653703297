import React, { useMemo } from 'react';

import { ICollaborationProps, IDocumentRequestProps, IFlaggedItemsProps } from '@src/types/common_operational_dashboard';

import LineGraph from '@src/components/operational_dashboard/components/custom_cells/line_graph';
import CorporateCardsTooltip from '@src/components/operational_dashboard/components/tooltips/corporate_cards_tooltip';
import ExpenseReportTooltip from '@src/components/operational_dashboard/components/tooltips/expense_report_tooltip';
import FlaggedItemsTooltip from '@src/components/operational_dashboard/components/tooltips/flagged_item_tooltip';
import { documentRequestsColors, documentRequestsTooltipData, flaggedItemsColors, flaggedItemsTooltipData, IBusinessOwnerConfigFormType, unreadMessagesTooltipData } from '@src/components/operational_dashboard/helper';
import { filterTooltipData, hasActivePreference, isSetupStatusComplete } from '@src/components/operational_dashboard/helper/functional';
import { ICommonProps } from '@src/components/operational_dashboard/types';

import AuthWrapper from './auth_wrapper';
import HoverTooltipWrapper from './hover_tooltip_wrapper';
import { isObjectEmpty } from './utils';

import styles from './style.module.scss';

interface ICollaborationCellProps extends ICollaborationProps, ICommonProps {}
const CollaborationCell = ({
  flaggedItems,
  documentRequests,
  unreadMessages,
  preferences,
  setupStatus,
}: ICollaborationCellProps) => {
  const progressFlaggedItemsData = useMemo(() => {
    if (!preferences?.collaboration?.flaggedItems) return [];
    const result = (Object.keys(flaggedItemsColors) as (keyof IFlaggedItemsProps)[])
      .filter((key) => preferences?.collaboration?.flaggedItems?.[key])
      .map((key) => ({
        value: flaggedItems?.[key] ?? 0,
        color: flaggedItemsColors[key],
      }));
    return result;
  }, [preferences, flaggedItems]);

  const progressDocumentRequestsData = useMemo(() => {
    if (documentRequests) {
      const result = (Object.keys(documentRequests) as (keyof IDocumentRequestProps)[])
        .filter((key) => preferences?.collaboration?.documentRequests?.[key])
        .map((key) => ({
          value: documentRequests[key] ?? 0,
          color: documentRequestsColors[key],
        }));
      return result;
    }
    return [];
  }, [documentRequests, preferences]);

  const UnreadMessagesDisplay = useMemo(() => {
    return (
      <div className={ styles['box-container'] }>
        <span className={ styles['table-font'] }>{isSetupStatusComplete(setupStatus) ? 'NA' : unreadMessages?.unreadMessagesCount ?? '-'}</span>
      </div>
    );
  }, [setupStatus, unreadMessages]);

  return (
    <div className={ `${styles['business-column-container']} ${styles['expense-header-container']}` }>
      {hasActivePreference('flaggedItems' as keyof IBusinessOwnerConfigFormType, preferences?.collaboration as IBusinessOwnerConfigFormType)
       && (
       <div className={ styles['header-item'] }>
         <AuthWrapper isObjectEmpty={ flaggedItems && isObjectEmpty(flaggedItems) }>
           <HoverTooltipWrapper
             content={ (
              (flaggedItems?.flaggedInvoices
               && flaggedItems.flaggedReceipts
               && flaggedItems.flaggedTransactions) && (
               <FlaggedItemsTooltip
                 cellData={ flaggedItems }
                 items={ filterTooltipData(
                   flaggedItemsTooltipData,
                   preferences?.collaboration?.flaggedItems,
                 ) }
               />
              )
           ) }
           >
             <LineGraph
               segments={ preferences
                 ? progressFlaggedItemsData : [] }
               setupStatus={ setupStatus }
             />
           </HoverTooltipWrapper>
         </AuthWrapper>
       </div>
       )}
      {documentRequests && hasActivePreference('documentRequests' as keyof IBusinessOwnerConfigFormType, preferences?.collaboration as IBusinessOwnerConfigFormType)
         && (
         <div className={ styles['header-item'] }>
           <AuthWrapper isObjectEmpty={ isObjectEmpty(documentRequests) }>
             <HoverTooltipWrapper
               content={ (
               (documentRequests.open && documentRequests.reviewed) && (
               <ExpenseReportTooltip
                 cellData={ documentRequests }
                 items={ filterTooltipData(
                   documentRequestsTooltipData,
                   preferences?.collaboration?.documentRequests,
                 ) }
               />
               )
            ) }
             >
               <LineGraph
                 segments={ preferences
                   ? progressDocumentRequestsData : [] }
                 setupStatus={ setupStatus }
               />
             </HoverTooltipWrapper>
           </AuthWrapper>
         </div>
         )}
      {preferences?.collaboration?.unreadMessages?.unreadMessagesCount
       && (
       <div className={ styles['header-item'] }>
         <AuthWrapper isObjectEmpty={ unreadMessages && isObjectEmpty(unreadMessages) }>
           <HoverTooltipWrapper
             content={ (
              unreadMessages?.unreadMessagesCount && (
              <CorporateCardsTooltip
                item={ unreadMessagesTooltipData }
                value={ unreadMessages?.unreadMessagesCount }
              />
              )
           ) }
           >
             {preferences
               ? (
                 UnreadMessagesDisplay
               )
               : null}
           </HoverTooltipWrapper>
         </AuthWrapper>
       </div>
       )}
    </div>
  );
};
export default CollaborationCell;
