import React, { memo } from 'react';

import HoverIcon from './icon_with_hover';
import { IIconConfig } from '../../types';

import styles from './style.module.scss';

interface IGroupHeaderProps {
  heading: string;
  elements?: IIconConfig[] | string[];
  isIssuesHeader?:boolean;
}
const GroupHeader = ({ heading, elements, isIssuesHeader = false }:IGroupHeaderProps) => {
  return (
    <div className={ styles['header-with-icons'] }>
      <h1>{heading}</h1>
      { elements && (
        <div className={ `${styles['group-header-container']} ${styles['expense-header-container']}` }>
          {elements?.map((item: IIconConfig | string) => {
            if (typeof item === 'string') {
              return (
                <span
                  key={ String(item) }
                  className={ styles['header-item'] }
                >
                  {item}
                </span>
              );
            }
            return (
              <span
                key={ item?.key }
                className={ styles['header-item'] }
              >
                <HoverIcon
                  key={ item.key }
                  content={ item.icon }
                  hoverValue={ (
                    <span className={ isIssuesHeader ? styles['white-text'] : '' }>
                      {item.hoverText}
                    </span>
) }
                />
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default memo(GroupHeader);
