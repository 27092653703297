import { TID } from '@src/types/common';
import { camelizeKeys } from '@src/utils/transform_keys';

import { notAuthorizedApiRequest, apiGet } from '../helpers';

interface IPostIntegrationAuthorizationResponse {
  id: TID
  businessId: TID,
  isAuthorized: boolean
}

interface IGetIntegrationAuthorizationUrlParams {
  integrationId: TID,
}

interface IGetIntegrationAuthorizationUrlResponse {
  authorizationUrl: string
}

const postIntegrationAuthorization = (
  params: object,
): Promise<IPostIntegrationAuthorizationResponse> => {
  return notAuthorizedApiRequest({
    method: 'POST',
    url:    '/integrations/api/v1/integration_authorizations/callback',
    params,
  }).then((data) => {
    return camelizeKeys(data) as IPostIntegrationAuthorizationResponse;
  });
};

const getIntegrationAuthorizationUrl = (
  params: IGetIntegrationAuthorizationUrlParams,
): Promise<IGetIntegrationAuthorizationUrlResponse> => {
  return apiGet(
    `integrations/api/v1/integrations/${params.integrationId}/integration_authorizations/new`
  ).then((data) => <any>camelizeKeys(data));
};

export {
  postIntegrationAuthorization,
  getIntegrationAuthorizationUrl,
  IGetIntegrationAuthorizationUrlParams,
  IGetIntegrationAuthorizationUrlResponse,
};
