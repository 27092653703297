import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  removeUserFromChannel,
  IRemoveUserFromChannelParams,
  IRemoveUserFromChannelResponse,
} from '@src/requests/chat_central';

// Custom hook to remove a user from a chat channel
const useRemoveUserFromChannel = () => {
  return useMutation<
    IRemoveUserFromChannelResponse, // The type of the data returned by the mutation function
    Error & AxiosError, // The type of the error returned by the mutation function
    IRemoveUserFromChannelParams // The type of the variables passed to the mutation function
  >(
    [QueryKey.chat_central], // The query key to be invalidated or updated after the mutation
    (params) => removeUserFromChannel(params), // The mutation function that performs the API call
  );
};

export {
  useRemoveUserFromChannel,
};
