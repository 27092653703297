import React from 'react';

import { useGetReceiptServiceByBusinessId } from '@src/hooks/queries/receipt_services';
import { receiptListPath } from '@src/routes';
import { TID } from '@src/types/common';

import ServiceMenu from '@src/components/common/service_menu';
import ServiceSidebar from '@src/components/ui_v2/service_sidebar';

interface IProps {
  businessId: TID
}

const CorporateCardsSidebarMenuItems = ({ businessId }: IProps) => {
  const { data: receiptService } = useGetReceiptServiceByBusinessId(businessId);

  if (!receiptService) return null;

  return (
    <ServiceSidebar
      serviceMenu={ (
        <ServiceMenu
          serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.CORPORATE_CARDS }
        />
      ) }
    >
      <ServiceSidebar.Item
        href={ receiptListPath(businessId) }
        itemCount={ String(receiptService?.unverifiedReceiptsCount) }
        title="All Receipts"
      />
      <ServiceSidebar.Item
        href=""
        title="Missing Receipts"
      />
      <ServiceSidebar.Item
        href=""
        title="Settings"
      />
    </ServiceSidebar>
  );
};

export default CorporateCardsSidebarMenuItems;
