import React, { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useGetPortfolioWidgets } from '@src/hooks/queries/portfolio_services/widgets';
import { IBusinessSimple } from '@src/types/businesses';
import { TID } from '@src/types/common';
import { IPortfolioDashboard } from '@src/types/portfolio';

import { togglePortfolioViewState } from '@src/components/portfolio_dashboard/atoms';

import KPIChartView from './kpi_chart_view';
import Table from '../tabular_view';

interface IAllKPIsViewProps {
  portfolioId: TID,
  dashboard: IPortfolioDashboard,
  businesses: IBusinessSimple[],
}

const AllKPIsView = ({
  portfolioId,
  dashboard,
  businesses,
}: IAllKPIsViewProps) => {
  const query = useGetPortfolioWidgets({ dashboardId: dashboard.id });
  const widgets = useMemo(() => {
    return query.data?.collection || [];
  }, [query.data?.collection]);

  const isChartView = useRecoilValue(togglePortfolioViewState);

  const renderTableView = () => (
    <Table businesses={ businesses } collection={ widgets } />
  );

  const renderChartView = () => (
    <div>
      {
        widgets.map((widget) => (
          <KPIChartView
            key={ widget.id }
            className="col-sm-6 col-md-4 pointer"
            dashboard={ dashboard }
            portfolioId={ portfolioId }
            widget={ widget }
          />
        ))
      }
    </div>
  );

  return isChartView ? renderChartView() : renderTableView();
};

export default AllKPIsView;
