import { ICorporateCardsProps } from '@src/types/common_operational_dashboard';

export const progressCorporateCardsData = (corporateCards?: ICorporateCardsProps) => {
  return [
    { value: corporateCards?.unapprovedReceipts?.[0] ?? 0, color: '#8a2be2' }, // Purple
    { value: corporateCards?.unapprovedReceipts?.[1] ?? 0, color: '' },
  ];
};

export const isObjectEmpty = <T extends object>(obj: T): boolean => Object.keys(obj).length === 0;
export const encryptedIconColor = 'grey-800';
