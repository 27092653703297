import React from 'react';

import { IWidget } from '@src/types/dashboards';

import Title from '@src/components/ui_v2/layout/details_region/header';

import CriticalNumber from '../critical_number';

import styles from './styles.module.scss';

interface IKPIHeaderViewProps {
  widget?: IWidget,
  standardCategoryId?: number
}

const KPIHeaderView = ({
  widget,
  standardCategoryId,
}: IKPIHeaderViewProps) => {
  let unit;
  if (widget?.dataSets?.length) unit = widget.dataSets[0].unit;

  const isValidData = () => {
    return widget && widget.dataSets
      && widget.dataSets.find((data) => data.values && data.values.length > 0);
  };

  return (
    <div className={ styles.header }>
      <Title
        className={ styles.title }
        help={ widget?.description }
        title={ widget?.name }
      />
      {
        isValidData()
        && (widget?.criticalNumber || widget?.criticalNumber === 0)
        && (standardCategoryId !== window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.SAAS_ID) && (
          <CriticalNumber
            className="font-16 font-semi-bold"
            criticalNumber={ widget.criticalNumber }
            unit={ unit }
          />
        )
      }
    </div>
  );
};

export default KPIHeaderView;
