import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IVendorPayment } from '@src/types/vendor_payments';

import { PaymentStatus, PaymentMethodLabel } from './options';

const PAYMENT_CREATED_STATUS_TITLE = 'Created';
const PAYMENT_MAILED_STATUS_TITLE = 'Mailed';
const PAYMENT_PROCESSING_STATUS_TITLE = 'Processing';
const PAYMENT_IN_TRANSIT_STATUS_TITLE = 'In Transit';
const PAYMENT_IN_LOCAL_AREA_STATUS_TITLE = 'In Local Area';
const PAYMENT_PROCESSED_FOR_DELIVERY_STATUS_TITLE = 'Processed for Delivery';
const PAYMENT_CLEARED_STATUS_TITLE = 'Cleared';
const PAYMENT_COMPLETED_STATUS_TITLE = 'Completed';

const ICON_FONT_SIZE = 35;

const formatPaymentMethod = (paymentMethod: string): string => {
  const paymentMethodMap: { [key: string]: string } = {
    docyt_ach:           PaymentMethodLabel.PAYMENT_DOCYT_ACH,
    docyt_check:         PaymentMethodLabel.PAYMENT_DOCYT_CHECK,
    bank_check:          PaymentMethodLabel.PAYMENT_BANK_CHECK,
    bank_account:        PaymentMethodLabel.PAYMENT_BANK_ACCOUNT,
    advanced_settlement: PaymentMethodLabel.PAYMENT_ADVANCED_SETTLEMENT,
    credit_card:         PaymentMethodLabel.PAYMENT_CREDIT_CARD,
    cash:                PaymentMethodLabel.PAYMENT_CASH,
    paid_externally:     PaymentMethodLabel.PAYMENT_PAID_EXTERNALLY,
    vendor_credit:       PaymentMethodLabel.PAYMENT_VENDOR_CREDIT,
  };

  return paymentMethodMap[paymentMethod] || paymentMethod
    .split('_')
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(' ');
};

const paymentStatusVariant = (payment: IVendorPayment): string => {
  const warningStatuses = [
    PaymentStatus.PAYMENT_CREATED,
    PaymentStatus.PAYMENT_MAILED,
    PaymentStatus.PAYMENT_IN_TRANSIT,
    PaymentStatus.PAYMENT_IN_LOCAL_AREA,
    PaymentStatus.PAYMENT_PROCESSING,
    PaymentStatus.PAYMENT_REROUTED,
    PaymentStatus.PAYMENT_RETURNED_TO_SENDER,
  ];

  const successStatuses = [
    PaymentStatus.PAYMENT_PROCESSED_FOR_DELIVERY,
    PaymentStatus.PAYMENT_CLEARED,
    PaymentStatus.PAYMENT_COMPLETED,
  ];

  const failedStatuses = [
    PaymentStatus.PAYMENT_CANCELLED,
    PaymentStatus.PAYMENT_VOIDED,
    PaymentStatus.PAYMENT_FAILED,
  ];

  if (warningStatuses.includes(payment.paymentStatus)) {
    return 'warning';
  }
  if (successStatuses.includes(payment.paymentStatus)) {
    return 'success';
  }
  if (failedStatuses.includes(payment.paymentStatus)) {
    return 'failed';
  }

  return '';
};

const statusValue = (status: string) => {
  switch (status) {
    case PaymentStatus.PAYMENT_CREATED:
      return PAYMENT_CREATED_STATUS_TITLE;
    case PaymentStatus.PAYMENT_MAILED:
      return PAYMENT_MAILED_STATUS_TITLE;
    case PaymentStatus.PAYMENT_PROCESSING:
      return PAYMENT_PROCESSING_STATUS_TITLE;
    case PaymentStatus.PAYMENT_IN_TRANSIT:
      return PAYMENT_IN_TRANSIT_STATUS_TITLE;
    case PaymentStatus.PAYMENT_IN_LOCAL_AREA:
      return PAYMENT_IN_LOCAL_AREA_STATUS_TITLE;
    case PaymentStatus.PAYMENT_PROCESSED_FOR_DELIVERY:
      return PAYMENT_PROCESSED_FOR_DELIVERY_STATUS_TITLE;
    case PaymentStatus.PAYMENT_CLEARED:
      return PAYMENT_CLEARED_STATUS_TITLE;
    case PaymentStatus.PAYMENT_COMPLETED:
      return PAYMENT_COMPLETED_STATUS_TITLE;
    default:
      return status;
  }
};

const truncatedName = (name: string, maxLength: number): string => {
  if (name.length <= maxLength) {
    return name;
  }
  return `${name.slice(0, maxLength)}...`;
};

export const getPaymentStatusClass = (
  document: IAccountsPayableServiceDocument,
  status: string | undefined | null,
) => {
  switch (status) {
    case PaymentStatus.PAYMENT_ERROR:
      return 'error_state';
    case null:
    case undefined:
      return '';
    default:
      return document.state;
  }
};

export {
  statusValue,
  formatPaymentMethod,
  paymentStatusVariant,
  truncatedName,
  ICON_FONT_SIZE,
};
