/* eslint-disable max-len */
import React, { FC, useCallback } from 'react';

import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useLocalStorage } from '@src/hooks/storage';
import { IBankAccountReconciliationItem, IBankAccountReconciliationItemTypes } from '@src/types/bank_account_reconciliations';

import CheckResultIcon from '@src/components/reconciliation_center/month_end/check_result_icon';
import Drawers from '@src/components/reconciliation_center/month_end/drawers';
import { amountFormatterX, checkReconciliationItem } from '@src/components/reconciliation_center/month_end/utils';
import Table from '@src/components/ui_v2/table';
import Tooltip from '@src/components/ui_v2/tooltip';
import { DocytSupportIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface PushedByDocytTransactionsProps {
  item: IBankAccountReconciliationItem
  items: IBankAccountReconciliationItemTypes
}

const PushedByDocytTransactions: FC<PushedByDocytTransactionsProps> = ({ item, items }) => {
  const bankStatement = useBankStatementContext();
  const checkResult = checkReconciliationItem(item, items, bankStatement);

  const { id } = useBusinessContext();
  const breakup = useLocalStorage(`$pushed_by_docyt_breakup_toggle_${id}`, true);
  const breakupValue = breakup.storedValue;
  const handleBreakup = useCallback(() => {
    breakup.setValue(!breakupValue);
  }, [breakup, breakupValue]);

  const tooltip = (
    <div className={ styles.tooltip }>
      <h4>This month</h4>
      Transactions of bank statement from this month that are found in GL with dates falling in this month
      <h4>Outside this month</h4>
      Transactions of bank statement from this month that are found in GL with dates falling before this month or after this month
      <h4>Missing in GL</h4>
      Transactions of bank statement from this month that have been pushed by Docyt into GL, but were found to be missing in GL (could be deleted from QBO due to some reason)
    </div>
  );

  const Breakup = (
    <>
      <Table.Row className={ styles['row-second-level'] }>
        <Table.TextCell>This Month</Table.TextCell>
        <Table.TextCell hideValueTooltip>
          <div className={ styles['cell-stats'] }>
            <span>{ item.details?.thisMonthCount || 0 }</span>
            <span>{ amountFormatterX(item.details?.thisMonthAmount) }</span>
          </div>
        </Table.TextCell>
      </Table.Row>
      <Table.Row className={ styles['row-second-level'] }>
        <Table.TextCell>Outside This Month</Table.TextCell>
        <Table.TextCell hideValueTooltip>
          <div className={ styles['cell-stats'] }>
            <span>{ item.details?.outsideThisMonthCount || 0 }</span>
            <span>{ amountFormatterX(item.details?.outsideThisMonthAmount) }</span>
          </div>
        </Table.TextCell>
      </Table.Row>
      <Table.Row className={ styles['row-second-level'] }>
        <Table.TextCell>Missing in GL</Table.TextCell>
        <Table.TextCell hideValueTooltip>
          <div className={ styles['cell-stats'] }>
            <span>{ item.details?.missingInQboCount || 0 }</span>
            <span>{ amountFormatterX(item.details?.missingInQboAmount) }</span>
          </div>
        </Table.TextCell>
      </Table.Row>
    </>
  );
  return (
    <>
      <Table.Row className={ styles.row }>
        <Table.TextCell
          hideValueTooltip
          className={ breakupValue ? styles['row-arrow-down'] : styles['row-arrow-right'] }
          onClick={ handleBreakup }
        >
          Bank Feed Transactions pushed to GL by Docyt
          <Tooltip.Hover as="span" content={ tooltip }>
            <DocytSupportIcon className={ styles['step-row-icon'] } />
          </Tooltip.Hover>
        </Table.TextCell>
        <Table.TextCell hideValueTooltip>
          <div className={ styles['cell-stats'] }>
            <span>{ item.count }</span>
            <span>{ amountFormatterX(item.amount) }</span>
          </div>
        </Table.TextCell>
        <Table.IconCell>
          <Drawers.PushedByDocytTransactions items={ items } />
        </Table.IconCell>
        <Table.IconCell>
          <CheckResultIcon checkResult={ checkResult } />
        </Table.IconCell>
      </Table.Row>
      { breakupValue && Breakup }
    </>
  );
};

export default PushedByDocytTransactions;
