import React, { useCallback } from 'react';

import classNames from 'classnames';

import { IBusinessSimple } from '@src/types/businesses';
import { IWidget } from '@src/types/dashboards';
import { uiStyleClassNames } from '@src/utils/ui_style_helpers';

import Table from '@src/components/ui_v2/table';

import { CriticalNumberTabularView } from '../critical_number';

interface ITableRowProps {
  item: IWidget[];
  business: IBusinessSimple;
}

const TableRow = ({
  item,
  business,
}: ITableRowProps): JSX.Element => {
  const classesActiveColumns = classNames('value-cell', true);

  const lineItemCellClasses = uiStyleClassNames('line-item-cell', {});

  const getCriticalNumber = useCallback((column: IWidget) => {
    const data = column.dataSets?.filter((dataset) => dataset.businessId === business.id)[0];
    return <CriticalNumberTabularView criticalNumber={ data?.criticalNumber || 0 } unit={ data?.unit || '' } />;
  }, [business.id]);

  return (
    <Table.Row isClickable className="table-row">
      <Table.TextCell className={ lineItemCellClasses } tooltip={ business.name }>
        { business.name }
      </Table.TextCell>
      {
        item.map((column: IWidget) => (
          <Table.TextCell key={ column.id } className={ classesActiveColumns }>
            { getCriticalNumber(column) }
          </Table.TextCell>
        ))
      }
    </Table.Row>
  );
};

export default TableRow;
