import React, { useCallback, useMemo } from 'react';

import pluralize from 'pluralize';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import Button from '@src/components/ui_v2/buttons/button';

import styles from '../styles.module.scss';

interface IChartOfAccountViewProps {
  invoice: IAccountsPayableServiceDocument;
}

const ChartOfAccountView = ({ invoice }: IChartOfAccountViewProps) => {
  const serviceLineItemsCount = invoice.serviceDocumentLineItems?.length || 0;
  const filedValue = invoice.chartOfAccount?.displayName || '-';

  const serviceDocumentSplitsCount = useMemo(() => {
    const serviceDocumentSplits = invoice.serviceDocumentSplits || [];
    return invoice.serviceDocumentSplitsCount || serviceDocumentSplits.length;
  }, [invoice]);

  const predictedChartOfAccountLineItem =
    serviceDocumentSplitsCount === 1 && invoice.serviceDocumentLineItems
      ? invoice.serviceDocumentLineItems[0]?.predictedChartOfAccountName
      : '';

  const value = predictedChartOfAccountLineItem || filedValue;

  const splitCount = useMemo(
    () => (serviceDocumentSplitsCount === 0 ? serviceLineItemsCount : serviceDocumentSplitsCount),
    [serviceDocumentSplitsCount, serviceLineItemsCount]
  );

  const renderChartOfAccountItem = useCallback(() => {
    if (splitCount === 0) {
      if (predictedChartOfAccountLineItem) {
        return (
          <div className={ styles['table-text-cell-text'] }>{ predictedChartOfAccountLineItem }</div>
        )
      }

      if (serviceLineItemsCount > 1) {
        return (
          <Button variant="link">
            { `${serviceLineItemsCount} ${pluralize('Split', serviceLineItemsCount)}` }
          </Button>
        )
      }

      return (
        <div>
          { value && (
            <div className={ styles['table-text-cell-text'] }>{ value }</div>
          ) }
        </div>
      )
    }

    return (
      <Button variant="link">
        { `${splitCount} ${pluralize('Split', splitCount)}` }
      </Button>
    );
  }, [splitCount, predictedChartOfAccountLineItem, serviceLineItemsCount, value]);

  return <div>{ renderChartOfAccountItem() }</div>;
};

export default React.memo(ChartOfAccountView);
