module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="table-responsive document-requests-table-container invoices-table-wrapper invoice-queue-list service-detail-view">\n  <table class="table">\n    <colgroup span="5">\n      <col width="25%"></col>\n      <col width="33%"></col>\n      <col></col>\n      <col width="50px"></col>\n      <col width="50px"></col>\n    </colgroup>\n    <thead>\n      <tr>\n        <th class="sortable font-14 font-bold sorted-desc" data-orderby="created_at" data-orderdir="ASC">Request Date</th>\n        ');
    
      if (this.bankStatementsDocRequestEnabled) {
        __out.push('\n          <th class="font-14 font-bold">Request Type</th>\n        ');
      } else {
        __out.push('\n          <th class="sortable font-14 font-bold" data-orderby="name" data-orderdir="ASC">Request Name</th>\n        ');
      }
    
      __out.push('\n        <th class="sortable font-14 font-bold" data-orderby="statement_date" data-orderdir="ASC">Statement Date</th>\n        ');
    
      if (this.documentRequestNewUXFeatureFlag) {
        __out.push('\n          <th class="font-14 font-bold">Notes</th>\n          <th class="font-14 font-bold">Status</th>\n        ');
      } else {
        __out.push('\n          <th></th>\n          <th></th>\n        ');
      }
    
      __out.push('\n      </tr>\n    </thead>\n    <tbody>\n    </tbody>\n  </table>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}