import React, { useMemo, useState } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useAddToLinkedBusiness } from '@src/hooks/queries/business_vendors';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import { SuccessNotification } from '@src/components/ui/notification';

import { useEditLinkedBusinessesModal } from '../modal/edit_linked_businesses_modal';
import useProgressModal from '../modal/progress_modal';

import styles from '../styles.module.scss';

interface IEditLinkedBusinessesActionProps {
  businessId: TID,
  vendorId: TID,
  linkedBusinesses: any[],
  onDone: () => void,
  customEditButton?: React.ReactNode,
  isAdding?: boolean,
}

const EditLinkedBusinessesAction = ({
  businessId,
  vendorId,
  linkedBusinesses,
  customEditButton,
  isAdding,
}: IEditLinkedBusinessesActionProps): JSX.Element => {
  const [doneCount, setDoneCount] = useState(0);
  const [unLinkedBusinesses, setUnLinkedBusinesses] = useState(0);
  const [successState, setSuccessState] = useState<string | undefined>(undefined);
  const editLinkedBusinesses = useEditLinkedBusinessesModal();
  const progressModal = useProgressModal();

  const queryClient = useQueryClient();

  const addToBusiness = useAddToLinkedBusiness();

  const { mutateAsync } = addToBusiness;

  const linkedBusinessIds = useMemo(() => {
    return linkedBusinesses.map((business) => (business.id));
  }, [linkedBusinesses]);

  const handleLinkToBusinesses = async (businesses: IBusiness[]) => {
    progressModal.open();
    try {
      const params = businesses
        .filter((business) => !linkedBusinessIds.includes(business.id))
        .map((business) => mutateAsync({
          businessId: business.id,
          vendorIds:  [vendorId],
        }).then(() => {
          setDoneCount((count) => count + 1);
        }));
      setUnLinkedBusinesses(params.length);
      await Promise.all(params);
      await queryClient.invalidateQueries(QueryKey.linkedBusinesses);
      setSuccessState('Success');
    } catch (error) {
      await queryClient.invalidateQueries(QueryKey.linkedBusinesses);
      const errorMessage = (error as Error)?.message || 'An unknown error occurred';
      toastr.error(`${errorMessage}`, 'Something went wrong');
    } finally {
      setTimeout(() => {
        progressModal.props.onDone();
        setDoneCount(0);
      }, 2000);
    }
  };

  return (
    <>
      {successState && (
      <SuccessNotification
        message={ `${doneCount} ${doneCount === 1 ? 'business was' : 'businesses were'} linked successfully` }
        onHidden={ () => setSuccessState(undefined) }
      />
      ) }
      <div className="pull-right pointer">
        <progressModal.Component
          doneCount={ doneCount }
          totalCount={ unLinkedBusinesses }
          { ...progressModal.props }
        />
        <editLinkedBusinesses.Component
          { ...editLinkedBusinesses.props }
          businessId={ businessId }
          isAdding={ isAdding }
          linkedBusinessIds={ linkedBusinessIds }
          onDone={ handleLinkToBusinesses }
        />
        {customEditButton ? (
          React.cloneElement(customEditButton as React.ReactElement, {
            onClick: editLinkedBusinesses.open,
          })
        ) : (
          <a
            className={ styles.edit }
            role="button"
            tabIndex={ 0 }
            onClick={ editLinkedBusinesses.open }
          >
            Add
          </a>
        )}
      </div>
    </>
  );
};

export default EditLinkedBusinessesAction;
