import React from 'react';

interface IEmployeeCardMappingTableProps {
  noBorder?: boolean;
  children: React.ReactNode;
}

const EmployeeCardMappingTable: React.FC<IEmployeeCardMappingTableProps> = ({
  noBorder,
  children,
}) => {
  return (
    <div className="mapping-table">
      <div className={ `mapping-header ${noBorder ? 'no-border' : ''}` }>
        <div className="header-cell">
          Employee Card
          <span className="sub-title">(Bank)</span>
        </div>
        <div className="header-cell">
          Employee Card
          <span className="sub-title">(Docyt)</span>
        </div>
        <div className="header-cell">Assigned Business</div>
      </div>
      <div className="mapping-body">
        {children}
      </div>
    </div>
  );
};

export default EmployeeCardMappingTable;
