import React, { useEffect, useState } from 'react';

import { useTCDContext } from '@src/hooks/contexts/transation_context_data_context';
import { useGetTransactionVendorCategory } from '@src/hooks/queries/bank_account_reconciliations';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

import AISuggestion from './ai_suggestion';
import ContextExpand from './context_expand';
import ContextSummary from './context_summary';
import DefaultContent from './default_content';
import { useAIContextLogic } from './use_ai_context_logic';

import styles from './styles.module.scss';

const TransactionContext = () => {
  const { data } = useTCDContext();
  const { aiContext, expand, openExpand, closeExpand, isLoading, error } = useAIContextLogic();
  const { mutate, isLoading: isVendorCategoryLoading } = useGetTransactionVendorCategory();

  const [isVendorCategoryStatus, setIsVendorCategoryStatus] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const vendorCategoryStatus = window.localStorage.getItem('vendorCategoryStatus');
    const errorVendorCategoryStatus = window.localStorage.getItem('errorVendorCategoryStatus');
    setIsVendorCategoryStatus(vendorCategoryStatus === '1');
    setIsError(errorVendorCategoryStatus === '1');

    if (errorVendorCategoryStatus === '1') {
      openExpand();
    }
  }, [setIsVendorCategoryStatus, setIsError, openExpand]);

  const renderContent = () => {
    if (!aiContext) {
      if (isLoading) return <span className={ styles['ai-cursor'] }>|</span>;
      if (error) return <ErrorNotification error={ error } />;
    }

    return (
      <AISuggestion content={ aiContext } />
    );
  };

  const handleCancel = () => {
    window.localStorage.removeItem('vendorCategoryStatus');
    window.localStorage.removeItem('errorVendorCategoryStatus');
    closeExpand()
  };

  const handleClick = () => {
    if (
      data.transactionType === window.configData.transaction_types.EXPENSE 
      && data.flaggedStatus !== 'open'
      && data.clientContext
    ) {
      mutate(
        {
          transactionId: data.id,
          businessId: data.businessId,
          transactionContext: data.clientContext,
        },
        {
          onSuccess: response => {
            window.Docyt.vent.trigger('vendor:category:status', response);

            if (response.error) {
              openExpand();
              setIsVendorCategoryStatus(false);
              setIsError(true);
              window.localStorage.setItem('vendorCategoryStatus',  '0');
              window.localStorage.setItem('errorVendorCategoryStatus',  '1');
            } else {
              setIsVendorCategoryStatus(true);
              window.localStorage.setItem('vendorCategoryStatus',  '1');
            }
          },
          onError: () => {}
        }
      );
    } else {
      openExpand();
    }
  };

  if (expand) {
    return (
      <ContextExpand onClick={ handleCancel }>
        { !isVendorCategoryStatus && isError ? <DefaultContent /> : renderContent() }
      </ContextExpand>
    );
  }

  return (
    <>
      { isVendorCategoryLoading && <Spinner /> }
      <ContextSummary isVendorCategoryStatus={ isVendorCategoryStatus } onClick={ handleClick } />
    </>
  );
};

export default React.memo(TransactionContext);
