module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var can_show_qbo_link;
    
      __out.push('<div class="documents-qbo-link-wrapper">\n  ');
    
      if (this.is_service_document && (this.paymentState === configData.ap_invoice_states["DOCUMENT_STATE_PAID"] || this.paymentState === configData.ap_invoice_states["DOCUMENT_STATE_CREDITED"])) {
        __out.push('\n    <div class="permission-dropdown-wrapper">\n      <div class="dropdown-toggle permission-dropdown-control" id="client-role-dropdown-btn" data-toggle="dropdown">\n        <span class="');
        __out.push(__sanitize(this.qbo_main_badge_icon_class));
        __out.push(' font-16"></span>\n        <span class="font-16 ');
        __out.push(__sanitize(this.qbo_synced ? '' : 'not-synced-link'));
        __out.push('">View in QuickBooks</span>\n        <span class="fa fa-caret-down"></span>\n      </div>\n      <ul class="dropdown-menu" aria-labelledby="client-role-dropdown-btn" style="width: 300px;">\n        <div class="bank-account-list">\n          <li class="flag-off-js" data-value=\'unflagged\'>\n            ');
        if (this.qbo_document_synced) {
          __out.push('\n              <a class="display-flex in-green-600 align-items-center" href="');
          __out.push(__sanitize(this.qbo_link));
          __out.push('" target="_blank">\n                <span class="icon-qbo-on font-16"></span>\n                <span class="text m-l-10">\n                  View ');
          __out.push(__sanitize(this.expense_status ? 'Expense' : this.paymentState === configData.ap_invoice_states["DOCUMENT_STATE_PAID"] ? 'Invoice' : 'Vendor Credit'));
          __out.push(' in QuickBooks\n                </span>\n              </a>\n            ');
        } else {
          __out.push('\n              <a class="display-flex not-synced-link align-items-center" data-toggle="tooltip" data-placement="bottom" title="');
          __out.push(__sanitize(this.qbo_disabled ? 'Transaction not pushed to ledger.' : this.hasQboError ? this.qbo_error : this.qbo_started ? 'QuickBooks sync has been initiated.' : this.qbo_pending ? 'QuickBooks sync has not yet start.' : this.qbo_error));
          __out.push('">\n                <span class="font-16 ');
          __out.push(__sanitize(this.qbo_disabled ? 'icon-qbo-block' : this.hasQboError ? 'icon-qbo-error' : this.qbo_started ? 'icon-qbo-off' : this.qbo_pending ? 'icon-qbo-off' : 'icon-qbo-error'));
          __out.push('"></span>\n                <span class="text m-l-10">\n                  View ');
          __out.push(__sanitize(this.expense_status ? 'Expense' : this.paymentState === configData.ap_invoice_states["DOCUMENT_STATE_PAID"] ? 'Invoice' : 'Vendor Credit'));
          __out.push(' in QuickBooks\n                </span>\n              </a>\n            ');
        }
        __out.push('\n          </li>\n          <li class="flag-on-js" data-value=\'flagged\'>\n            ');
        if (this.qbo_payment_synced) {
          __out.push('\n              <a class="display-flex in-green-600 align-items-center" href="');
          __out.push(__sanitize(this.payment_qbo_link));
          __out.push('" target="_blank">\n                <span class="icon-qbo-on font-16"></span>\n                <span class="text m-l-10">\n                  View ');
          __out.push(__sanitize(this.expense_status ? 'Reimbursement' : 'Payment'));
          __out.push(' in QuickBooks\n                </span>\n              </a>\n            ');
        } else {
          __out.push('\n              <a class="display-flex not-synced-link align-items-center" data-toggle="tooltip" data-placement="bottom" title="');
          __out.push(__sanitize(this.qbo_disabled ? 'Transaction not pushed to ledger.' : this.hasPaymentQboError ? this.payment_qbo_error : this.qbo_started ? 'QuickBooks sync has been initiated.' : this.qbo_pending ? 'QuickBooks sync has not yet start.' : this.payment_qbo_error));
          __out.push('">\n                <span class="font-16 ');
          __out.push(__sanitize(this.qbo_disabled ? 'icon-qbo-block' : this.hasPaymentQboError ? 'icon-qbo-error' : this.qbo_started ? 'icon-qbo-off' : this.qbo_pending ? 'icon-qbo-off' : 'icon-qbo-error'));
          __out.push('"></span>\n                <span class="text m-l-10">\n                  View ');
          __out.push(__sanitize(this.expense_status ? 'Reimbursement' : 'Payment'));
          __out.push(' in QuickBooks\n                </span>\n              </a>\n            ');
        }
        __out.push('\n          </li>\n        </div>\n      </ul>\n    </div>\n  ');
      } else {
        __out.push('\n    ');
        can_show_qbo_link = this.is_tsd ? this.tsd_qbo_synced : this.qbo_synced;
        __out.push('\n    ');
        if (can_show_qbo_link) {
          __out.push('\n      <a class="display-inline-flex in-green-600" href="');
          __out.push(__sanitize(this.qbo_link));
          __out.push('" target="_blank">\n        <span class="icon-qbo-on font-22"></span>\n        <span class="font-16 m-l-10">View in QuickBooks</span>\n      </a>\n    ');
        } else {
          __out.push('\n      <a class="display-inline-flex not-synced-link" data-toggle="tooltip" data-placement="bottom" title="');
          __out.push(__sanitize(this.qbo_disabled ? 'Transaction not pushed to ledger' : this.hasQboError ? this.qbo_error : this.qbo_started ? 'QuickBooks sync has been initiated.' : this.qbo_pending ? 'QuickBooks sync has not yet start' : this.qbo_error));
          __out.push('">\n        <span class="font-22 ');
          __out.push(__sanitize(this.qbo_disabled ? 'icon-qbo-block' : this.hasQboError ? 'icon-qbo-error' : this.qbo_started ? 'icon-qbo-off' : this.qbo_pending ? 'icon-qbo-off' : 'icon-qbo-error'));
          __out.push('"></span>\n        <span class="font-16 m-l-10">View in QuickBooks</span>\n      </a>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}