import React, { useCallback } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetAchTransactionsResponse } from '@src/requests/accounts_payable/ach_transactions';
import { IAchTransaction, TAchTransactionsSortColumn } from '@src/types/accounts_payable/ach_transactions';
import { ISorting } from '@src/types/sorting';

import CollectionTable from '@src/components/ui_v2/collection_table';

import Actions from './actions';
import AchTransactionsFilter from './filter';
import StatusItem from './status_item';

interface IAchTransactionsTableProps {
  infiniteQuery: UseInfiniteQueryResult<IGetAchTransactionsResponse, Error>,
  records: IAchTransaction[],
  sorting: ISorting<TAchTransactionsSortColumn>,
  onRowClick: (document: IAchTransaction) => void,
}

const AchTransactionsTable = ({
  infiniteQuery,
  records,
  sorting,
  onRowClick,
}: IAchTransactionsTableProps) => {
  const headerRegionHeight = Number(window.document?.getElementById('second-header-region')?.clientHeight);

  const statusLabel = useCallback((document: IAchTransaction) => {
    return (
      <StatusItem document={ document } />
    );
  }, []);

  const actions = useCallback((document: IAchTransaction) => {
    return (
      <Actions
        document={ document }
        onRowClick={ onRowClick }
      />
    );
  }, [onRowClick]);

  return (
    <CollectionTable<IAchTransaction, TAchTransactionsSortColumn>
      isRegionScroll
      filter={ (
        <AchTransactionsFilter />
      ) }
      height={ `calc(100vh - ${headerRegionHeight}px - 110px)` }
      query={ infiniteQuery }
      records={ records }
      sorting={ sorting }
      onRowClick={ onRowClick }
    >
      <CollectionTable.TextColumn<IAchTransaction, TAchTransactionsSortColumn>
        name="receiverName"
        sortColumn="receiver_name"
        title="Payee"
        value={ (document) => document.receiverName }
        width="25%"
      />
      <CollectionTable.AmountColumn<IAchTransaction>
        name="amount"
        title="Amount"
        value={ (document) => document.amount }
        width="25%"
      />
      <CollectionTable.DateColumn<IAchTransaction, TAchTransactionsSortColumn>
        name="dwollaCreatedAt"
        sortColumn="dwolla_created_at"
        title="Payment Date"
        value={ (document) => document.dwollaCreatedAt }
        width="25%"
      />
      <CollectionTable.ButtonColumn<IAchTransaction>
        isVisibleHeader
        name="status"
        title="Status"
        value={ statusLabel }
      />
      <CollectionTable.ActionsColumn<IAchTransaction>
        name="actions"
        title="Actions"
        value={ actions }
        width="20px"
      />
    </CollectionTable>
  );
};

export default React.memo(AchTransactionsTable);
