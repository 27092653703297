import React, { useState, useCallback } from 'react';

import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';
import SideView from '@src/components/ui/side_view';

import DocumentConversationRightSideView from './document_requests/document_conversation_right_side_view';

interface IDocumentModel {
  id: TID;
  business_id: TID;
  bank_statement: IBankStatement;
  isBankStatementRequest: () => boolean;
  isBalanceSheetStatementRequest: () => boolean;
  get: (key: string) => string | number | null;
}

interface IDocumentRequestConversationRightsideContainerProps {
  businessId: TID;
  model: IDocumentModel;
  bankStatement: IBankStatement;
  onDone: () => void;
}

const DocumentRequestConversationRightsideContainer = ({
  businessId,
  bankStatement,
  model,
  onDone,
}: IDocumentRequestConversationRightsideContainerProps): JSX.Element | null => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const handleClose = useCallback(() => {
    setIsVisible(false);
    window.Docyt.vent.trigger('request:item:unselected');
    onDone();
  }, [onDone]);

  if (!isVisible) {
    return null;
  }

  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <SideView.Provider>
          <DocumentConversationRightSideView
            bankStatement={ bankStatement }
            businessId={ businessId }
            model={ model }
            onClose={ handleClose }
          />
          <SideView.Render />
        </SideView.Provider>
      </BusinessProvider>
    </Root>
  );
};

export default DocumentRequestConversationRightsideContainer;
