import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Spinner from '@src/components/ui/spinner';

import SidebarSelectInput from './sidebar_select_input';
import useTxnDoc from './use_txn_doc';

interface Props {
  rowData: ITransactionServiceDocument;
  setRowData: (data: ITransactionServiceDocument) => void;
}

const transactionTypes = window.configData.transaction_types;

const withdrawalOptions = [
  { value: transactionTypes.EXPENSE, label: 'Expense' },
  { value: transactionTypes.TRANSFER, label: 'Transfer' },
  { value: transactionTypes.PAYROLL, label: 'Payroll Charges' },
  { value: transactionTypes.REVENUE_REVERSAL, label: 'Revenue Reversal' },
  { value: transactionTypes.EQUITY_WITHDRAWAL, label: 'Equity Withdrawal' },
  { value: transactionTypes.ASSET_PURCHASE, label: 'Asset Withdrawal' },
  { value: transactionTypes.LIABILITY_PAYMENTS, label: 'Liability Payments' },
  { value: transactionTypes.LOAN_PAYMENTS, label: 'Loan Payments' },
];
const depositOptions = [
  { value: transactionTypes.REVENUE, label: 'Income' },
  { value: transactionTypes.REFUND, label: 'Vendor Refunds' },
  { value: transactionTypes.TRANSFER, label: 'Transfer' },
  { value: transactionTypes.PAYROLL, label: 'Payroll Charges' },
  { value: transactionTypes.OTHER_INCOME, label: 'Other Income' },
  { value: transactionTypes.LOAN_PROCEEDS, label: 'Loan Proceeds' },
  { value: transactionTypes.EQUITY_INVESTMENT, label: 'Equity Investment' },
  { value: transactionTypes.OTHER_RECEIVABLES, label: 'Other Receivables' },
];

const TransactionTypeSelector = ({ rowData, setRowData }: Props) => {
  const options = Number(rowData.amount) < 0 ? withdrawalOptions : depositOptions;

  const isDisabled = rowData.state === 'verified';

  const { commonProps: { isSubmitting }, updateFields } = useTxnDoc({ rowData, setRowData });
  const handleSelectChange = async (newType: string) => {
    await updateFields({
      transactionType:     newType,
      userTransactionType: newType,
    });
  };

  // Origin: verify_transaction_view_tmpl.jst.eco (line 26)
  // We treat CHECK as REVENUE for display purposes:
  const actualTransactionType =
    rowData.transactionType === transactionTypes.CHECK
      ? transactionTypes.REVENUE
      : rowData.transactionType;

  const typeValue = options.find((o) => o.value === actualTransactionType);

  return (
    <>
      { isSubmitting && <Spinner /> }
      <SidebarSelectInput
        isDisabled={ isDisabled }
        label="Transaction Type:"
        options={ options }
        placeholder="Select Transaction Type"
        value={ typeValue }
        onChange={ handleSelectChange }
      />
    </>
  );
};

export default TransactionTypeSelector;
