import React from 'react';

import styles from '@src/components/reconciliation_center/all_transactions/styles.module.scss';

interface Props {
  isDisabled: boolean;
  checked: boolean;
  onChange: (newChecked: boolean) => void;
}

const DocytAiLearnToggle = ({ isDisabled, checked, onChange }: Props) => {
  return (
    <div className={ styles['sidebar-data'] }>
      <div className={ styles['docyt-ai-learn-container'] }>
        <div className={ styles['switch-wrapper'] }>
          <input
            checked={ checked }
            className={ styles['switch-input'] }
            disabled={ isDisabled }
            type="checkbox"
            onChange={ (e) => onChange(e.target.checked) }
          />
          <div className={ styles.switch }>
            <span className={ styles.slider } />
          </div>
        </div>
        <span>Docyt AI will learn the above categorization details</span>
      </div>
    </div>
  );
};

export default DocytAiLearnToggle;
