import React from 'react';

import { ICollaborationProps, IExpenseProps, IMonthEndClosingProps, IReconciliationProps, IRevenueProps } from '@src/types/common_operational_dashboard';

import Table from '@src/components/ui_v2/table';

import BusinessCell from '../components/custom_cells/business_cell';
import CollaborationCell from '../components/custom_cells/collaboration_cell';
import ExpenseCell from '../components/custom_cells/expense_cell';
import IssueCell from '../components/custom_cells/issue_cell';
import MonthEndClosingCell from '../components/custom_cells/month_end_closing_cell';
import ReconciliationCell from '../components/custom_cells/reconciliation_cell';
import RevenueCell from '../components/custom_cells/revenue_cell';
import { IBusinessOwnerConfigFormType } from '../helper';
import { hasActivePreference } from '../helper/functional';
import {
  IDashboardData,
  IBusinessCommonProps,
  TViewOperationalDashboard,
  TSetupStatus,
} from '../types';

interface IDashboardTableBodyProps {
    records: IDashboardData[];
    preferences?: IBusinessOwnerConfigFormType;
    view?: string | null;
}

const TableBody = ({
  records,
  preferences,
  view,
}: IDashboardTableBodyProps) => {
  const memoizedBusinesCell = (columnData?: IBusinessCommonProps): React.ReactNode => (
    <BusinessCell user={ view as TViewOperationalDashboard } { ...columnData } />
  );
  const memoizedExpenseCell = (
    columnData?: IExpenseProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <ExpenseCell
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnData }
    />
  );
  const memoizedRevenueCell = (
    columnData?: IRevenueProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <RevenueCell
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnData }
    />
  );
  const memoizedReconciliationCell = (
    columnData?: IReconciliationProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <ReconciliationCell
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnData }
    />
  );
  const memoizedMonthEndCell = (
    columnData?: IMonthEndClosingProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <MonthEndClosingCell
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnData }
    />
  );
  const memoizedCollaborationCell = (
    columnData?: ICollaborationProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <CollaborationCell
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnData }
    />
  );
  return (
    <Table.Body>
      {records.map((columnData) => (
        <Table.Row key={ columnData.id }>
          <Table.Cell>
            {memoizedBusinesCell({
              displayName:         columnData?.displayName,
              legalName:           columnData?.legalName,
              icon:                columnData?.icon,
              setupStatus:         columnData?.setupStatus,
              unreadInboxMessages: columnData?.unreadInboxMessages,
              businessId:          columnData?.businessId,
            })}
          </Table.Cell>
          <Table.Cell>
            {IssueCell({
              ...columnData?.issues,
              setupStatus: columnData?.setupStatus,
              businessId:  columnData?.businessId,
            })}
          </Table.Cell>
          {hasActivePreference('expense', preferences) && (
          <Table.Cell>
            {memoizedExpenseCell(columnData.expense, columnData?.setupStatus)}
          </Table.Cell>
          )}
          {hasActivePreference('revenue', preferences)
                && (
                <Table.Cell>
                  {memoizedRevenueCell(columnData.revenue, columnData.setupStatus)}
                </Table.Cell>
                )}
          { hasActivePreference('continuousReconciliation', preferences) && (
          <Table.Cell>
            {memoizedReconciliationCell(
              columnData.continuousReconciliation,
              columnData.setupStatus,
            )}
          </Table.Cell>
          )}
          { hasActivePreference('monthEndClosing', preferences) && (
          <Table.Cell>
            {memoizedMonthEndCell(
              columnData.monthEndClosing,
              columnData.setupStatus,
            )}
          </Table.Cell>
          )}
          {hasActivePreference('collaboration', preferences) && (
          <Table.Cell>
            {memoizedCollaborationCell(
              columnData.collaboration,
              columnData.setupStatus,
            )}
          </Table.Cell>
          )}
        </Table.Row>
      ))}
    </Table.Body>
  );
};

export default TableBody;
