module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="requests-tab-container m-t-10">\n  <h1 class="font-20 font-semi-bold m-t-0 m-b-0">Document Requests</h1>\n  ');
    
      if (!this.documentRequestNewUXFeatureFlag) {
        __out.push('\n    <div class="display-flex">\n      <a class="request-tab m-r-38 tab-opened-js ');
        if (this.tab === 'opened') {
          __out.push(__sanitize('active'));
        }
        __out.push('">OPEN</a>\n      <a class="request-tab tab-closed-js ');
        if (this.tab === 'closed') {
          __out.push(__sanitize('active'));
        }
        __out.push('">CLOSED</a>\n      </div>\n  ');
      }
    
      __out.push('\n  <div class="create-action-wrapper">\n  </div>\n</div>\n<div id="filter-region"></div>\n<div id="list-region"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}