import React from 'react';

import styles from '@src/components/reconciliation_center/all_transactions/styles.module.scss';

interface Props {
  noPushQbo: boolean;
  isDisabled: boolean;
  onChange: (checked: boolean) => void;
}

const NoPushQbo = ({ noPushQbo, isDisabled, onChange }: Props) => (
  <div className={ styles['sidebar-data-checkbox'] }>
    <input
      checked={ noPushQbo }
      className={ isDisabled ? styles['disabled-checkbox'] : '' }
      id="no-push-qbo"
      type="checkbox"
      onChange={ (e) => !isDisabled && onChange(e.target.checked) }
    />
    <label htmlFor="no-push-qbo">Do not push the transaction to ledger</label>
  </div>
);

export default NoPushQbo;
