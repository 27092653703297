import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete } from './helpers';

// Interface for the parameters required to remove a user from a channel
interface IRemoveUserFromChannelParams {
channelUrl: string, // The URL of the channel
userId: string, // The ID of the user to be removed
}

// Interface for the response received after removing a user from a channel
interface IRemoveUserFromChannelResponse {
status: string, // The status of the removal operation
}

// Function to remove a user from a chat channel
const removeUserFromChannel = (params: IRemoveUserFromChannelParams): Promise<IRemoveUserFromChannelResponse> => {
  const wrappedParams = {
    chat_central_service: underscoreKeys(params)
  };
  return apiDelete('/api/v1/chat_central_services/remove_user_from_channel', wrappedParams)
    .then((data) => camelizeKeys(data) as IRemoveUserFromChannelResponse);
};

export {
  IRemoveUserFromChannelParams,
  IRemoveUserFromChannelResponse,
  removeUserFromChannel,
};
