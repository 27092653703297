module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var isReviewer;
    
      if (['REVIEWER', 'VIEWER'].includes(this.documentRequestServiceRole)) {
        __out.push('\n  ');
        isReviewer = this.documentRequestServiceRole === 'REVIEWER';
        __out.push('\n  <td class="font-12 in-black ');
        if (isReviewer) {
          __out.push(__sanitize('request-detail-column pointer'));
        }
        __out.push(' ');
        if (this.checked) {
          __out.push(__sanitize('active'));
        }
        __out.push('">');
        __out.push(__sanitize(moment(this.created_at).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
        __out.push('</td>\n  ');
        if (this.bankStatementsDocRequestEnabled) {
          __out.push('\n    <td class="font-12 in-dark400 ');
          if (isReviewer) {
            __out.push(__sanitize('request-detail-column pointer'));
          }
          __out.push(' ');
          if (this.checked) {
            __out.push(__sanitize('active'));
          }
          __out.push('">');
          __out.push(__sanitize(this.request_type));
          __out.push('</td>\n  ');
        } else {
          __out.push('\n    <td class="font-12 in-dark400 ');
          if (isReviewer) {
            __out.push(__sanitize('request-detail-column pointer'));
          }
          __out.push(' ');
          if (this.checked) {
            __out.push(__sanitize('active'));
          }
          __out.push('">');
          __out.push(__sanitize(this.name));
          __out.push('</td>\n  ');
        }
        __out.push('\n  <td class="font-12 in-dark400 description-column ');
        if (isReviewer) {
          __out.push(__sanitize('request-detail-column pointer'));
        }
        __out.push(' ');
        if (this.checked) {
          __out.push(__sanitize('active'));
        }
        __out.push('">');
        __out.push(__sanitize(this.statement_date));
        __out.push('</td>\n  ');
        if (this.documentRequestNewUXFeatureFlag) {
          __out.push('\n    <td class="font-12 in-dark400 description-column text-center">');
          __out.push(__sanitize(this.notes || '-'));
          __out.push('</td>\n    <td class="');
          if (isReviewer) {
            __out.push(__sanitize('request-detail-column pointer'));
          }
          __out.push(' ');
          if (this.checked) {
            __out.push(__sanitize('active'));
          }
          __out.push('">\n      ');
          if (this.state === 'reviewed') {
            __out.push('\n        <span class="icon-success font-16 vertical-align-bottom"><span class="path1"></span><span class="path2"></span></span>\n      ');
          }
          __out.push('\n    </td>\n  ');
        } else {
          __out.push('\n    <td></td>\n    <td></td>\n  ');
        }
        __out.push('\n  ');
        if (isReviewer && this.isDropdownVisible) {
          __out.push('\n    <td class="pointer relative ');
          if (this.checked) {
            __out.push(__sanitize('active'));
          }
          __out.push('">\n      <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="request-');
          __out.push(__sanitize(this.id));
          __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <i class=\'icon icon-3-dots font-24\'></i>\n      </button>\n      <div class="document-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="request-');
          __out.push(__sanitize(this.id));
          __out.push('-action-dropdown-btn">\n        ');
          if (this.isMailroomRequest) {
            __out.push('\n          ');
            if (this.state === 'opened') {
              __out.push('\n          <div class="dropdown-item font-14 mark-as-reviewed-js">\n            <i class="icon icon-mark-reviewed"></i> <span>Mark as Reviewed</span>\n          </div>\n          ');
            }
            __out.push('\n          ');
            if (this.state !== 'opened' && this.isAccountant || this.state === 'reviewed') {
              __out.push('\n            <div class="dropdown-item font-14 reopen-issue-js">\n              <i class="icon icon-re-open font-19"></i> <span>Re-Open Request</span>\n            </div>\n          ');
            }
            __out.push('\n          ');
            if (this.state !== 'closed' && this.isAccountant) {
              __out.push('\n            <div class="dropdown-item font-14 close-issue-js">\n              <i class="icon icon-cross-icon font-24"></i> <span>Close Request</span>\n            </div>\n          ');
            }
            __out.push('\n        ');
          }
          __out.push('\n        ');
          if (this.isAccountant) {
            __out.push('\n          <div class="dropdown-item font-14 edit-request-js">\n            <i class="icon icon-edit font-14"></i> <span>Edit Request</span>\n          </div>\n          <div class="dropdown-item font-14 delete-request-js">\n            <i class="icon icon-destroy font-14"></i> <span class="in-red-600">Delete Request</span>\n          </div>\n        ');
          }
          __out.push('\n      </div>\n    </td>\n  ');
        }
        __out.push('\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}