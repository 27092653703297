import React from 'react';

import { IBusinessSimple } from '@src/types/businesses';
import { IWidget } from '@src/types/dashboards';

import Table from '@src/components/ui_v2/table/table';
import TableSection from '@src/components/ui_v2/table_section';

import Body from './body';
import Header from './header';

import styles from './styles.module.scss';

interface IWidgetReportDataTableProps {
  collection: IWidget[];
  businesses: IBusinessSimple[];
}

const WidgetDataTable = ({
  collection,
  businesses,
}: IWidgetReportDataTableProps): JSX.Element => {
  return (
    <TableSection className={ styles['widget-report-data-table-section'] }>
      <TableSection.Panel>
        <Table isRegionScroll sticky wrapperClassName="table-region-container">
          <Header widgets={ collection } />
          <Body businesses={ businesses } widgets={ collection } />
        </Table>
      </TableSection.Panel>
    </TableSection>
  );
};

export default WidgetDataTable;
