import { number, object } from 'yup';

import { TID } from '@src/types/common';

interface IImportMappingValues {
  businessId: TID | undefined
  revenueReportTypeId: TID | undefined
}

const selectImportMappingValidation = object({
  businessId:          number().nullable().label('Business').required(),
  revenueReportTypeId: number().nullable().label('Revenue Mapping').required(),
});

export {
  IImportMappingValues,
  selectImportMappingValidation,
};
