import React, { useCallback } from 'react';

import classNames from 'classnames';

import { Button } from '@src/components/ui_v2/buttons';
import { CloseFullScreenIcon, OpenFullScreenIcon } from '@src/components/utils/icomoon';

import { useExpandRegion } from './hooks';

import styles from './styles.module.scss';

interface IExpandRegionToggleButtonProps {
  className?: string,
}

const FONT_SIZE = 26;

const ExpandRegionToggleButton = ({ className }: IExpandRegionToggleButtonProps) => {
  const classes = classNames(styles['expand-toggle-btn'], className)
  const { toggleStatus, setToggleStatus } = useExpandRegion();

  const onClick = useCallback(() => {
    if (setToggleStatus) setToggleStatus(!toggleStatus);
  }, [toggleStatus, setToggleStatus]);

  return (
    <Button className={ classes } variant="link" onClick={ onClick }>
      { toggleStatus ? (
        <CloseFullScreenIcon fontSize={ FONT_SIZE } />
      ) : (
        <OpenFullScreenIcon fontSize={ FONT_SIZE } />
      ) }
    </Button>
  );
};

export default React.memo(ExpandRegionToggleButton);
