import React from 'react';

import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import { IReviewedStatementViewFilter } from './types';

import styles from '../../styles.module.scss';

interface IReviewFilterProps {
  filter: IFilter<IReviewedStatementViewFilter>;
}

const ReviewFilter = ({
  filter,
}: IReviewFilterProps) => {
  return (
    <Filter filter={ filter }>
      <Filter.TextField
        name="bankingAccountName"
        placeholder="Banking Account"
        widthPercent={ 15 }
      />
      <Filter.YearField
        name="year"
        options={ { endDate: new Date(), date: new Date() } }
        placeholder="Year"
        widthPercent={ 12 }
      />
      <Filter.CheckboxField
        className={ styles['saao-checkbox'] }
        label="See Archived Accounts Only"
        name="archivedAccountsOnly"
        widthPercent={ 15 }
      />
    </Filter>
  );
};

export default React.memo(ReviewFilter);
