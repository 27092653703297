import React, { useCallback, useMemo } from 'react';

import { flatMapDeep } from 'lodash';

import { TFilterData } from '@src/types/filter';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import { useFilterField } from '@src/components/ui_v2/filter';
import Wrapper from '@src/components/ui_v2/filter/field_with_tooltip_wrapper';
import { DropdownInput, formatSelectOption, TOption } from '@src/components/ui_v2/inputs';

interface IInvoiceStatusFilterFieldProps extends
  Omit<React.ComponentPropsWithoutRef<typeof DropdownInput>, 'options' | 'value' | 'onChange'>,
  IFilterFieldUIProps
{
  invoiceStatusName?: string,
}

const statusOptions: TOption[] = window.Docyt.Common.Constants.INVOICE_FILTER_AVAILABLE_STATES.map((state) => {
  return {
    label: state.label,
    value: state.key,
  };
});

const formatTooltip = (option: TOption | null): JSX.Element | null => {
  if (!option) return null;

  return formatSelectOption(option);
};

const InvoiceStatusFilterField = <TFilter extends TFilterData>({
  invoiceStatusName = 'status',
  ...props
}: IInvoiceStatusFilterFieldProps): JSX.Element => {
  const [status, updateStatus] = useFilterField<TFilter, string | null>(
    invoiceStatusName,
  );

  const [wrapperProps, inputProps] = useFilterFieldProps(props);

  const handleChange = useCallback((selectedValue?: TOption | null) => {
    updateStatus(selectedValue?.value || undefined);
  }, [updateStatus]);

  const currentOption = useMemo(() => {
    return flatMapDeep(statusOptions, (opt) => {
      return opt;
    }).find((opt) => {
      return status && opt.value === status;
    });
  }, [status]);

  return (
    <Wrapper
      { ...wrapperProps }
      tooltip={ formatTooltip(currentOption || null) }
      tooltipLabel="Type: "
    >
      <DropdownInput
        placeholder="Select Status..."
        { ...inputProps }
        options={ statusOptions }
        value={ currentOption || null }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(InvoiceStatusFilterField);