import React, { ReactNode } from 'react';

import Dropdown from '@src/components/ui_v2/dropdown';

import HoverableButton from './button';

interface IBulkActionsDropDownProps {
  children: ReactNode,
  title?: string,
  icon?: ReactNode,
  className?: string,
  classNameMenus?: string,
  isShowArrow?: boolean,
}

const BulkActionsDropDown = ({ 
  children, 
  title, 
  icon, 
  className, 
  classNameMenus, 
  isShowArrow = true, 
  ...props 
}: IBulkActionsDropDownProps) => {
  return (
    <Dropdown.Provider { ...props }>
      <HoverableButton
        className={ className }
        icon={ icon }
        isShowArrow={ isShowArrow }
        title={ title }
      />
      <Dropdown.Menu className={ classNameMenus }>{ children }</Dropdown.Menu>
    </Dropdown.Provider>
  );
};

export default React.memo(BulkActionsDropDown);
