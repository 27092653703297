import { useMemo } from 'react';

import { flatten } from 'lodash';
import omit from 'lodash/omit';
import { UseInfiniteQueryResult } from 'react-query';

import { useGetAccountsPayableServiceDocuments } from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { useGetVendorPayments } from '@src/hooks/queries/vendor_payments';
import { useSorting } from '@src/hooks/url_params';
import { IGetAccountsPayableServiceDocumentsResponse } from '@src/requests/accounts_payable/accounts_payable_service_documents';
import { IVendorPaymentsResponse } from '@src/requests/vendor_payments';
import {
  IAccountsPayableServiceDocument,
  IAccountsPayableServiceDocumentsFilter,
  IAccountsPayableServiceDocumentsQueryFilter,
  TAccountsPayableServiceDocumentsSortColumn,
} from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IBusinessVendor } from '@src/types/business_vendors';
import { TID, TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';
import {
  IVendorPayment,
  TVendorPaymentsSortColumn,
  IVendorPaymentsFilter,
  IVendorPaymentsQueryFilter,
} from '@src/types/vendor_payments';
import { dateFilterToQuery } from '@src/utils/filter';

import { useFilterData } from '@src/components/ui_v2/filter';
import { useItemsSelector } from '@src/components/utils_v2/items_selector';

interface IVendorPaymentsCollectionParams {
  businessVendor: IBusinessVendor,
  section: TSection,
}

interface IVendorInvoicesCollectionParams {
  section: TSection,
  serviceId: TID,
}

interface IVendorPaymentsCollection {
  query: UseInfiniteQueryResult<IVendorPaymentsResponse, Error>;
  records: IVendorPayment[];
  sorting: ISorting<TVendorPaymentsSortColumn>;
  selectedRecord?: IVendorPayment;
  refetch: () => void;
}

interface IVendorInvoicesCollection {
  query: UseInfiniteQueryResult<IGetAccountsPayableServiceDocumentsResponse, Error>;
  records: IAccountsPayableServiceDocument[];
  sorting: ISorting<TAccountsPayableServiceDocumentsSortColumn>;
  selectedRecord?: IAccountsPayableServiceDocument;
  refetch: () => void;
  totalAmount: string;
}

const defaultSortingPayments: ISortingParams<TVendorPaymentsSortColumn> = {
  orderColumn:    'payment_date',
  orderDirection: 'desc',
};

const defaultSortingInvoices: ISortingParams<TAccountsPayableServiceDocumentsSortColumn> = {
  orderColumn:    'payment_date',
  orderDirection: 'desc',
};

const filterToQuery = (
  filterData: IVendorPaymentsFilter | undefined,
): IVendorPaymentsQueryFilter | undefined => {
  if (!filterData) return undefined;

  const query = omit(filterData, ['payment_date', 'document_date']);

  return {
    ...query,
    ...dateFilterToQuery(filterData.payment_date, 'payment_date') || {},
    ...dateFilterToQuery(filterData.document_date, 'document_date') || {},
  };
};

const filterToQueryInvoice = (
  filterData: IAccountsPayableServiceDocumentsFilter | undefined,
): IAccountsPayableServiceDocumentsQueryFilter | undefined => {
  if (!filterData) return undefined;

  const query = omit(filterData, ['invoice_date']);

  return {
    ...query,
    ...(dateFilterToQuery(filterData?.invoice_date, 'invoice_date') || {}),
  };
};

const useVendorPaymentsCollection = ({
  businessVendor,
  section,
}: IVendorPaymentsCollectionParams): IVendorPaymentsCollection => {
  const filterData = useFilterData(section);
  const filterQuery = useMemo(() => filterToQuery(filterData), [filterData]);

  const sorting = useSorting<TVendorPaymentsSortColumn>({
    section: section.section,
    defaultSorting: defaultSortingPayments,
  });

  const query = useGetVendorPayments({
    vendorId:   businessVendor.id,
    businessId: businessVendor.businessId,
    filters:    filterQuery,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const { selected: selectedIds } = useItemsSelector(section);

  const selectedRecord = useMemo(() => {
    if (selectedIds.length === 0) return undefined;

    return records.find((r) => r.id === selectedIds[0]);
  }, [selectedIds, records]);

  return {
    query,
    records,
    sorting,
    selectedRecord,
    refetch: query.refetch,
  };
};

const useVendorInvoicesCollection = ({
  section,
  serviceId,
}: IVendorInvoicesCollectionParams): IVendorInvoicesCollection => {
  const filterData = useFilterData(section);
  const filterQuery = useMemo(() =>
    filterToQueryInvoice({ ...filterData, vendorId: section.businessId }),
  [filterData, section]);

  const sorting = useSorting<TAccountsPayableServiceDocumentsSortColumn>({
    section: section.section,
    defaultSorting: defaultSortingInvoices,
  });

  const query = useGetAccountsPayableServiceDocuments({
    serviceId,
    filters: filterQuery,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const { selected: selectedIds } = useItemsSelector(section);

  const selectedRecord = useMemo(() => {
    if (selectedIds.length === 0) return undefined;
    return records.find((r) => r.id === selectedIds[0]);
  }, [selectedIds, records]);

  const totalAmount = query?.data?.pages[0]?.meta?.totalAmount || '0';

  return {
    query,
    records,
    sorting,
    selectedRecord,
    refetch: query.refetch,
    totalAmount,
  };
};

export {
  IVendorPaymentsCollection,
  IVendorInvoicesCollection,
  useVendorPaymentsCollection,
  useVendorInvoicesCollection,
};
