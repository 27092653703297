import React, { ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import { IPopover } from '@src/hooks/popover';
import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import Dropdown, { useDropdown } from '@src/components/ui_v2/dropdown';
import { CaretIcon } from '@src/components/utils/fa_icons';
import { ThreeDotsIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface IHoverableButtonProps extends IUIStyleProps {
  className?: string,
  title?: string,
  icon?: ReactNode,
  isShowArrow?: boolean,
}

const HoverableButton = ({
  className,
  icon,
  title,
  isShowArrow,
}: IHoverableButtonProps) => {
  const { isVisible } = (useDropdown() || {}) as IPopover;
  
  const classes = useMemo(() => classNames(className, {
    [styles['dropdown-toggle-button']]: !icon && !title,
    [styles['dropdown-toggle-button-with-icon-text']]: icon || title,
    [styles.active]: isVisible
  }), [className, icon, title, isVisible]);

  return (
    <Dropdown.ToggleButtonHoverable className={ classes }>
      {icon || title ? (
        <>
          { icon }
          { title && (<span>{ title }</span>) }
        </>
      ) : (
        <ThreeDotsIcon />
      )}
      {isShowArrow && <CaretIcon variant="down" />}
    </Dropdown.ToggleButtonHoverable>
  );
};

const MemoizedHoverableButton = React.memo(HoverableButton);

export {
  IHoverableButtonProps,
  MemoizedHoverableButton as default,
};
