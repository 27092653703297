import React from 'react';

import { portfolioDashboardsWidgetPath } from '@src/routes';
import { IPortfolioDashboard } from '@src/types/portfolio';

import Item from './side_menu_item';

import styles from '../styles.module.scss';

interface IDashboardSideMenuList {
  dashboard: IPortfolioDashboard,
  widgetId?: string,
}

const DashboardSideMenuList = ({
  dashboard,
  widgetId,
}: IDashboardSideMenuList): JSX.Element => {
  return (
    <ul className={ styles['side-menu-list'] }>
      <Item
        active={ !widgetId }
        title="All KPIs"
        url={ portfolioDashboardsWidgetPath(dashboard.portfolioId) }
      />
      {
        dashboard.widgetTemplates.map((item) => (
          <Item
            key={ item.id }
            active={ widgetId === item.id }
            title={ item.name }
            url={ portfolioDashboardsWidgetPath(dashboard.portfolioId, dashboard.id, item.id) }
          />
        ))
      }
    </ul>
  );
};

export default DashboardSideMenuList;
