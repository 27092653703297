import React, { useCallback } from 'react';

import {
  IAccountsPayableServiceDocument,
  TAccountsPayableServiceDocumentsSortColumn,
} from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IBusinessVendor } from '@src/types/business_vendors';
import { TID, TSection } from '@src/types/common';
import { formatDate } from '@src/utils/date_helpers';

import QBOStatusIcon from '@src/components/common_v2/qbo_status_icon';
import { AppLink } from '@src/components/ui_v2/buttons';
import CollectionTable from '@src/components/ui_v2/collection_table';

import { InvoiceFilter } from '../filter';
import { useVendorInvoicesCollection } from '../hooks';
import { ChartOfAccountView, PaymentStatus } from '../status_item';
import { formatPaymentMethod } from '../utils';

import styles from '../styles.module.scss';

interface IVendorInvoiceTableProps {
  businessVendor: IBusinessVendor,
  section: TSection,
  serviceId: TID,
}

const VendorInvoicesTable = ({ businessVendor, section, serviceId }: IVendorInvoiceTableProps) => {
  const collection = useVendorInvoicesCollection({ section, serviceId });

  const invoiceNoLabel = useCallback((invoice: IAccountsPayableServiceDocument) => {
    return (
      <AppLink newWindow className={ styles['invoice-no'] } href={ `/document/${invoice.documentDocytId}` }>
        <span>
          { invoice.documentDocytId ? invoice.documentDocytId : '-' }
        </span>
      </AppLink>
    );
  }, []);

  const invoiceQBOStatusIcon = useCallback((invoice: IAccountsPayableServiceDocument) => {
    return <QBOStatusIcon fontSize={ 18 } model={ invoice } />;
  }, []);

  const paymentStatusLabel = useCallback((invoice: IAccountsPayableServiceDocument) => {
    return (
      <PaymentStatus invoice={ invoice } />
    );
  }, []);

  const charOfAccountLabel = useCallback((invoice: IAccountsPayableServiceDocument) => {
    return (
      <ChartOfAccountView invoice={ invoice } />
    );
  }, []);

  const accrualDateLabel = useCallback((invoice: IAccountsPayableServiceDocument) => {
    const startDate = invoice.accrualStartDate;
    const endDate = invoice.accrualEndDate;

    if (!startDate) {
      return <span>-</span>;
    }

    return (
      <span>{`${formatDate(startDate)} - ${formatDate(endDate)}`}</span>
    );
  }, []);

  return (
    <CollectionTable
      isRegionScroll
      className={ styles['payment-table'] }
      filter={ <InvoiceFilter businessId={ businessVendor.businessId } /> }
      height="calc(100vh - 310px)"
      query={ collection.query }
      records={ collection.records }
      showSettings={ false }
      sorting={ collection.sorting }
    >
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument, TAccountsPayableServiceDocumentsSortColumn>
        name="payment_date"
        sortColumn="payment_date"
        title="Payment Date"
        value={ (invoice) => invoice?.paymentDate || '-' }
        width="6%"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument, TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        name="documentDocytId"
        // sortColumn="document_docyt_id"
        title="Invoice ID"
        value={ invoiceNoLabel }
        width="6%"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument, TAccountsPayableServiceDocumentsSortColumn>
        name="invoiceDate"
        sortColumn="invoice_date"
        title="Invoice Date"
        value={ (invoice) => formatDate(invoice?.invoiceDate) || '-' }
        width="6%"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument, TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        name="chartOfAccount"
        title="Chart of Account"
        value={ charOfAccountLabel }
        width="8%"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument, TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        name="accountNo"
        title="Account No"
        value={ (invoice) => invoice?.accountNumber || '-' }
        width="8%"
      />
      <CollectionTable.IconColumn<IAccountsPayableServiceDocument, TAccountsPayableServiceDocumentsSortColumn>
        name="qbo_sync_status"
        title="QBO Status"
        value={ invoiceQBOStatusIcon }
        width="5%"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument, TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        name="paymentMethod"
        title="Payment Method"
        value={ (invoice) => formatPaymentMethod(invoice?.paymentMethod || '-') }
        width="8%"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument, TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        name="paymentStatus"
        title="Payment Status"
        value={ paymentStatusLabel }
        width="8%"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument, TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        name="accrualPeriod"
        title="Accrual Period"
        value={ accrualDateLabel }
        width="8%"
      />
      <CollectionTable.AmountColumn<IAccountsPayableServiceDocument, TAccountsPayableServiceDocumentsSortColumn>
        className={ styles['payment-amount-label'] }
        name="amount"
        sortColumn="invoice_amount"
        title="Amount"
        value={ (invoice) => invoice.invoiceAmount }
        width="5%"
      />
    </CollectionTable>
  );
};

export default React.memo(VendorInvoicesTable);
