import React, { useCallback } from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import styles from './styles.module.scss';

interface IRowProps extends React.ComponentPropsWithoutRef<'tr'>, IUIStyleProps {
  /**
   * Pass `isClickable` prop to implement custom logic to check if the row is clickable.
   *
   * If the `isClickable` is not passed or is `undefined` then the row will be clickable
   * if `onClick` callback is passed.
   * If the `isClickable` is `false` then `onClick` callback will not be triggered at all.
   */
  isClickable?: boolean,
  selected?: boolean,
  isInactive?: boolean;
  isHoverable?: boolean;
  onClick?: () => void,
}

const Row = ({
  children,
  isClickable,
  selected,
  isInactive,
  isHoverable,
  onClick,
  ...props
}: IRowProps) => {
  const handleClick = useCallback(() => {
    if (isClickable === false) return;
    if (!onClick) return;

    onClick();
  }, [isClickable, onClick]);

  const [classes, trProps] = uiStyleProps(
    undefined,
    props,
    {
      [styles['table-selected-row']]:  selected,
      [styles['table-clickable-row']]: isClickable !== undefined ? isClickable : onClick,
      [styles['table-inactive-row']]:  isInactive,
      [styles['table-hoverable-row']]: isHoverable,
    },
  );

  return (
    <tr
      className={ classes }
      { ...trProps }
      onClick={ handleClick }
    >
      { children }
    </tr>
  );
};

export default Row;
