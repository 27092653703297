/* eslint-disable indent */
import { useCallback, useEffect, useMemo, useState } from 'react';

import { GroupChannel } from '@sendbird/chat/groupChannel';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { useSearchParams } from 'react-router-dom';
import SendBird from 'sendbird';

import toastr from '@lib/toastr';
import { useGetThreadById } from '@src/hooks/queries/ai_chat/ai_chatbot';
import { TUUID } from '@src/types/common';

import { chatChannelCreationMessage } from '../constants';
import { IStartConversationProps } from '../types';
import { getDefaultDataProperty } from '../utils';
import { PushTriggerOption } from '@sendbird/chat';

const useChatProvider = () => {
  const context = useSendbirdStateContext();
  const sdk = sendbirdSelectors.getSdk(context);

  const sendbirdInstance = useMemo(() => {
    return new SendBird({ appId: window.configData.ai_chatbot.app_id });
  }, []);

  const [currentChannel, setCurrentChannel] = useState<GroupChannel>();
  const [searchParams] = useSearchParams();
  const [activeThreadId, setActiveThreadid] = useState<TUUID | undefined>(searchParams.get('threadId') ?? '');
  const [activeChannelID, setActiveChannelId] = useState<TUUID | undefined>();
  const [conversationLoader, setConversationLoader] = useState<boolean>(false);

  const { data: threadByIdResponse } = useGetThreadById(activeThreadId ?? '');

  useEffect(() => {
    if (threadByIdResponse) {
      setActiveChannelId(threadByIdResponse.channelId);
      setActiveThreadid(threadByIdResponse.id);
    }
  }, [threadByIdResponse]);

  useEffect(() => {
    if (activeThreadId) {
      Backbone.history.navigate(
        `/ai_chatbot?threadId=${activeThreadId}`,
        { trigger: false, replace: true },
      );
    } else {
      Backbone.history.navigate(
        '/ai_chatbot',
        { trigger: false, replace: true },
      );
    }
  }, [activeThreadId]);

  const startNewThread = useCallback(async () => {
    setActiveThreadid('');
    setCurrentChannel(undefined);
    setActiveChannelId('');
  }, []);

  const startConversation = useCallback(async ({
    message,
    channelId,
    chatThreadId,
  }: IStartConversationProps) => {
    try {
      setConversationLoader(true);
      const data = getDefaultDataProperty(activeThreadId);
      const channelIdToUse = channelId || activeChannelID || '';
      const activeThreadIdToUse = chatThreadId || activeThreadId || '';
      if ((channelIdToUse) && activeThreadIdToUse) {
        const channel = await sdk.groupChannel.getChannel(channelIdToUse);
        setCurrentChannel(channel);
        setActiveChannelId(channel.url);
        setActiveThreadid(chatThreadId);
        if (message) {
          await channel.sendUserMessage({ message, data });
        }
      } else {
        setActiveThreadid('');
        setCurrentChannel(undefined);
        const channel = await sdk.groupChannel.createChannelWithUserIds(
          [context.config.userId, window.configData.ai_chatbot.sendbird_bot_id],
          false,
          window.configData.ai_chatbot.sendbird_bot_name,
          '',
        );
        channel.setMyPushTriggerOption(PushTriggerOption.OFF);
        setCurrentChannel(channel);
        setActiveChannelId(channel.url);
        if (message) {
          await channel.sendUserMessage({ message, data });
        // setActiveThreadid(activeThreadIdToUse);
        }
      }
    } catch (err) {
      toastr.error(
        chatChannelCreationMessage.errorCreating.message,
        chatChannelCreationMessage.errorCreating.title,
      );
    } finally {
      setConversationLoader(false);
    }
  }, [context, sdk, activeChannelID, activeThreadId]);

  const sendUserMessage = useCallback(async (message: string) => {
    if (currentChannel) {
      const data = getDefaultDataProperty(activeThreadId);
      await currentChannel.sendUserMessage({ message, data });
    }
  }, [activeThreadId, currentChannel]);

  const data = useMemo(() => {
    return {
      startConversation,
      currentChannel,
      activeThreadId,
      activeChannelID,
      sendUserMessage,
      setActiveThreadid,
      getDefaultDataProperty,
      sendbirdInstance,
      startNewThread,
      conversationLoader,
    };
  }, [currentChannel,
    activeThreadId,
    startConversation,
    sendUserMessage,
    activeChannelID,
    sendbirdInstance,
    startNewThread,
    conversationLoader]);
  return data;
};

export default useChatProvider;
