import * as yup from 'yup';

const targetValidation = yup.object().shape({
  targets: yup.object().test(
    'hasTargets',
    'At least one target must be set',
    (value) => Object.keys(value || {}).length > 0
  ),
});

interface IEditTargetsFormValues {
  targets: {
    [key: string]: number;
  };
}

export {
  IEditTargetsFormValues,
  targetValidation,
};
