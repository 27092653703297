import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { IDashboard } from '@src/types/dashboards/dashboards';
import { IWidget } from '@src/types/dashboards/widgets';

import Form from '@src/components/ui/form';

import { DefaultTargets } from './default_targets';
import EditTargetsItem from './item';
import { targetValidation } from './schema';

import styles from './styles.module.scss';

export interface IEditTargetsFormValues {
  dashboardId: string;
  targets: {
    [key: string]: number;
  };
}

interface IEditTargetsFormProps {
  formId: string;
  standardCategoryId: number;
  dashboard: IDashboard;
  widgets: IWidget[];
  onSubmit: (values: IEditTargetsFormValues) => void;
}

const EditTargetsForm = ({
  formId,
  standardCategoryId,
  dashboard,
  widgets,
  onSubmit,
}: IEditTargetsFormProps): JSX.Element => {
  const {
    control,
    handleSubmit,
  } = useForm<IEditTargetsFormValues>({
    defaultValues: {
      targets: dashboard.targets || {},
    },
    resolver: yupResolver(targetValidation),
  });

  const targetsControl = useController({ control, name: 'targets' });

  const handleSubmitError = useCallback((err) => {
    if (!err.targets) return;
    toastr.error(err.targets.message, 'Something wrong');
  }, []);

  const getDefaultTarget = (identifier: string) => {
    const defaultTarget = DefaultTargets[standardCategoryId.toString()]
    if (!defaultTarget) return 0;

    return defaultTarget[identifier]?.value || 0;
  };

  const getTargetUnit = (identifier: string) => {
    const defaultTarget = DefaultTargets[standardCategoryId.toString()]
    if (!defaultTarget) return 'dollar';

    return defaultTarget[identifier]?.unit || 'dollar';
  };

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit, handleSubmitError) }>
      <div className={ styles['edit-targets-container'] }>
        <div className={ styles['message-container'] }>
          You can edit the values here, by default the values are auto-filled using Docyt&apos;s industry standard values.
        </div>
        <div>
          {
            widgets
            && (
              widgets.map((widget) => (
                <EditTargetsItem
                  key={ widget.identifier }
                  defaultTarget={ getDefaultTarget(widget.identifier) }
                  field={ targetsControl.field }
                  targetUnit={ getTargetUnit(widget.identifier) }
                  widget={ widget }
                />
              ))
            )
          }
        </div>
      </div>
    </Form>
  );
};

export default EditTargetsForm; 