/* eslint-disable max-len */
import React, { FC, useCallback } from 'react';

import moment from 'moment';
import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useGetBankAccountReconciliationPDF, useUnreconcile } from '@src/hooks/queries/bank_account_reconciliations';
import { useDownloadDocument } from '@src/hooks/queries/documents';
import { IDownloadDocumentParams, IDownloadDocumentResponse } from '@src/requests/documents';
import { IBankAccountReconciliation } from '@src/types/bank_account_reconciliations';
import { IBankStatement } from '@src/types/bank_statements';
import { ISimpleUserInfo } from '@src/types/users';
import { createAndDownloadBlobFile } from '@src/utils/download_file';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import { Button } from '@src/components/ui_v2/buttons';
import { DefaultAvatarIcon, DownloadDocumentIcon, SuccessIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { getReconciliationDateRange } from '../utils';

import styles from './styles.module.scss';

interface ReconciledProps {
  reconciliation: IBankAccountReconciliation
  bankStatement: IBankStatement
}

const UserAvatar: FC<{ user: ISimpleUserInfo }> = ({ user }) => {
  if (user?.imageUrl) {
    return (
      <img
        alt=""
        height={ 42 }
        src={ user.imageUrl || '' }
        width={ 42 }
      />
    );
  }

  return <DefaultAvatarIcon fontSize={ 42 } />;
};

const Reconciled: FC<ReconciledProps> = ({ reconciliation, bankStatement }) => {
  const reconcileUser = reconciliation.bankAccountReconciliationItems[0]?.acknowledgedBy;
  const reconcileTime = reconciliation.bankAccountReconciliationItems[0]?.acknowledgedOn;
  const reconcileNote = reconciliation.bankAccountReconciliationItems[0]?.note;

  const queryClient = useQueryClient();
  const [startDate] = getReconciliationDateRange(reconciliation, bankStatement);

  const downloadFromGeneration = useGetBankAccountReconciliationPDF();
  const downloadFromS3 = useDownloadDocument();
  const mutation = reconciliation.documentId ? downloadFromS3 : downloadFromGeneration;

  const downloadFile = (data: IDownloadDocumentResponse | Blob) => {
    const filename = data instanceof Blob ? `${moment(startDate).format('MMMYY')}_Reconciliation_Report.pdf` : data.fileName;
    const file = data instanceof Blob ? data : data.fileData;
    createAndDownloadBlobFile(file, filename);
  };

  const data = {
    id:         reconciliation.id,
    documentId: reconciliation.documentId,
    type:       'original',
  };

  const options = {
    onSuccess: downloadFile,
  };

  const download = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    try {
      if (reconciliation.documentId) {
        downloadFromS3.mutate(data as IDownloadDocumentParams, options);
      } else {
        downloadFromGeneration.mutate(data, options);
      }
    } catch (error) {
      const errorMessage = (error as Error).message || 'Failed to download PDF.';
      toastr.error(errorMessage, 'Error');
    }
  };

  const unreconcileMutation = useUnreconcile();

  const onUnreconciled = useCallback((resp?: any) => {
    if (!resp) {
      return;
    }

    if (resp.error) {
      toastr.error(resp.error, 'Error');
      return;
    }

    queryClient.invalidateQueries(
      [QueryKey.bankAccountReconciliation, reconciliation.id],
    );

    window.Docyt.vent.trigger('month_end:ba_reconciliation:status_change', 'in_progress');
    toastr.success('Successfully un-reconcile', 'Success');
  }, [queryClient, reconciliation.id]);

  const unreconcile = useCallback(() => {
    unreconcileMutation.mutate(reconciliation.id, { onSettled: onUnreconciled });
  }, [unreconcileMutation, reconciliation.id, onUnreconciled]);

  const modal = useConfirmDeleteModal({ onDone: unreconcile });

  return (
    <>
      <MutationStatus mutation={ mutation } />
      <MutationStatus mutation={ unreconcileMutation } />
      <modal.Component
        confirmStyle="primary"
        confirmTitle="Confirm"
        text="Are you sure you want to re-open this reconciliation?"
        title="Re-open reconciliation"
        { ...modal.props }
      />
      <div className={ styles.content }>
        <div className={ styles['content-section'] }>
          <div className={ styles.card }>
            <div className={ styles['card-header'] }>
              <span>Reconciled & Acknowledged by</span>
              <SuccessIcon fontSize={ 18 } />
            </div>
            <div className={ styles['card-user'] }>
              <div className={ styles['card-avatar'] }>
                <UserAvatar user={ reconcileUser } />
              </div>
              <div className={ styles['card-user-detail'] }>
                <div className={ styles['card-user-name'] }>{ reconcileUser?.parsedFullname }</div>
                <div className={ styles['card-timestamp'] }>{ reconcileTime && moment(reconcileTime).format('MM/DD/YYYY hh:mm A') }</div>
              </div>
            </div>
          </div>

          <div className={ styles.section }>
            <h4>Acknowledgement Note</h4>
            <p>{ reconcileNote }</p>
          </div>

          <div className={ styles.section }>
            <h4>Reconciliation Report</h4>
            <Button variant="link" onClick={ download }>
              <DownloadDocumentIcon />
              Export
            </Button>
          </div>
        </div>
      </div>
      <div className={ styles.footer }>
        <div>
          <button className={ styles['unreconcile-button'] } type="button" onClick={ modal.open }>
            Re-open Reconciliation
          </button>
        </div>
      </div>
    </>
  );
};

export default Reconciled;
