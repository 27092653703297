/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';

import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import LikedBusinessesView from './linked_businesses_view';

import styles from './styles.module.scss';

interface IBusinessesViewProps {
  businessId: TID,
  vendorId: TID,
  businesses: IBusiness[],
  refetchBusinesses: () => void,
}

const BusinessesView = ({
  businessId,
  vendorId,
  businesses,
  refetchBusinesses,
}: IBusinessesViewProps): JSX.Element | null => {
  return (
    <div className={ styles['linked-businesses-container'] }>
      <div>
        <span>
          This vendor's information is linked to the following businesses. All edits and changes to
          this vendor will be applied across all selected businesses.
        </span>
      </div>
      <LikedBusinessesView
        businessId={ businessId }
        businesses={ businesses }
        refetchBusinesses={ refetchBusinesses }
        vendorId={ vendorId }
      />
    </div>
  );
};

export default BusinessesView;
