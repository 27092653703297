import React, { useCallback } from 'react';

import { useRecoilState } from 'recoil';

import { Button } from '@src/components/ui_v2/buttons';

import { togglePortfolioViewState } from '../atoms';

const TogglePortfolioViewAction = (): JSX.Element => {
  const [isChartView, setIsChartView] = useRecoilState(togglePortfolioViewState);

  const handleViewToggle = useCallback(() => {
    setIsChartView((prevIsChartView) => !prevIsChartView);
  }, [setIsChartView]);

  const title = isChartView ? 'Data Table' : 'Visual Analytics';

  return (
    <Button
      variant="outline"
      onClick={ handleViewToggle }
    >
      { title }
    </Button>
  );
};

export default TogglePortfolioViewAction;
