import { atom } from 'recoil';

import { Family } from '@src/constants/atom_keys';

const toggleViewState = atom<boolean>({
  key:     Family.ToggleViewState,
  default: true,
});

const toggleTargetsState = atom<boolean>({
  key:     Family.ToggleTargetsState,
  default: true,
});

export {
  toggleViewState,
  toggleTargetsState,
};