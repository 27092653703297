import React, { useCallback, useMemo, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetDataSetsWithValues } from '@src/hooks/queries/dashboards/data_sets';
import { useGetReportLastUpdatedDate } from '@src/hooks/queries/report_service/custom_reports';
import { TMongoID, TDateRange } from '@src/types/common';
import { IWidget } from '@src/types/dashboards';
import { API_DATE_FORMAT, formatDate, getUTCTimezone } from '@src/utils/date_helpers';

import Banner from './full_view_banner';
import Footer from './full_view_footer';
import Header from './full_view_header';
import {
  getDefaultDateRange,
  getRealDateRange,
} from '../charts/chart_helpers';
import ChartView from '../charts/chart_view';

import styles from './styles.module.scss';

interface IFulViewProps {
  dashboardId: TMongoID,
  widgetId: TMongoID,
  widgets: IWidget[],
}

const FulView = ({
  dashboardId,
  widgetId,
  widgets,
}: IFulViewProps) => {
  const business = useBusinessContext();
  const widget = widgets.find((item) => item.id === widgetId);

  const defaultRange = getDefaultDateRange(widget?.scale);
  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: formatDate(defaultRange.from, API_DATE_FORMAT),
    endDate:   formatDate(defaultRange.to, API_DATE_FORMAT),
  });

  const realRange = getRealDateRange(
    dateRange,
    business.bookkeepingStartDate,
    widget?.scale,
    widget?.startDate,
  );
  const query = useGetDataSetsWithValues({
    dashboardId,
    widgetId,
    from: realRange.startDate!,
    to:   realRange.endDate!,
  });
  const { data: info } = useGetReportLastUpdatedDate({
    businessId: business.id,
    slug:       widget?.reportIdentifier,
    from:       realRange.startDate!,
    to:         realRange.endDate!,
  });

  const handleDateRange = useCallback((range: TDateRange) => {
    setDateRange(range);
  }, [setDateRange]);

  const dataSets = useMemo(() => {
    return query.data?.dataSets || [];
  }, [query.data?.dataSets]);

  const handleMonthlyLaunch = (event: any, elements: any) => {
    if (!widget?.reportUrl) return;

    const date = getUTCTimezone(new Date(realRange.startDate!));
    const startDate = formatDate(
      new Date(date.getFullYear(), date.getMonth() + elements[0].index, 1),
      API_DATE_FORMAT,
    );
    const endDate = formatDate(
      new Date(date.getFullYear(), date.getMonth() + elements[0].index + 1, 0),
      API_DATE_FORMAT,
    );

    window.open(`${widget?.reportUrl}?from=${startDate}&to=${endDate}`, '_blank');
  };

  return (
    <div className={ styles['full-view-container'] }>
      {
        (info?.lastUpdatedDate && widget?.reportUrl) && (
          <Banner
            dateRange={ realRange }
            lastUpdatedDate={ formatDate(info?.lastUpdatedDate) }
            reportUrl={ widget?.reportUrl }
          />
        )
      }
      <div className={ styles['full-view-panel'] }>
        <Header
          { ...(business.standardCategoryId !== window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.SAAS_ID && {
            criticalNumber: query.data?.meta.criticalNumber,
          }) }
          dateRange={ dateRange }
          unit={ dataSets.length ? dataSets[0].unit : undefined }
          widgetDescription={ widget?.description }
          widgetName={ widget?.name }
          onChange={ handleDateRange }
        />
        {
          realRange.startDate && realRange.endDate && (
            <>
              <ChartView
                dataSets={ dataSets }
                dateRange={ realRange }
                scale={ widget?.scale }
                viewOption={ widget?.fullViewOption }
                onMonthlyLaunch={ handleMonthlyLaunch }
              />
              <Footer
                dataSets={ dataSets }
                viewOption={ widget?.fullViewOption }
              />
            </>
          )
        }
      </div>
    </div>
  );
};

export default FulView;
