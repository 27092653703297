import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { useGetOperationDashboardPreferences }
  from '@src/hooks/queries/operational_dashboard/operation_dashboard_service';
import { IGetDashboardTableServiceResponse } from '@src/requests/operational_dashboard/operation_dashboard_service';
import { ISorting } from '@src/types/sorting';

import TableBody from '@src/components/operational_dashboard/table/table_body';
import TableHead from '@src/components/operational_dashboard/table/table_head';
import { IDashboardData, TDashboardSortColumn,
  TViewOperationalDashboard } from '@src/components/operational_dashboard/types';
import Table from '@src/components/ui_v2/table';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import style from './style.module.scss';

interface IDashboardTableProps {
  infiniteQuery: UseInfiniteQueryResult<
    IGetDashboardTableServiceResponse,
    Error
  >;
  records: IDashboardData[];
  view: TViewOperationalDashboard;
  sorting: ISorting<TDashboardSortColumn>;
}

const DashboardTable = ({
  infiniteQuery,
  records,
  sorting,
  view,
}: IDashboardTableProps) => {
  const { data: preferences } = useGetOperationDashboardPreferences(
    { view },
  );
  const { isLoading } = infiniteQuery;

  return (
    <Table query={ infiniteQuery } wrapperClassName={ style['table-container'] }>
      <TableHead preferences={ preferences } sorting={ sorting } />
      {isLoading
        ? <div className={ style['spinner-container'] }><SpinnerIcon spin fontSize={ 32 } /></div>
        : <TableBody preferences={ preferences } records={ records } view={ view } />}
    </Table>
  );
};

export default DashboardTable;
