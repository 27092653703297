export const PaymentStatus = {
  PAYMENT_CREATED:                'Created',
  PAYMENT_MAILED:                 'Mailed',
  PAYMENT_IN_TRANSIT:             'In Transit',
  PAYMENT_IN_LOCAL_AREA:          'In Local Area',
  PAYMENT_PROCESSING:             'Processing',
  PAYMENT_REROUTED:               'Re-routed',
  PAYMENT_RETURNED_TO_SENDER:     'Returned to sender',
  PAYMENT_PROCESSED_FOR_DELIVERY: 'Processed for Delivery',
  PAYMENT_CLEARED:                'Cleared',
  PAYMENT_COMPLETED:              'Completed',
  PAYMENT_CANCELLED:              'cancelled',
  PAYMENT_VOIDED:                 'Voided',
  PAYMENT_FAILED:                 'Failed',
  PAYMENT_ERROR:                  'Payment Error',
};
