import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IBalanceSheetInformation } from '@src/types/balance_sheet_information';
import { TStatementSortColumn } from '@src/types/balance_sheet_statements';
import { TID, TYear } from '@src/types/common';
import { ISorting } from '@src/types/sorting';
import { MONTHS } from '@src/utils/date_helpers';

import Table from '@src/components/ui/table';

import Item from './table_item';

import styles from './styles.module.scss';

interface IStatementTableProps {
  businessId: TID,
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TStatementSortColumn>,
  statements: IBalanceSheetInformation[],
  months: number;
  year?: TYear,
}

const generateTableCells = (months: number) => {
  const cells = [];
  for (let i = 0; i < months; i += 1) {
    const monthName = MONTHS[i];
    cells.push(<Table.HCell className={ styles['reviewed-item'] }>{monthName}</Table.HCell>);
  }
  return cells;
};

const StatementTable = ({
  businessId,
  query,
  sorting,
  statements,
  months,
  year,
}: IStatementTableProps) => {
  return (
    <Table
      className={ styles['bank-statements-table'] }
      infiniteQuery={ query }
      wrapperClassName="banking-transactions-table-wrapper-limited"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell<TStatementSortColumn>
            columnName="account_name"
            sorting={ sorting }
            widthPercent={ 20 }
          >
            Balance Sheet Account
          </Table.HCell>
          {
            generateTableCells(months)
          }
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          statements.map((statement) => (
            <Item
              key={ statement.id }
              businessId={ businessId }
              months={ months }
              statement={ statement }
              year={ year }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(StatementTable);
