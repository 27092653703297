import React, { useCallback, useMemo, useState } from 'react';

import sumBy from 'lodash/sumBy';

import { useReportServiceBudgetContext } from '@src/hooks/contexts/budget_context';
import {
  useCalculateReportServiceBudgetItem,
} from '@src/hooks/queries/report_service/report_service_budget_items';
import { IReportServiceBudgetFormula } from '@src/types/report_service/report_service_budget_formulas';
import { IReportServiceBudgetItem, TItemChangeCallback } from '@src/types/report_service/report_service_budget_items';
import { MONTHS } from '@src/utils/date_helpers';

import { SelectInput } from '@src/components/ui/form';
import Table from '@src/components/ui/table';
import MutationStatus from '@src/components/utils/mutation_status';

import FormulaValueCell from './formula_value_cell';
import MetricsInputCell from './metrics_input_cell';

interface IBudgetsDetailMetricItemProps {
  item: IReportServiceBudgetItem,
  decimalScaleValue?: number,
  budgetFormulas: IReportServiceBudgetFormula[],
  formulaEnabled: string | boolean | undefined,
  onChange: TItemChangeCallback,
  onSetDraft: (value: boolean) => void,
}

const BudgetsDetailMetricItem = ({
  item,
  decimalScaleValue=0,
  budgetFormulas,
  formulaEnabled,
  onChange,
  onSetDraft,
}: IBudgetsDetailMetricItemProps): JSX.Element => {
  const [currentFormula, setCurrentFormula] = useState<IReportServiceBudgetFormula>(
    budgetFormulas.find((b) => b.id === item.formulaId) || budgetFormulas.find((b) => b.id === 'fixed') || budgetFormulas[0]
  );

  const formulaOptions: any = useMemo(() => {
    const budgetOptions = budgetFormulas.map((formula) => {
      if (!formula.section) {
        return {
          name:  formula.name,
          value: String(formula.id),
        };
      }
      return '';
    });

    return budgetOptions.filter((element) => element !== '');
  }, [budgetFormulas]);

  const budget = useReportServiceBudgetContext();
  const calculator = useCalculateReportServiceBudgetItem();
  const { mutate } = calculator;

  const handleChangeFormula = useCallback((value) => {
    if (value === String(currentFormula.id)) return;

    const newFormula = budgetFormulas.find((b) => String(b.id) === value);
    setCurrentFormula(newFormula!);
    mutate(
      {
        id:           item.id,
        budgetId:     budget.id,
        formulaId:    newFormula!.id,
        formulaInput: 0.0,
      },
      {
        onSuccess: () => onSetDraft(true),
      },
    );
  }, [currentFormula, setCurrentFormula, budgetFormulas, mutate, budget, item, onSetDraft]);

  const handleChangeFormulaValue = useCallback((value: number) => {
    mutate(
      {
        id:           item.id,
        budgetId:     budget.id,
        formulaId:    currentFormula.id,
        formulaInput: value,
      },
      {
        onSuccess: () => onSetDraft(true),
      },
    );
  }, [currentFormula, item, mutate, onSetDraft, budget]);

  const metricBudgetTotal = sumBy(item.budgetItemValues, (value) => value.value);

  return (
    <>
      <MutationStatus mutation={ calculator } />
      <Table.Row>
        <Table.Cell style={ { paddingLeft: 20 } }>{item.standardMetricName}</Table.Cell>
        <Table.Cell />
        <Table.Cell className="table-divider" />
        {
          formulaEnabled
          && (
          <Table.Cell className="table-divider p-l-10">
            <SelectInput
              options={ formulaOptions }
              value={ String(currentFormula.id) }
              onChange={ handleChangeFormula }
            />
          </Table.Cell>
          )
        }
        {
          formulaEnabled
          && (
          <FormulaValueCell
            currentFormula={ currentFormula }
            decimalScaleValue={ decimalScaleValue }
            initValue={ item.formulaValue }
            onChange={ handleChangeFormulaValue }
          />
          )
        }
        <Table.CurrencyAmountCell hidePrefix decimalScaleValue={ decimalScaleValue } value={ metricBudgetTotal } />
        {
          MONTHS.map((month, index) => (
            <MetricsInputCell
              key={ month }
              currentFormula={ currentFormula }
              decimalScaleValue={ decimalScaleValue }
              formulaEnabled={ formulaEnabled }
              item={ item }
              month={ index }
              onChange={ onChange }
              onSetDraft={ onSetDraft }
            />
          ))
        }
      </Table.Row>
    </>
  );
};

export default BudgetsDetailMetricItem;
