import React, { FC, useCallback } from 'react';

import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useLocalStorage } from '@src/hooks/storage';
import {
  IBankAccountReconciliationItem,
  IBankAccountReconciliationItemTypes,
} from '@src/types/bank_account_reconciliations';

import CheckResultIcon from '@src/components/reconciliation_center/month_end/check_result_icon';
import Table from '@src/components/ui_v2/table';
import Tooltip from '@src/components/ui_v2/tooltip';
import { DocytSupportIcon } from '@src/components/utils/icomoon';

import { checkReconciliationItem } from '../utils';

import styles from '@src/components/reconciliation_center/month_end/styles.module.scss';

interface ItemProps {
  data: {
    title: string;
    total: string;
    thisMonth: string;
    carriedOver: string;
    downloadType: string;
    downloadParams: object;
    downloadColumnMappings?: [string, string][];
    drawer?: React.ReactElement;
    showDownloadIcon?: boolean;
    emptyValue?: boolean;
    tooltip?: string;
    customThisMonth?: React.ReactElement;
    item: IBankAccountReconciliationItem;
    items: IBankAccountReconciliationItemTypes;
    noteRequired?: boolean;
    showAckButton?: boolean;
  };
}

interface TooltipComponentProps {
  tooltip?: string;
}

const TooltipComponent: FC<TooltipComponentProps> = ({ tooltip }) => {
  if (!tooltip) return null;

  return (
    <Tooltip.Hover as="span" content={ tooltip }>
      <DocytSupportIcon fontSize={ 18 } inColor="grey-500" />
    </Tooltip.Hover>
  );
};

const StatementEndingBalanceItem = ({ data }: ItemProps) => {
  const {
    title,
    total,
    thisMonth,
    carriedOver,
    drawer,
    tooltip,
    emptyValue = false,
    customThisMonth,
    item,
    items,
  } = data;

  const bankStatement = useBankStatementContext();
  const checkResult = checkReconciliationItem(item, items, bankStatement);
  const { id } = useBusinessContext();
  const breakup = useLocalStorage(`${title}_breakup_toggle_${id}`, false);
  const breakupValue = breakup.storedValue;
  const handleBreakup = useCallback(() => {
    breakup.setValue(!breakupValue);
  }, [breakup, breakupValue]);

  const thisMonthPart = customThisMonth || (
    <Table.Row className={ styles['row-second-level'] }>
      <Table.TextCell>This Month</Table.TextCell>
      <Table.TextCell hideValueTooltip>
        <div className={ styles['cell-stats'] }>
          <span />
          <span>{ emptyValue ? '-' : thisMonth }</span>
        </div>
      </Table.TextCell>
    </Table.Row>
  );

  const Breakup = (
    <>
      { thisMonthPart }

      <Table.Row className={ styles['row-second-level'] }>
        <Table.TextCell>Carried Over</Table.TextCell>
        <Table.TextCell hideValueTooltip>
          <div className={ styles['cell-stats'] }>
            <span />
            <span>
              { carriedOver }
            </span>
          </div>
        </Table.TextCell>
      </Table.Row>
    </>
  );

  return (
    <>
      <Table.Row className={ styles.row }>
        <Table.TextCell
          hideValueTooltip
          className={ breakupValue ? styles['row-arrow-down'] : styles['row-arrow-right'] }
          onClick={ handleBreakup }
        >
          { title }
        </Table.TextCell>
        <Table.TextCell hideValueTooltip>
          <span>{ emptyValue ? '-' : total }</span>
        </Table.TextCell>
        <Table.IconCell>
          { drawer }
          <TooltipComponent tooltip={ tooltip } />
        </Table.IconCell>
        <Table.IconCell>
          <CheckResultIcon checkResult={ checkResult } />
        </Table.IconCell>
      </Table.Row>

      { breakupValue && Breakup }
    </>
  );
};

export default StatementEndingBalanceItem;
