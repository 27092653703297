module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="detail-column checkbox-clickable relative pointer">\n  <div class="checkbox checkbox-primary">\n    <input type="checkbox" id="category-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox" ');
    
      __out.push(__sanitize(this.is_checked ? 'checked' : ''));
    
      __out.push(' class="check-category pointer">\n    <label for="category-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox"></label>\n  </div>\n</td>\n<td class="detail-column" title="');
    
      __out.push(__sanitize(this.number));
    
      __out.push('">');
    
      __out.push(__sanitize(this.number));
    
      __out.push('</td>\n<td class="name-column flex-wrapper">\n  <span class="font-20 m-r-5 icon ');
    
      __out.push(__sanitize(this.qbo_status === 'synced' ? 'icon-qbo-on' : this.qbo_status === 'invalid' ? 'icon-warning in-red-400' : 'icon-qbo-off'));
    
      __out.push(' ');
    
      if (this.qbo_status === 'invalid') {
        __out.push(__sanitize('pointer description-tooltip-js'));
      }
    
      __out.push('" data-toggle="tooltip" data-placement="bottom" title="');
    
      if (!!this.error_description) {
        __out.push(__sanitize(this.error_description));
      }
    
      __out.push('">\n    <span class=\'path1\'></span>\n  </span>\n  <span class="account-name">');
    
      __out.push(__sanitize(this.coaName));
    
      __out.push('</span>\n</td>\n<td class="detail-column">');
    
      __out.push(__sanitize(this.acc_type_name));
    
      __out.push('</td>\n<td class="detail-column">');
    
      __out.push(__sanitize(this.sub_type));
    
      __out.push('</td>\n<td class="detail-column">');
    
      __out.push(__sanitize(this.count_transactions ? this.count_transactions : '0'));
    
      __out.push('</td>\n<td class="detail-column">');
    
      __out.push(__sanitize(this.count_invoices ? this.count_invoices : '0'));
    
      __out.push('</td>\n<td class="detail-column">');
    
      __out.push(__sanitize(this.count_receipts ? this.count_receipts : '0'));
    
      __out.push('</td>\n<td class="detail-column">');
    
      __out.push(__sanitize(this.count_revenues ? this.count_revenues : '-'));
    
      __out.push('</td>\n<td class="detail-column">');
    
      __out.push(__sanitize(this.count_adjustments ? this.count_adjustments : '0'));
    
      __out.push('</td>\n');
    
      if (this.business.get('class_mapping_type') !== 'any') {
        __out.push('\n  <td class="detail-column mapped-classes pointer">\n    <p class="mapped-classes-truncate font-12" data-toggle="tooltip" data-placement="bottom">\n      ');
        __out.push(__sanitize(this.mapped_class_names !== void 0 && this.mapped_class_names.length > 0 ? this.mapped_class_names.join(', ') : '-'));
        __out.push('\n    </p>\n  </td>\n');
      }
    
      __out.push('\n<td class="detail-column" title="');
    
      __out.push(__sanitize(moment(this.created_at).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
    
      __out.push('" >');
    
      __out.push(__sanitize(moment(this.created_at).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
    
      __out.push('</td>\n<td class="pointer">\n  <div class="relative">\n    <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="category-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <i class=\'icon icon-3-dots font-20\'></i>\n    </button>\n    <div class="category-action-dropdown dropdown-menu dropdown-menu-right dropdown-menu-custom" aria-labelledby="category-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n      <a class="dropdown-item font-14" href="');
    
      __out.push(__sanitize(this.qbo_link));
    
      __out.push('" target="_blank">\n        <span class="icon icon-qbo-on font-24"><span class=\'path1\'></span></span> <span>View in QuickBooks</span>\n      </a>\n      <a class="dropdown-item font-14 edit-ledger-link-js">\n        <i class="icon icon-edit-account font-24"></i> <span>Edit Link to Ledger</span>\n      </a>\n      <a class="dropdown-item font-14 edit-category-js">\n        <i class="icon icon-edit-account font-24"></i> <span>Edit Accounting Category</span>\n      </a>\n      <a class="dropdown-item font-14 move-items-js">\n        <i class="icon icon-edit-account font-24"></i> <span>Move Items</span>\n      </a>\n      ');
    
      if (this.qbo_status === 'invalid') {
        __out.push('\n        <a class="dropdown-item font-14 re-sync-js">\n          <i class="icon icon-qbo-on font-24"></i> <span>Re-sync</span>\n        </a>\n      ');
      }
    
      __out.push('\n      <a class="dropdown-item font-14 remove-category-js">\n        <i class="icon icon-trashcan font-24 in-red-600"></i> <span class=\'in-red-600\'>Delete Accounting Category</span>\n      </a>\n    </div>\n  </div>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}