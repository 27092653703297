/* eslint-disable max-len */
import React, { FC, useCallback } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useReconcile } from '@src/hooks/queries/bank_account_reconciliations';
import { IBankAccountReconciliation, IBankAccountReconciliationItemTypes } from '@src/types/bank_account_reconciliations';
import { IBankStatement } from '@src/types/bank_statements';

import { UnableToSyncIcon, WarningTriangleIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { checkReconciliationItem } from '../utils';

import styles from './styles.module.scss';

interface UnreconciledProps {
  items: IBankAccountReconciliationItemTypes
  bankStatement: IBankStatement
  reconciliation: IBankAccountReconciliation
}

const Unreconciled: FC<UnreconciledProps> = ({ reconciliation, items, bankStatement }) => {
  const checkResults = reconciliation.bankAccountReconciliationItems.map((it) => checkReconciliationItem(it, items, bankStatement));
  const errorItems = checkResults.filter((it) => it.result === 'error');
  const warningItems = checkResults.filter((it) => it.result === 'warn');
  const isNoteRequired = warningItems.length > 0;
  const [note, setNote] = React.useState<string>('');

  const queryClient = useQueryClient();
  const mutation = useReconcile();
  const { mutate } = mutation;

  const onReconciled = useCallback((resp?: any) => {
    if (!resp) return;

    if (resp.error) {
      toastr.error(resp.error, 'Error');
      return;
    }

    window.Docyt.vent.trigger(
      'month_end:ba_reconciliation:status_change',
      resp.bank_account_reconciliation?.status,
    );

    queryClient.invalidateQueries([QueryKey.bankAccountReconciliation, reconciliation.id]);
  }, [queryClient, reconciliation]);

  const reconcile = useCallback(() => {
    mutate(
      { id: reconciliation.id, note },
      { onSettled: onReconciled },
    );
  }, [reconciliation, mutate, onReconciled, note]);

  return (
    <>
      <MutationStatus mutation={ mutation } />
      <div className={ styles.content }>
        <div className={ styles['content-section'] }>
          <div className={ styles.section }>
            <p className={ styles['section-tip'] }>Fix the issues(if any) and acknowledge all the items to reconcile the account.</p>
            { errorItems.length > 0 && (
              <div className={ styles.danger }>
                <div>
                  <UnableToSyncIcon fontSize={ 18 } inColor="red-400" />
                </div>
                <div>
                  <p><b>Fix the issues below to reconcile:</b></p>
                  <ul>
                    { errorItems.map((it) => <li key={ it.message }>{ it.message }</li>)}
                  </ul>
                </div>
              </div>
            )}
          </div>

          { errorItems.length === 0 && (
            <div className={ styles.section }>
              <p className={ styles['section-title'] }>Acknowledgement Note</p>
              <textarea
                className={ styles['reconciliation-notes'] }
                placeholder="Add note"
                rows={ 5 }
                value={ note }
                onChange={ (e) => setNote(e.target.value) }
              />
              <p className={ styles['input-tip'] }>
                <WarningTriangleIcon fontSize={ 20 } />
                { '  ' }
                <span>
                  An acknowledgement note is required as there are few open items in this account.
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={ styles.footer }>
        <div>
          <button className={ styles['reconcile-button'] } disabled={ errorItems.length > 0 || (isNoteRequired && !note) } type="button" onClick={ reconcile }>
            Mark as Reconciled
          </button>
        </div>
      </div>
    </>
  );
};

export default Unreconciled;
