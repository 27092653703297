import { useMemo } from 'react';

import { omit } from 'lodash';
import flatten from 'lodash/flatten';
import { UseInfiniteQueryResult } from 'react-query';

import { useGetTransactionServiceDocuments } from '@src/hooks/queries/transaction_service_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetTransactionServiceDocumentsResponse } from '@src/requests/transaction_service_documents';
import { TID, TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';
import { ITransactionServiceDocument, TTransactionServiceDocumentsSortColumn } from '@src/types/transaction_service_documents';

import { TSubTransactionTypeOption } from '@src/components/common_v2/filter_fields/transaction_type_filter_field';
import { useFilterData } from '@src/components/ui_v2/filter';
import { useItemsSelector } from '@src/components/utils_v2/items_selector';
import { TItemsSelectionState } from '@src/components/utils_v2/items_selector/types';

interface IUseTSDCollectionParams {
  businessId: TID,
  excluded?: boolean,
}

interface ITSDCollection {
  query: UseInfiniteQueryResult<IGetTransactionServiceDocumentsResponse, Error>,
  records: ITransactionServiceDocument[],
  section: TSection,
  sorting: ISorting<TTransactionServiceDocumentsSortColumn>,
  selectedRecords: ITransactionServiceDocument[],
  getSelectionState: () => TItemsSelectionState,
  markAll: (checked: boolean) => void,
}

const tsdDefaultSorting: ISortingParams<TTransactionServiceDocumentsSortColumn> = {
  orderColumn:    'transaction_date',
  orderDirection: 'desc',
};

type TTransactionState = 'unverified' | 'verified' | undefined;

const getTransactionsParamsFromFilter = (filterData: Partial<TSubTransactionTypeOption>) => {
  const filterParams = omit(filterData || {});

  const rpa = filterParams.reconciliation_payment_account_id;
  if (rpa && typeof rpa === 'object') {
    filterParams.reconciliation_payment_account_id = Object.values(rpa);
  }
  const pa = filterParams.payment_account_id;
  if (pa && typeof pa === 'object') {
    filterParams.payment_account_id = Object.values(pa);
  }

  let transactionsState: TTransactionState;

  if (filterData?.transaction_type && filterData.transaction_type.length > 0) {
    if (filterData.transaction_type[0] === window.configData.transaction_types.UNVERIFIED) {
      transactionsState = 'unverified';
      delete filterParams.transaction_type;
    } else {
      transactionsState = 'verified';
    }
  }

  return {
    filter: filterParams,
    state:  transactionsState,
  };
};

const useTSDCollection = ({
  businessId,
  excluded,
}: IUseTSDCollectionParams): ITSDCollection => {
  const section = useMemo(() => {
    return {
      businessId,
      section: window.Docyt.Common.Constants.TRANSACTION_TYPES.ALL_TRANSACTIONS,
    };
  }, [businessId]);

  const filterData = useFilterData(section);

  const filterParams = useMemo(() => {
    return getTransactionsParamsFromFilter(filterData);
  }, [filterData]);

  const sorting = useSorting<TTransactionServiceDocumentsSortColumn>({
    section:        section.section,
    defaultSorting: tsdDefaultSorting,
  });

  const query = useGetTransactionServiceDocuments({
    businessId,
    withDuplicateOriginTransaction: true,
    excluded,
    ...filterParams,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const { selected: selectedIds, markAll, getSelectionState } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter((r) => selectedIds.includes(r.id));
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    sorting,
    selectedRecords,
    markAll,
    getSelectionState,
  };
};

export {
  ITSDCollection,
  useTSDCollection,
};
