/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';

import moment from 'moment';

import { useChatsFayeChannel } from '@src/hooks/faye/chat';
import { useGetTransactionMessages } from '@src/hooks/queries/bank_account_reconciliations';
import { useGetDocument } from '@src/hooks/queries/documents';
import { rephraseChatMessage, getChatParticipants, IChatParticipant } from '@src/requests/bank_account_reconciliations';

import InboxServiceProvider from '@src/components/providers/inbox_service_provider';
import { BotNotificationIcon } from '@src/components/utils/icomoon';

import AddDocument from './chat_add_document';
import MentionInput from './mention_input';
import MessageGroup from './message_group';

import styles from '../styles.module.scss';

interface IChatProps {
  documentId: number;
  businessId: number;
}

interface IMessageData {
messageId?: number;
  message?: {
    action: 'typing' | 'update' | 'delete';
    text: string;
    sender_name: string;
    sender_avatar?: {
      s3_object_key: string;
    };
  };
  error?: string;
}

interface ISenderAvatar {
s3ObjectKey: string;
}

interface IChatMessage {
  id: number;
  text: string;
  senderName: string;
  createdAt: string;
  senderAvatar?: ISenderAvatar;
  chatDocument?: IChatDocument;
}

interface IChatDocument {
  businessNames: string[];
  fileContentType: string;
  finalFileKey: string;
  firstDocumentOwnerName: string;
  firstPageS3Key: string;
  haveAccess: boolean;
  id: number;
  originalFileKey: string;
  originalFileName: string;
  state: string;
  storageSize: number;
  standardDocumentName: string;
  standardFolderName: string;
  pagesCount: number;
}

interface IMessageGroup {
  id: string;
  messages: IChatMessage[];
  timestamp: string;
  senderName: string;
  senderAvatar?: ISenderAvatar;
}

interface IMentionTag {
  id: number;
  name: string;
  avatar: string;
  role: string;
}

interface IMessagePart {
  type: 'text' | 'mention';
  content: string;
  mentionData?: IMentionTag;
}

// Add new interface for hover card
interface IHoverCardProps {
  // uid: string;
  name: string;
  avatar?: string;
  role?: string;
  style: React.CSSProperties;
}

// Add new component for hover card
const MentionHoverCard = ({ name, avatar, role, style }: IHoverCardProps) => (
  <div className={ styles['mention-hover-card'] } style={ style }>
    <div className={ styles['mention-item'] }>
      {avatar ? (
        <img alt={ `${name}'s avatar` } className={ styles['small-avatar'] } src={ avatar } />
      ) : (
        <i className="icon icon-smile in-blue-400 font-25" />
      )}
      <span>{ name }</span>
      {role && <span className={ styles.role }>{ role }</span>}
    </div>
  </div>
);

const Chat = ({ documentId, businessId }: IChatProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const messageInputRef = useRef<HTMLDivElement>(null);
  const [rephrasedMessage, setRephrasedMessage] = useState<string | null>(null);
  const [isAiLoading, setIsAiLoading] = useState(false);
  const [chatMembers, setChatMembers] = useState<IChatParticipant[]>([]);
  const [messageParts, setMessageParts] = useState<IMessagePart[]>([{ type: 'text', content: '' }]);
  const [hoverCard, setHoverCard] = useState<{
    uid: string;
    name: string;
    position: { top: number; left: number };
  } | null>(null);

  const messagesQuery = useGetTransactionMessages(documentId);
  const { data: document } = useGetDocument(documentId);
  const { chatId } = document || {};
  const messageGroups = useMemo(() => {
    const messages = messagesQuery.data?.chatMessages || [];
    const groups: IMessageGroup[] = [];

    messages.forEach((chatMessage: IChatMessage) => {
      const messageTime = moment(chatMessage.createdAt);
      const lastGroup = groups[groups.length - 1];

      if (lastGroup
          && lastGroup.senderName === chatMessage.senderName
          && moment(lastGroup.timestamp).isSame(messageTime, 'minute')) {
        lastGroup.messages.push(chatMessage);
      } else {
        groups.push({
          id:           `group-${chatMessage.id}`,
          messages:     [chatMessage],
          timestamp:    chatMessage.createdAt,
          senderName:   chatMessage.senderName,
          senderAvatar: chatMessage.senderAvatar,
        });
      }
    });

    return groups;
  }, [messagesQuery.data?.chatMessages]);

  const formatTimestamp = (timestamp: string) => {
    return moment(timestamp).format('ddd, MMM D YYYY, h:mm A');
  };

  const getSenderAvatarUrl = (group: IMessageGroup): string | undefined => {
    if (!group.senderAvatar?.s3ObjectKey) return undefined;
    return `https://${window.configData.bucketName}.s3.amazonaws.com/${group.senderAvatar.s3ObjectKey}`;
  };

  // Render message content
  const renderMessageContent = (text: string) => {
    // Split text into parts using regex to match all possible patterns
    const parts = text
      // Match four patterns:
      // 1. User mention: $[uid:123,name:John]$
      // 2. Admin role: $[role:ADMIN]$
      // 3. All role: $[role:all]$
      // 4. Regular text (anything between special patterns)
      .split(/(\$\[uid:\d+,name:[^\]]+\]\$|\$\[role:(?:ADMIN|all)\]\$|[^$]+)/g)
      .filter(Boolean); // Remove empty strings

    return parts.map((part, index) => {
      // Handle user mention pattern
      const userMentionMatch = part.match(/\$\[uid:(\d+),name:([^\]]+)\]\$/);
      if (userMentionMatch) {
        const [, uid, name] = userMentionMatch;
        return (
          <span
            key={ `user-${uid}` }
            className={ styles['mention-text'] }
            onMouseEnter={ (e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              setHoverCard({
                uid,
                name,
                position: {
                  top:  rect.top - 70,
                  left: rect.left,
                },
              });
            } }
            onMouseLeave={ () => setHoverCard(null) }
          >
            @
            { name }
          </span>
        );
      }

      // Handle role mention pattern (ADMIN or all)
      const roleMentionMatch = part.match(/\$\[role:(ADMIN|all)\]\$/);
      if (roleMentionMatch) {
        const [, role] = roleMentionMatch;
        return (
          <span
            key={ `role-${role}` }
            className={ styles['mention-text'] }
            onMouseEnter={ (e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              setHoverCard({
                uid:      role,
                name:     role,
                position: {
                  top:  rect.top - 70,
                  left: rect.left,
                },
              });
            } }
            onMouseLeave={ () => setHoverCard(null) }
          >
            @
            { role }
          </span>
        );
      }

      // Handle regular text
      return (
        <span key={ part }>
          { part }
        </span>
      );
    });
  };

  // handle Faye message callback
  const handleFayeMessage = useCallback(async (data: IMessageData) => {
    if (data.error) {
      return;
    }

    if (data.message?.action === 'typing') {
      return;
    }

    if (data.message && ['update', 'delete'].includes(data.message.action)) {
      // @ts-ignore
      window.Docyt.fayeClient.publish('/service/message_ack', {
        message: {
          ...data.message,
          read: true,
        },
      });
      await messagesQuery.refetch();
      return;
    }

    if (data.messageId) {
      try {
        // get new message details
        const response = await fetch(`/api/v1/messages/${data.messageId}`);
        const messageData = await response.json();
        // @ts-ignore
        window.Docyt.fayeClient.publish('/service/message_ack', {
          message: {
            ...messageData.message,
            read: true,
          },
        });

        // refresh messages list
        await messagesQuery.refetch();
      } catch (error) {
        // ignore
      }
    }
  }, [messagesQuery]);

  // use Faye channel
  useChatsFayeChannel(Number(chatId), handleFayeMessage);

  // 发送消息
  const publishMessage = async (messageText: string) => {
    if (!chatId) return;

    try {
      // @ts-ignore
      window.Docyt.fayeClient.publish(`/chats/${chatId}`, {
        text:             messageText,
        auth_token:       localStorage.getItem('oauth_access_token'),
        client_timestamp: moment().unix(),
        notifiers:        [],
        type:             'web',
      });

      await messagesQuery.refetch();
      if (messageInputRef.current) {
        messageInputRef.current.textContent = '';
      }
    } catch (error) {
      // ignore
    }
  };

  // 处理发送消息
  const handleSendMessage = () => {
    if (!messageParts.some((part) => part.content.trim())) return;

    setIsLoading(true);
    try {
      const processedMessage = messageParts.map((part) => part.content).join('');
      publishMessage(processedMessage);
      setMessageParts([{ type: 'text', content: '' }]);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch chat members
  useEffect(() => {
    const fetchChatMembers = async () => {
      try {
        const response = await getChatParticipants(Number(chatId));
        // Add ADMIN and all participants
        response.chatParticipants.push({
          id:             0,
          firstName:      'ADMIN',
          middleName:     '',
          lastName:       '',
          email:          'ADMIN',
          accountManager: false,
          phone:          null,
          avatar:         null,
          role:           '',
        });
        response.chatParticipants.push({
          id:             1,
          firstName:      'all',
          middleName:     '',
          lastName:       '',
          email:          'all',
          accountManager: false,
          phone:          null,
          avatar:         null,
          role:           '',
        });
        setChatMembers(response.chatParticipants);
      } catch (error) {
        // Handle error if needed
      }
    };

    if (chatId) {
      fetchChatMembers();
    }
  }, [chatId]);

  const renderMessageGroup = (group: IMessageGroup) => (
    <MessageGroup
      key={ group.id }
      chatId={ chatId }
      chatMembers={ chatMembers }
      formatTimestamp={ formatTimestamp }
      getSenderAvatarUrl={ getSenderAvatarUrl }
      group={ group }
      renderMessageText={ renderMessageContent }
      onRefetch={ async () => {
        await messagesQuery.refetch();
      } }
    />
  );

  // rephrase chat messa
  const handleAiPrompt = async () => {
    if (!messageParts.some((part) => part.content.trim())) return;

    // Join all parts to create the final message
    const TotalMessage = messageParts.map((part) => part.content).join('');

    setIsAiLoading(true);

    try {
      // Process message to replace mentions with placeholders
      let processedMessage = TotalMessage;
      const newMentionMappings: {[key: string]: string} = {};
      let userIndex = 0;

      // Find all mentions in the message
      const mentions = TotalMessage.match(/\$\[(?:uid:\d+,name:[^\]]+|role:(?:ADMIN|all))\]\$/g) || [];

      // Replace each mention with a placeholder
      mentions.forEach((mention) => {
        const placeholder = `USERNAME${userIndex}`;
        newMentionMappings[placeholder] = mention;
        processedMessage = processedMessage.replace(mention, placeholder);
        userIndex += 1;
      });

      // Call API with processed message
      const response = await rephraseChatMessage({
        businessId,
        message: processedMessage.trim(),
      });

      // Restore mentions in the rephrased message
      let rephrasedWithMentions = response.rephrased;
      Object.entries(newMentionMappings).forEach(([placeholder, originalMention]) => {
        rephrasedWithMentions = rephrasedWithMentions.replace(
          new RegExp(placeholder, 'g'),
          originalMention,
        );
      });

      setRephrasedMessage(rephrasedWithMentions);
    } catch (error) {
      // ignore
    } finally {
      setIsAiLoading(false);
    }
  };

  // hide rephrase chat message
  const handleHideRephrased = () => {
    setRephrasedMessage(null);
  };

  // accept rephrase chat message
  const handleAcceptRephrased = async () => {
    if (rephrasedMessage && messageInputRef.current) {
      // Convert mentions to HTML format
      const processedMessage = rephrasedMessage.replace(
        /\$\[(?:uid:(\d+),name:([^\]]+)|role:(ADMIN|all))\]\$/g,
        (match, uid, name, role) => {
          if (role) {
            // eslint-disable-next-line max-len
            return `<span class="atwho-inserted" contenteditable="false" data-mention-type="role">@${role.toLowerCase()}</span>`;
          }
          return `<span class="atwho-inserted" contenteditable="false" data-mention-id="${uid}">@${name}</span>`;
        },
      );

      // Update input content
      messageInputRef.current.innerHTML = processedMessage;

      // Add non-breaking space at the end
      const spaceNode = window.document.createTextNode('\u00A0');
      messageInputRef.current.appendChild(spaceNode);

      setMessageParts([{ type: 'text', content: rephrasedMessage }]);
      setRephrasedMessage(null);

      // Move cursor to end
      const selection = window.getSelection();
      if (selection && window.document) {
        const range = window.document.createRange();
        range.setStartAfter(spaceNode);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  return (
    <div className={ styles['chat-container'] }>
      <div className={ styles['messages-list'] }>
        {messageGroups.map(renderMessageGroup)}
      </div>

      {rephrasedMessage && (
        <div className={ styles['chat-rephrased-message'] }>
          <div className={ styles['rephrased-message-text'] }>
            { renderMessageContent(rephrasedMessage) }
          </div>

          <div className={ styles['rephrased-message-actions'] }>
            <div className={ styles['ai-info'] }>
              <BotNotificationIcon fontSize={ 22 } />
              Powered By Docyt AI
            </div>

            <div className={ styles['action-buttons'] }>
              <button
                className={ styles['hide-button'] }
                type="button"
                onClick={ handleHideRephrased }
              >
                Hide
              </button>
              <button
                className={ styles['accept-button'] }
                type="button"
                onClick={ handleAcceptRephrased }
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      )}

      <div className={ styles['chat-input-container'] }>
        <MentionInput
          chatMembers={ chatMembers }
          messageInputRef={ messageInputRef }
          onMessagePartsChange={ setMessageParts }
          onSend={ handleSendMessage }
        />

        <div className={ styles['chat-buttons'] }>
          <InboxServiceProvider businessId={ businessId }>
            <AddDocument documentId={ documentId } />
          </InboxServiceProvider>

          <button
            className={ styles['ai-button'] }
            disabled={ !messageParts.some((part) => part.content.trim()) || isAiLoading }
            title="Powered By Docyt AI"
            type="button"
            onClick={ handleAiPrompt }
          >
            {!isAiLoading ? (
              <BotNotificationIcon fontSize={ 22 } />
            ) : (
              <i className="fa fa-spinner" />
            )}
          </button>

          <button
            aria-label="Send message"
            className={ styles['send-button'] }
            disabled={ !messageParts.some((part) => part.content.trim()) || isLoading }
            type="button"
            onClick={ handleSendMessage }
          >
            <i className="icon icon-letter-send" />
          </button>
        </div>
      </div>

      {hoverCard && (
        <MentionHoverCard
          avatar={ chatMembers.find((m) => m.id.toString() === hoverCard.uid)?.avatar?.avatarUrl }
          name={ hoverCard.name }
          style={ {
            top:  hoverCard.position.top,
            left: hoverCard.position.left,
          } }
        />
      )}
    </div>
  );
};

export default Chat;
