import React from 'react';

import { useUniqueDomId } from '@src/hooks/dom';

import { CaretIcon } from '@src/components/utils/fa_icons';

interface ICollapseSectionProps {
  title: string;
  children: React.ReactNode;
  suffix?: React.ReactNode;
}

const CollapseSection = ({ 
  title,
  children,
  suffix
}: ICollapseSectionProps): JSX.Element => {
  const collapseSectionId = useUniqueDomId('collapse-section-');
  return (
    <>
      <div id={ collapseSectionId }>
        <div 
          aria-controls={ `${collapseSectionId}-collapse` } 
          aria-expanded="true" 
          className="text-left padding-5 pointer document-detail-sidebar-section-header" 
          data-target={ `#${collapseSectionId}-collapse` }
          data-toggle="collapse"
        >
          <div className="display-flex align-items-center">
            <CaretIcon className="m-r-5" variant="down" />
            <span className="font-bold">{ title }</span>
            { suffix && (
              <span className="ml-auto">{ suffix }</span>
            ) }
          </div>
        </div>
      </div>
      <div 
        aria-expanded="true" 
        aria-labelledby={ collapseSectionId } 
        className="p-t-10 collapse in background-white document-detail-sidebar-section-body" 
        id={ `${collapseSectionId}-collapse` }
      >
        { children }
      </div>
    </>
  );
};

export default CollapseSection;
