import React, { useCallback, useMemo } from 'react';

import { useGetLinkedBusinesses } from '@src/hooks/queries/businesses';
import { TID } from '@src/types/common';

import Spinner from '@src/components/ui/spinner';

import BusinessesView from './businesses_view';
import LinkBusinesses from './link_businesses';

interface IBusinessesViewPageProps {
  businessId: TID,
  vendorId: TID,
}

const BusinessesViewPage = ({
  businessId,
  vendorId,
}: IBusinessesViewPageProps): JSX.Element | null => {
  const query = useGetLinkedBusinesses({
    id: businessId,
    vendorId,
  });

  const refetchBusinesses = useCallback(() => {
    query.refetch();
  }, [query]);

  const linkedBusinesses = useMemo(() => {
    if (!query.data?.businesses) return [];

    return query.data?.businesses;
  }, [query.data?.businesses]);

  if (query.isLoading) return <Spinner />;

  return (
    linkedBusinesses.length > 0 ? (
      <BusinessesView
        businessId={ businessId }
        businesses={ linkedBusinesses }
        refetchBusinesses={ refetchBusinesses }
        vendorId={ vendorId }
      />
    ) : (
      <LinkBusinesses
        businessId={ businessId }
        linkedBusinesses={ linkedBusinesses }
        refetchBusinesses={ refetchBusinesses }
        vendorId={ vendorId }
      />
    )
  );
};

export default BusinessesViewPage;
