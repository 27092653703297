import { useMemo } from 'react';

import { flatten, omit } from 'lodash';
import { UseInfiniteQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useAccountsPayableServiceContext } from '@src/hooks/contexts/accounts_payable_service_context';
import { useGetAccountsPayableServiceSummary } from '@src/hooks/queries/accounts_payable/accounts_payable_services';
import { useGetAccountsPayableServiceDocumentsWithPossibleMatches } from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetAccountsPayableServiceDocumentsResponse } from '@src/requests/accounts_payable/accounts_payable_service_documents';
import {
  IAccountsPayableServiceDocument,
  IAccountsPayableServiceDocumentsFilter,
  IAccountsPayableServiceDocumentsQueryFilter,
  TAccountsPayableServiceDocumentsSortColumn,
} from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID, TSection } from '@src/types/common';
import { IServiceSummary } from '@src/types/service_summary';
import { ISorting, ISortingParams } from '@src/types/sorting';
import { amountFilterToQuery, dateFilterToQuery } from '@src/utils/filter';

import { useFilterData } from '@src/components/ui_v2/filter';
import { useItemsSelector } from '@src/components/utils_v2/items_selector';
import { TItemsSelectionState } from '@src/components/utils_v2/items_selector/types';

interface IInvoiceQueueCollectionParams {
  businessId: TID,
  type: string,
}

export interface IInvoiceQueueCollection {
  query: UseInfiniteQueryResult<IGetAccountsPayableServiceDocumentsResponse, Error>,
  records: IAccountsPayableServiceDocument[],
  section: TSection,
  sorting: ISorting<TAccountsPayableServiceDocumentsSortColumn>,
  selectedRecords: IAccountsPayableServiceDocument[],
  totalAmount: string,
  summaryInfo?: IServiceSummary,
  getSelectionState: () => TItemsSelectionState,
  markAll: (checked: boolean) => void,
}

const defaultSorting: ISortingParams<TAccountsPayableServiceDocumentsSortColumn> = {};

const filterToQuery = (
  filterData: IAccountsPayableServiceDocumentsFilter | undefined,
  type: string
): IAccountsPayableServiceDocumentsQueryFilter | undefined => {
  const query = omit(filterData || { queueType: type }, ['amount', 'invoice_date']);
  query.queueType = type;

  return {
    ...query,
    ...(dateFilterToQuery(filterData?.invoice_date, 'invoice_date') || {}),
    ...(amountFilterToQuery(filterData?.amount, 'invoice_amount', 'value') || {}),
  };
};

export const useInvoiceQueueCollection = ({
  businessId,
  type,
}: IInvoiceQueueCollectionParams): IInvoiceQueueCollection => {
  const service = useAccountsPayableServiceContext();
  const section = useMemo(() => {
    return {
      businessId,
      section: Section.AccountsPayableService,
    };
  }, [businessId]);
  const filterData = useFilterData(section);

  const filterQuery = useMemo(() => {
    return filterToQuery(filterData, type);
  }, [filterData, type]);

  const sorting = useSorting<TAccountsPayableServiceDocumentsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const { data: summaryInfo } = useGetAccountsPayableServiceSummary(businessId, 'queued');

  const query = useGetAccountsPayableServiceDocumentsWithPossibleMatches({
    serviceId: service.id,
    filters: filterQuery,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map(p => p.collection));
  }, [query?.data?.pages]);

  const totalAmount = query?.data?.pages[0]?.meta.totalAmount || '0';
  const { selected: selectedIds, markAll, getSelectionState } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter(r => selectedIds.includes(r.id));
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    selectedRecords,
    sorting,
    markAll,
    getSelectionState,
    totalAmount,
    summaryInfo,
  };
};
