import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getPortfolioWidgets,
  IGetPortfolioWidgetsParams,
  IGetPortfolioWidgetsResponse,
} from '@src/requests/portfolio_service/widgets';

const useGetPortfolioWidgets = (params: IGetPortfolioWidgetsParams) => {
    return useQuery<IGetPortfolioWidgetsResponse, Error>(
    [QueryKey.portfolioDashboardWidgets, params],
    () => getPortfolioWidgets(params),
  );
};

export {
  useGetPortfolioWidgets,
};
