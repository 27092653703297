import React from 'react';

import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IDocument } from '@src/types/documents';

import { LinkButton } from '@src/components/ui/buttons/';

import DocumentUploader from './document_uploader';

import styles from './document_list.module.scss';

interface IDocumentModel {
  id: TID;
  business_id: TID;
  bank_statement: IBankStatement;
  isBankStatementRequest: () => boolean;
  isBalanceSheetStatementRequest: () => boolean;
  get: (key: string) => string | number | null;
}

interface DocumentsSectionProps {
  model: IDocumentModel;
  isUploading: boolean;
  setIsUploading: (value: boolean) => void;
  documents: IDocument[];
  noStatementAvailable: boolean;
  setNoStatementAvailable: (value: boolean) => void;
  isDocumentsExpanded: boolean;
  toggleDocumentsSection: () => void;
  onDocumentsUpdate?: (newDocuments: IDocument[]) => void;
  bankStatement: IBankStatement;
  businessId?: TID;
}

const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  model,
  isUploading,
  setIsUploading,
  documents,
  bankStatement,
  noStatementAvailable,
  setNoStatementAvailable,
  isDocumentsExpanded,
  toggleDocumentsSection,
  onDocumentsUpdate,
  businessId,
}) => {
  const documentCount = documents?.length || 0;
  const documentText = `${documentCount} ${documentCount === 1 ? 'Document' : 'Documents'}`;

  return (
    <section className={ styles['documents-section'] }>
      <div className={ styles['section-header'] }>
        <h1 className={ styles['documents-section-header'] }>Documents</h1>
        {!isDocumentsExpanded && documentCount > 0 && (
          <span className={ styles['document-count'] }>{documentText}</span>
        )}

        <LinkButton
          aria-label={ isDocumentsExpanded ? 'Collapse documents section' : 'Expand documents section' }
          title=""
          onClick={ toggleDocumentsSection }
        >
          <i className={ `fa ${isDocumentsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'}` } />
        </LinkButton>
      </div>

      {isDocumentsExpanded && (
        <DocumentUploader
          bankStatement={ bankStatement }
          documents={ documents }
          isUploading={ isUploading }
          model={ {
            id:                             model.id,
            business_id:                    businessId ?? model.business_id,
            bank_statement:                 bankStatement,
            isBankStatementRequest:         () => false,
            isBalanceSheetStatementRequest: () => false,
            get:                            (key: string) => {
              return key === 'business_id' ? (businessId ?? model.business_id) : null;
            },
          } }
          noStatementAvailable={ noStatementAvailable }
          setIsUploading={ setIsUploading }
          setNoStatementAvailable={ setNoStatementAvailable }
          onDocumentsUpdate={ onDocumentsUpdate }
        />
      )}
    </section>
  );
};

export default DocumentsSection;
