import React, { ReactNode } from 'react';

import EncryptedIcon from '@src/components/utils/icomoon/encrypted';

import { encryptedIconColor } from './utils';

interface AuthWrapperProps {
  isObjectEmpty?: boolean;
  children: ReactNode;
}

const AuthWrapper = ({
  isObjectEmpty,
  children,
}: AuthWrapperProps): JSX.Element => {
  return (
    <div>
      {isObjectEmpty ? (
        <EncryptedIcon
          fontSize={ 20 }
          inColor={ encryptedIconColor }
        />
      ) : children}
    </div>
  );
};

export default AuthWrapper;
