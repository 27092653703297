import React, { useCallback } from 'react';

import classNames from 'classnames';

import { IBusinessVendor } from '@src/types/business_vendors';
import { capitalize } from '@src/utils/transform_keys';

import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';
import { QBOOnIcon, UnverifiedIcon } from '@src/components/utils/icomoon';

import { useActivateInvoiceModal } from './activate_invoice_modal';

import styles from '../styles.module.scss';

interface IVendorDetailProps {
  vendor: IBusinessVendor,
  show: boolean,
}

const VendorDetail = ({ vendor, show } : IVendorDetailProps) => {
  const modal = useActivateInvoiceModal();

  const showClasses = classNames(
    styles.item,
    styles['item-show-status'],
    {
      [styles.hide]: !show,
    },
  );

  const LineItemsDetection = useCallback(() => {
      if (vendor.isAiInvoiceLineItemsExtraction) {
        return (
          <p>Activated</p>
        );
      }
      return (
        <p>Deactivated</p>
      );

    return (
      <Button variant="link" onClick={ modal.open }>Click to activate</Button>
    );
  }, [vendor, modal.open]);

  const RenderAddress = useCallback(() => {
    if (vendor?.addresses?.length !== 0) {
      return (
        <p>
          {
            vendor?.addresses?.[0]?.addressLine1
            && vendor?.addresses?.[0]?.addressLine1 !== ''
              ? `${vendor?.addresses?.[0]?.addressLine1}, `
              : ''
          }
          {
            vendor?.addresses?.[0]?.addressLine2
            && vendor?.addresses?.[0]?.addressLine2 !== ''
              ? `${vendor?.addresses?.[0]?.addressLine2}, `
              : ''
          }
          {
            vendor?.addresses?.[0]?.addressCity
            && vendor?.addresses?.[0]?.addressCity !== ''
              ? `${vendor?.addresses?.[0]?.addressCity}, `
              : ''
          }
          {
            vendor?.addresses?.[0]?.addressState
            && vendor?.addresses?.[0]?.addressState !== ''
              ? `${vendor?.addresses?.[0]?.addressState}, `
              : ''
          }
          {
            vendor?.addresses?.[0]?.addressZip
            && vendor?.addresses?.[0]?.addressZip !== ''
              ? `${vendor?.addresses?.[0]?.addressZip}`
              : ''
          }
        </p>
      );
    }

    return <p>-</p>;
  }, [vendor]);

  return (
    <>
      <div className={ styles.content }>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Name</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor.name ? vendor.name : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Website</p>
          </div>
          <div className={ styles['value-item'] }>
            { vendor.website ? (
              <a href={ `mailto:${vendor.website}` }>{ vendor.website }</a>
            ) : (
              <p>-</p>
            ) }
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Email</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor.email ? vendor.email : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Phone Number</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor.phone ? vendor.phone : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Fax</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor.fax ? vendor.fax : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Preferred Payment Method</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor.preferredPaymentMethod ? vendor.preferredPaymentMethod : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Check Payable to</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor?.addresses?.length !== 0 ? vendor?.addresses?.[0]?.name : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Department</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor?.addresses?.length !== 0 ? vendor?.addresses?.[0]?.company : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Address</p>
          </div>
          <div className={ styles['value-item'] }>
            <RenderAddress />
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>ACH Status</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor.achStatus ? capitalize(vendor.achStatus) : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>AI Invoice Line Items Detection</p>
            <Tooltip.Hover
              content="This will help extract the line items from an invoice automatically.
              You will be able to edit the data. Docyt AI will learn from manually input data."
            >
              <UnverifiedIcon fontSize={ 14 } />
            </Tooltip.Hover>
          </div>
          <div className={ styles['value-item'] }>
            <LineItemsDetection />
          </div>
        </div>
        <div className={ showClasses }>
          <div className={ styles['label-item'] }>
            <p>Ledger Match</p>
          </div>
          <div className={ styles['value-item'] }>
            <div className={ styles['display-flex'] }>
              { vendor.qboName ? (
                <>
                  <QBOOnIcon fontSize={ 16 } />
                  <p>{ vendor.qboName }</p>
                </>
              ) : (
                <p>-</p>
              ) }
            </div>
          </div>
        </div>
        <div className={ showClasses }>
          <div className={ styles['label-item'] }>
            <p>Link</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor.isLinkedToBusinesses ? 'Pushed to multiple businesses' : '-' }</p>
          </div>
        </div>
      </div>
      <modal.Component { ...modal.props } businessId={ vendor.businessId } />
    </>
  );
};

export default React.memo(VendorDetail);
