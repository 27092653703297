import React, { useState, useRef, useEffect } from 'react';

import { IChatParticipant } from '@src/requests/bank_account_reconciliations';

import MentionInput from './mention_input';
import useDeleteDocumentModal from './modals/delete_document_modal';
import UploadFileView from './upload_file_view';

import styles from '../styles.module.scss';

interface IMessageGroup {
  id: string;
  messages: IChatMessage[];
  timestamp: string;
  senderName: string;
  senderAvatar?: ISenderAvatar;
}

interface ISenderAvatar {
  s3ObjectKey: string;
}

interface IChatMessage {
  id: number;
  text: string;
  senderName: string;
  createdAt: string;
  senderAvatar?: ISenderAvatar;
  chatDocument?: IChatDocument;
}

interface IChatDocument {
  businessNames: string[];
  fileContentType: string;
  finalFileKey: string;
  firstDocumentOwnerName: string;
  firstPageS3Key: string;
  haveAccess: boolean;
  id: number;
  originalFileKey: string;
  originalFileName: string;
  state: string;
  storageSize: number;
  standardDocumentName: string;
  standardFolderName: string;
  pagesCount: number;
}

interface IMentionTag {
  id: number;
  name: string;
  avatar: string;
  role: string;
}

interface IMessagePart {
  type: 'text' | 'mention';
  content: string;
  mentionData?: IMentionTag;
}

interface IMessageGroupProps {
  group: IMessageGroup;
  chatId: number | undefined;
  chatMembers: IChatParticipant[];
  getSenderAvatarUrl: (group: IMessageGroup) => string | undefined;
  formatTimestamp: (timestamp: string) => string;
  renderMessageText: (text: string) => React.ReactNode;
  onRefetch: () => Promise<any>;
}

export const MessageGroup: React.FC<IMessageGroupProps> = ({
  group,
  chatId,
  chatMembers,
  getSenderAvatarUrl,
  formatTimestamp,
  renderMessageText,
  onRefetch,
}) => {
  const [hoveredMessageId, setHoveredMessageId] = useState<number | null>(null);
  const [showMenuId, setShowMenuId] = useState<number | null>(null);
  const [editingMessageId, setEditingMessageId] = useState<number | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const editInputRef = useRef<HTMLDivElement>(null);
  const [messageParts, setMessageParts] = useState<IMessagePart[]>([{ type: 'text', content: '' }]);
  const deleteDocumentModal = useDeleteDocumentModal();
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false);
  const [messageIdToDelete, setMessageIdToDelete] = useState<number | null>(null);

  const handleStartEdit = (message: IChatMessage) => {
    setEditingMessageId(message.id);

    const parts: IMessagePart[] = [];
    const matches = message.text.match(/\$\[(?:uid:\d+,name:[^\]]+|role:(?:ADMIN|all))\]\$|[^$]+/g) || [];

    matches.forEach((part) => {
      const userMentionMatch = part.match(/\$\[uid:(\d+),name:([^\]]+)\]\$/);
      const roleMentionMatch = part.match(/\$\[role:(ADMIN|all)\]\$/);

      if (userMentionMatch) {
        const [, uid, name] = userMentionMatch;
        const member = chatMembers.find((m) => m.id.toString() === uid);
        if (member) {
          parts.push({
            type:        'mention',
            content:     part,
            mentionData: {
              id:     member.id,
              name,
              avatar: member.avatar?.avatarUrl || '',
              role:   member.role || '',
            },
          });
        }
      } else if (roleMentionMatch) {
        const [, role] = roleMentionMatch;
        const member = chatMembers.find((m) => m.firstName === role);
        if (member) {
          parts.push({
            type:        'mention',
            content:     part,
            mentionData: {
              id:     member.id,
              name:   role,
              avatar: member.avatar?.avatarUrl || '',
              role:   member.role || '',
            },
          });
        }
      } else {
        parts.push({ type: 'text', content: part });
      }
    });

    setMessageParts(parts);
    setShowMenuId(null);
  };

  const handleSaveEdit = async () => {
    try {
      const processedMessage = messageParts.map((part) => part.content).join('');
      if (!processedMessage.trim()) return;

      // @ts-ignore
      window.Docyt.fayeClient.publish(`/chats/${chatId}`, {
        auth_token: localStorage.getItem('oauth_access_token'),
        message:    {
          action:     'update',
          message_id: editingMessageId,
          text:       processedMessage.trim(),
        },
      });

      setEditingMessageId(null);
      setMessageParts([{ type: 'text', content: '' }]);
      await onRefetch();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      // ignore
    }
  };

  const handleCancelEdit = () => {
    setEditingMessageId(null);
    setMessageParts([{ type: 'text', content: '' }]);
  };

  const renderMessageContent = (message: IChatMessage) => {
    if (editingMessageId === message.id) {
      return (
        <div className={ styles['edit-container'] }>
          <MentionInput
            chatMembers={ chatMembers }
            initialMessage={ message.text }
            messageInputRef={ editInputRef }
            onMessagePartsChange={ setMessageParts }
            onSend={ handleSaveEdit }
          />
          <div className={ styles['edit-actions'] }>
            <button
              className={ styles['cancel-button'] }
              type="button"
              onClick={ handleCancelEdit }
            >
              Cancel
            </button>
            <button
              className={ styles['save-button'] }
              disabled={ !messageParts.some((part) => part.content.trim()) }
              type="button"
              onClick={ handleSaveEdit }
            >
              Save
            </button>
          </div>
        </div>
      );
    }

    if (message.chatDocument) {
      return <UploadFileView { ...message.chatDocument } onRefetch={ onRefetch } />;
    }

    return renderMessageText(message.text);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenuId(null);
      }
    };

    if (typeof window !== 'undefined') {
      window.document.addEventListener('mousedown', handleClickOutside);
      return () => {
        window.document.removeEventListener('mousedown', handleClickOutside);
      };
    }
    return undefined;
  }, []);

  const handleDeleteMessage = async (messageId: number) => {
    try {
      // @ts-ignore
      window.Docyt.fayeClient.publish(`/chats/${chatId}`, {
        auth_token: localStorage.getItem('oauth_access_token'),
        message:    {
          action:     'delete',
          message_id: messageId,
        },
      });
      setShowMenuId(null);
      setMessageIdToDelete(null);
      await onRefetch();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      // ignore
    }
  };

  const handleDeleteClick = async (messageId: number) => {
    const message = group.messages.find((m) => m.id === messageId);

    if (message?.chatDocument) {
      setShowDeleteDocumentModal(true);
      setMessageIdToDelete(messageId);
      return;
    }

    handleDeleteMessage(messageId);
  };

  const renderMessageMenu = (messageId: number, isDocument: boolean) => (
    <div ref={ menuRef } className={ styles['message-menu'] }>
      {!isDocument && (
        <button
          className={ styles['edit-button'] }
          type="button"
          onClick={ () => {
            const message = group.messages.find((m) => m.id === messageId);
            if (message) handleStartEdit(message);
          } }
        >
          <i className="icon icon-edit" />
          Edit message
        </button>
      )}
      <button
        className={ styles['delete-button'] }
        type="button"
        onClick={ () => handleDeleteClick(messageId) }
      >
        <i className="icon icon-trashcan" />
        Delete message
      </button>
    </div>
  );

  return (
    <div className={ styles['message-group'] }>
      <div
        className={ styles['message-text'] }
        onMouseEnter={ () => setHoveredMessageId(group.messages[0].id) }
        onMouseLeave={ () => setHoveredMessageId(null) }
      >
        <div className={ styles['message-text-content'] }>
          <div className={ styles['message-text-inner'] }>
            <div className={ styles['message-header-content'] }>
              <div className={ styles['message-avatar'] }>
                {getSenderAvatarUrl(group) ? (
                  <img
                    alt={ group.senderName }
                    src={ getSenderAvatarUrl(group) }
                  />
                ) : (
                  <i className="icon icon-smile in-blue-400" />
                )}
              </div>
              <div className={ styles['message-info'] }>
                <span className={ styles['sender-name'] }>{ group.senderName }</span>
                <span className={ styles['message-time'] }>{ formatTimestamp(group.timestamp) }</span>
              </div>
            </div>
            {renderMessageContent(group.messages[0])}
          </div>

          {hoveredMessageId === group.messages[0].id && (
            <button
              aria-label="Message menu"
              className={ styles['message-menu-button'] }
              type="button"
              onClick={ () => setShowMenuId(group.messages[0].id) }
            >
              <i className="fa fa-caret-down" />
            </button>
          )}
          {showMenuId === group.messages[0].id && renderMessageMenu(
            group.messages[0].id,
            !!group.messages[0].chatDocument,
          )}
        </div>
      </div>

      {group.messages.slice(1).map((chatMessage) => (
        <div
          key={ chatMessage.id }
          className={ styles['message-text'] }
          onMouseEnter={ () => setHoveredMessageId(chatMessage.id) }
          onMouseLeave={ () => setHoveredMessageId(null) }
        >
          <div className={ styles['message-text-content'] }>
            <div className={ styles['message-text-inner'] }>
              {renderMessageContent(chatMessage)}
            </div>

            {hoveredMessageId === chatMessage.id && (
              <button
                aria-label="Message menu"
                className={ styles['message-menu-button'] }
                type="button"
                onClick={ () => setShowMenuId(chatMessage.id) }
              >
                <i className="fa fa-caret-down" />
              </button>
            )}
            {showMenuId === chatMessage.id && renderMessageMenu(
              chatMessage.id,
              !!chatMessage.chatDocument,
            )}
          </div>
        </div>
      ))}

      <deleteDocumentModal.Component
        { ...deleteDocumentModal.props }
        isOpen={ showDeleteDocumentModal }
        onCancel={ () => setShowDeleteDocumentModal(false) }
        onConfirm={ () => {
          setShowDeleteDocumentModal(false);
          handleDeleteMessage(messageIdToDelete || 0);
        } }
      />
    </div>
  );
};

export default MessageGroup;
